import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import DialogBox from "../../../../../components/DialogBox";
import { useCallbackPrompt } from "../../../../../hooks/useCallbackPrompt";
import { Box } from "@mui/system";
import {
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const EditVisitQuestionnaire = () => {
  const labelStyles = {
    display: "flex",
    alignItems: "center",
    height: "20px",
  };

  const textBoxBody = {
    marginLeft: "10px",
  };

  const textBoxStyles = {
    fontSize: 15,
    width: "500px",
    height: "10px",
  };

  const loadContainerStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70vh",
  };

  const selectStyles = {
    width: 530,
    marginTop: 1,
  };

  const rowStyles = {
    marginTop: "2%",
  };

  const buttonLinkStyle = {
    background: "none",
    color: "#337AB7",
    border: "none",
    padding: "0",
    font: "inherit",
    cursor: "pointer",
  };

  const addButtonStyle = {
    cursor: "pointer",
    textAlign: "center",
    color: "white",
    backgroundColor: "#337ab7",
    padding: "12px",
    fontSize: "15px",
    border: "none",
    borderRadius: "5px",
  };

  const createQuestionnaireSchema = yup.object().shape({
    questionnaireId: yup.string().required("This field is required"),
    sequence: yup
      .number()
      .typeError("Value must be a number")
      .required("This field is required")
      .min(0, "Value cannot be less than 0"),
    isAudioEnabled: yup.bool().oneOf([true, false]),
    isVideoEnabled: yup.bool().oneOf([true, false]),
  });

  const createCalculationSchema = yup.object().shape({
    calculation: yup.string().required("This field is required"),
  });

  const createStudyRoleSchema = yup.object().shape({
    studyRole: yup.string().required("This field is required"),
  });

  const createVisitRoleSchema = yup.object().shape({
    visitRole: yup.string().required("This field is required"),
  });

  const createCalculationRoleSchema = yup.object().shape({
    calculationRole: yup.string().required("This field is required"),
  });

  const createReviewRoleSchema = yup.object().shape({
    reviewRole: yup.string().required("This field is required"),
  });

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createQuestionnaireSchema),
  });

  const {
    register: registerCalculation,
    handleSubmit: handleSubmitCalculation,
    reset: resetCalculation,
    setValue: calculationSetValue,
    formState: { errors: errorsCalculation },
  } = useForm({
    resolver: yupResolver(createCalculationSchema),
  });

  const {
    register: registerStudyRole,
    handleSubmit: handleStudyRole,
    setValue: studyRoleSetValue,
    formState: { errors: errorsStudyRole },
  } = useForm({
    resolver: yupResolver(createStudyRoleSchema),
  });

  const {
    register: registerVisitRole,
    handleSubmit: handleVisitRole,
    setValue: visitRoleSetValue,
    formState: { errors: errorsVisitRole },
  } = useForm({
    resolver: yupResolver(createVisitRoleSchema),
  });

  const {
    register: registerCalculationRole,
    handleSubmit: handleCalculationRole,
    setValue: calculationRoleSetValue,
    formState: { errors: errorsCalculationRole },
  } = useForm({
    resolver: yupResolver(createCalculationRoleSchema),
  });

  const {
    register: registerReviewRole,
    handleSubmit: handleReviewRole,
    setValue: reviewRoleSetValue,
    formState: { errors: errorsReviewRole },
  } = useForm({
    resolver: yupResolver(createReviewRoleSchema),
  });

  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.id;
  const visitId = location?.state?.visitId;

  const [load, setLoad] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const [value, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
  };

  const handleCancel = (e) => {
    e.preventDefault();
    navigate("/study-management/visits/visit-questionnaire", {
      state: {
        id: visitId,
      },
    });
  };

  const handleCalculation = () => {};

  const handleStudyRoleFun = () => {};

  const handleVisitRoleFun = () => {};

  const handleCalulationRoleFun = () => {};

  const handleReviewRoleFun = () => {};

  return (
    <>
      {load ? (
        <>
          <div style={loadContainerStyles}>
            <BeatLoader color="#3661eb" />
          </div>
        </>
      ) : (
        <div className="content-body">
          <p className="study-management-link" style={{ fontWeight: "600" }}>
            <Link to="/study-management">Manage Your Study</Link> |{" "}
            <Link to="/study-management/visits">Visits</Link> |{" "}
            <button
              style={buttonLinkStyle}
              onClick={() => {
                navigate("/study-management/visits/visit-questionnaire", {
                  state: {
                    id: visitId,
                  },
                });
              }}
            >
              Visit Questionnaires
            </button>{" "}
            |{" "}
            <button
              style={buttonLinkStyle}
              onClick={() => {
                navigate("/study-management/visits/edit-questionnaire", {
                  state: {
                    id: id,
                    visitId: visitId,
                  },
                });
              }}
            >
              Edit Questionnaires
            </button>
          </p>

          <div>
            <DialogBox
              showDialog={showPrompt}
              confirmNavigation={confirmNavigation}
              cancelNavigation={cancelNavigation}
            />
          </div>
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              style={{
                width: "fit-content",
              }}
            >
              <Tab label="Edit Visit Questionnaire" {...a11yProps(0)} />
              <Tab label="Calculations" {...a11yProps(1)} />
              <Tab label="Review Functionality" {...a11yProps(2)} />
            </Tabs>

            <TabPanel value={value} index={0}>
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{ height: "auto", width: "100%" }}
                autoComplete="off"
              >
                <div className="createVisitBody">
                  <div className="createVisitHeader">
                    <h1>Create Questionnaire</h1>
                  </div>
                  <div className="createVisitFormBody">
                    <div className="createVisitInput">
                      <p style={labelStyles}>
                        Questionnaire Id
                        <span className="error-highlight">*</span>
                      </p>
                      <FormControl sx={selectStyles}>
                        <Select
                          name="questionnaireId"
                          defaultValue={""}
                          inputProps={{
                            style: textBoxStyles,
                            "aria-label": "Without label",
                          }}
                          {...register("questionnaireId", {
                            onChange: (e) => {
                              setValue("questionnaireId", e.target.value, {
                                shouldValidate: true,
                              });
                              setShowDialog(true);
                            },
                          })}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    {errors.questionnaireId && (
                      <>
                        <div className="createVisitFlexEnd">
                          <div className="createVisitError">
                            <span
                              className="error-text"
                              style={{ color: "red" }}
                            >
                              {errors.questionnaireId.message}
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="createVisitCheckbox">
                      <p>Is Audio Enabled</p>
                      <Checkbox
                        style={{ fontSize: "15px" }}
                        inputProps={{ "aria-label": "controlled" }}
                        {...register("isAudioEnabled", {
                          onChange: (e) => {
                            setShowDialog(true);
                          },
                        })}
                      />
                    </div>

                    {errors.isAudioEnabled && (
                      <>
                        <div className="createVisitFlexEnd">
                          <div className="createVisitError">
                            <span
                              className="error-text"
                              style={{ color: "red" }}
                            >
                              {errors.isAudioEnabled.message}
                            </span>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="createVisitCheckbox">
                      <p>Is Video Enabled</p>
                      <Checkbox
                        style={{ fontSize: "15px" }}
                        inputProps={{ "aria-label": "controlled" }}
                        {...register("isVideoEnabled", {
                          onChange: (e) => {
                            setShowDialog(true);
                          },
                        })}
                      />
                    </div>

                    {errors.isVideoEnabled && (
                      <>
                        <div className="createVisitFlexEnd">
                          <div className="createVisitError">
                            <span
                              className="error-text"
                              style={{ color: "red" }}
                            >
                              {errors.isVideoEnabled.message}
                            </span>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="createVisitInput">
                      <p style={labelStyles}>
                        Sequence
                        <span className="error-highlight">*</span>
                      </p>
                      <TextField
                        type={"number"}
                        style={textBoxBody}
                        inputProps={{
                          style: textBoxStyles,
                          min: 0,
                        }}
                        {...register("sequence", {
                          onChange: (e) => {
                            setShowDialog(true);
                          },
                        })}
                      />
                    </div>
                    {errors.sequence && (
                      <>
                        <div className="createVisitFlexEnd">
                          <div className="createVisitError">
                            <span
                              className="error-text"
                              style={{ color: "red" }}
                            >
                              {errors.sequence.message}
                            </span>
                          </div>
                        </div>
                      </>
                    )}

                    <Row style={rowStyles}>
                      <Col md={8}></Col>
                      <Col md={2}>
                        <div className="study-management-head-end">
                          <button
                            onClick={(e) => {
                              handleCancel(e);
                            }}
                            className="study-management-cancel-btn"
                          >
                            Cancel
                          </button>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="study-management-head-end">
                          <button
                            type="submit"
                            className="study-management-create-btn-md"
                          >
                            Save
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Box
                component="form"
                onSubmit={handleSubmitCalculation(handleCalculation)}
              >
                <div className="createVisitInput">
                  <p style={labelStyles}>
                    Add a calculation
                    {/* <span className="error-highlight">*</span> */}
                  </p>
                  <FormControl sx={selectStyles}>
                    <Select
                      name="calculation"
                      defaultValue={""}
                      inputProps={{
                        style: textBoxStyles,
                        "aria-label": "Without label",
                      }}
                      {...registerCalculation("calculation", {
                        onChange: (e) => {
                          calculationSetValue("calculation", e.target.value, {
                            shouldValidate: true,
                          });
                          setShowDialog(true);
                        },
                      })}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {errorsCalculation.calculation && (
                  <>
                    <div
                      className="createVisitFlexEnd"
                      style={{ marginTop: "30px" }}
                    >
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errorsCalculation.calculation.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                <Row style={rowStyles}>
                  <Col md={8}></Col>
                  <Col md={2}>
                    <div className="study-management-head-end">
                      <button
                        onClick={(e) => {
                          handleCancel(e);
                        }}
                        className="study-management-cancel-btn"
                      >
                        Cancel
                      </button>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="study-management-head-end">
                      <button
                        type="submit"
                        className="study-management-create-btn-md"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Box>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div>
                <h4>
                  {`This functionality configures visit
                  ${" --> "}
                  questionnaire review functionality. The roles assigned will be
                  permitted to do the review. The visits selected will be
                  displayed on the summary review screen.`}
                </h4>
              </div>
              <Box
                component="form"
                autoComplete="off"
                onSubmit={handleStudyRole(handleStudyRoleFun)}
              >
                <div>
                  <p style={labelStyles}>
                    Add a Study Role to review this questionnaire at this visit
                    {/* <span className="error-highlight">*</span> */}
                  </p>
                  <FormControl sx={selectStyles}>
                    <Select
                      name="studyRole"
                      defaultValue={""}
                      inputProps={{
                        style: textBoxStyles,
                        "aria-label": "Without label",
                      }}
                      {...registerStudyRole("studyRole", {
                        onChange: (e) => {
                          studyRoleSetValue("studyRole", e.target.value, {
                            shouldValidate: true,
                          });
                          setShowDialog(true);
                        },
                      })}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {errorsStudyRole.studyRole && (
                  <>
                    <div style={{ marginTop: "30px" }}>
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errorsStudyRole.studyRole.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                <div style={{ marginTop: "10px" }}>
                  <button type="submit" style={addButtonStyle}>
                    Add Study Role
                  </button>
                </div>
              </Box>
              <hr />
              <Box
                component="form"
                autoComplete="off"
                onSubmit={handleVisitRole(handleVisitRoleFun)}
              >
                <div>
                  <p style={labelStyles}>
                    Add a visit value to be displayed for review on the tablet.
                    Please note that ONLY visits that contain this questionnaire
                    may be selected.
                    {/* <span className="error-highlight">*</span> */}
                  </p>
                  <FormControl sx={selectStyles}>
                    <Select
                      name="visitRole"
                      defaultValue={""}
                      inputProps={{
                        style: textBoxStyles,
                        "aria-label": "Without label",
                      }}
                      {...registerVisitRole("visitRole", {
                        onChange: (e) => {
                          visitRoleSetValue("visitRole", e.target.value, {
                            shouldValidate: true,
                          });
                          setShowDialog(true);
                        },
                      })}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {errorsVisitRole.visitRole && (
                  <>
                    <div style={{ marginTop: "30px" }}>
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errorsVisitRole.visitRole.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                <div style={{ marginTop: "10px" }}>
                  <button type="submit" style={addButtonStyle}>
                    Add Visit
                  </button>
                </div>
              </Box>
              <hr />
              <Box
                component="form"
                autoComplete="off"
                onSubmit={handleCalculationRole(handleCalulationRoleFun)}
              >
                <div>
                  <p style={labelStyles}>
                    Add a calculation to be displayed for review on the tablet.
                    {/* <span className="error-highlight">*</span> */}
                  </p>
                  <FormControl sx={selectStyles}>
                    <Select
                      name="calculationRole"
                      defaultValue={""}
                      inputProps={{
                        style: textBoxStyles,
                        "aria-label": "Without label",
                      }}
                      {...registerCalculationRole("calculationRole", {
                        onChange: (e) => {
                          calculationRoleSetValue(
                            "calculationRole",
                            e.target.value,
                            {
                              shouldValidate: true,
                            }
                          );
                          setShowDialog(true);
                        },
                      })}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {errorsCalculationRole.calculationRole && (
                  <>
                    <div style={{ marginTop: "30px" }}>
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errorsCalculationRole.calculationRole.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                <div style={{ marginTop: "10px" }}>
                  <button type="submit" style={addButtonStyle}>
                    Add Calculation
                  </button>
                </div>
              </Box>
              <hr />
              <Box
                component="form"
                autoComplete="off"
                onSubmit={handleReviewRole(handleReviewRoleFun)}
              >
                <div>
                  <p style={labelStyles}>
                    Add a review questionnaire to be completed after this
                    questionnaire is marked for review (follow up questions).
                    {/* <span className="error-highlight">*</span> */}
                  </p>
                  <FormControl sx={selectStyles}>
                    <Select
                      name="reviewRole"
                      defaultValue={""}
                      inputProps={{
                        style: textBoxStyles,
                        "aria-label": "Without label",
                      }}
                      {...registerReviewRole("reviewRole", {
                        onChange: (e) => {
                          reviewRoleSetValue("reviewRole", e.target.value, {
                            shouldValidate: true,
                          });
                          setShowDialog(true);
                        },
                      })}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {errorsReviewRole.reviewRole && (
                  <>
                    <div style={{ marginTop: "30px" }}>
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errorsReviewRole.reviewRole.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                <div style={{ marginTop: "10px" }}>
                  <button type="submit" style={addButtonStyle}>
                    Update Review Questionnaire
                  </button>
                </div>
                <Row style={{ marginTop: "2%" }}>
                  <Col md={6}></Col>
                  <Col md={6}>
                    {/* <Link to="/study-management"> */}
                    <div className="study-management-head-end">
                      <button
                        onClick={(e) => handleCancel(e)}
                        className="study-management-create-btn-lg"
                      >
                        Back
                      </button>
                    </div>
                    {/* </Link> */}
                  </Col>
                </Row>
              </Box>
            </TabPanel>
          </Box>
        </div>
      )}
    </>
  );
};

export default EditVisitQuestionnaire;
