import React, { createContext, useReducer, useContext } from "react";
import treatmentReducer, { initialState as treatmentInitialState } from "./TreatmentReducer";


const TreatmentContext = createContext();

export const TreatmentProvider = ({ children }) => {
    const [state, dispatch] = useReducer(treatmentReducer, treatmentInitialState);

    const setPageNumber = (pageNumber) => {
        dispatch({ type: "SET_PAGE_NUMBER", payload: pageNumber });
    };

    const setPageSize = (pageSize) => {
        dispatch({ type: "SET_PAGE_SIZE", payload: pageSize });
    };

    const setSorting = (sortingType, sortingField) => {
        dispatch({ type: "SET_SORTING", payload: { sortingType, sortingField } });
    };

    const setFilters = (filters) => {
        dispatch({ type: "SET_FILTERS", payload: filters });
    };
    return (
        <TreatmentContext.Provider value={{ state, dispatch, setPageNumber, setPageSize, setFilters, setSorting }}>
            {children}
        </TreatmentContext.Provider>
    );
};

export const useTreatmentManagement = () => useContext(TreatmentContext);

export default TreatmentContext;
