import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import SubjectSidebar from "./SubjectSidebar";
import SubjectForm from "./SubjectForm";
import SubjectDataTable from "./SubjectDatatable";
import useSubjectAttributesDetails from "../../../../hooks/Api/useSubjectAttributesDetails";
import axios from "axios";
import { studyServer } from "../../../../services/study_axios";
import { Box, Button, ButtonBase, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import BeatLoader from "react-spinners/BeatLoader";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SubjectConfiguration from "./SubjectConfiguration";

const ODD_OPACITY = 0.2;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SubjectAttributes = ({ setShowDialog }) => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [formData, setFormData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [pacDisplayNames, setPacDisplayNames] = useState([]);
  const [apiResponse, setApiResponse] = useState(null);
  const [rows, setRows] = useState([]);
  // const [selectedPacId, setSelectedPacId] = useState();
  const [selectedPacId, setSelectedPacId] = useState(null); // Changed initial value to null
  const [deletedItems, setDeletedItems] = useState([]);
  const {
    subjectAttributeQuery,
    attributeDetailsQuery,
    businessDetailsQuery,
    dataTypeDetailsQuery,
    fetchAttributeDetailsByGuid,
  } = useSubjectAttributesDetails();
  const { data } = subjectAttributeQuery;
  const { data: businessRules } = businessDetailsQuery;
  const { data: dataTypes } = dataTypeDetailsQuery;

  useEffect(() => {
    if (data?.pac) {
      const displayNames = data.pac.map((pacItem) => pacItem.displayName);
      setPacDisplayNames(displayNames);
    }
  }, [data]);

  useEffect(() => {
    const savedRows = JSON.parse(localStorage.getItem("subjectDataTableRows"));
    if (savedRows && savedRows.length > 0) {
      setRows(savedRows);
    }

    const savedFormData = JSON.parse(localStorage.getItem("formData"));
    if (savedFormData && savedFormData.length > 0) {
      setFormData(savedFormData);
    }
  }, []);



  // useEffect(() => {
  //   const savedPacId = localStorage.getItem('selectedPacId');
  //   if (savedPacId) {
  //     setSelectedPacId(savedPacId);
  //   }
  // }, []);

  const mapResponseToTableRows = (details) => {
    if (!Array.isArray(details)) {
      console.error("Expected an array but received:", details);
      return [];
    }

    return details.map((item) => {
      const dataType = dataTypes?.find((type) => type.id === item.dataTypeId);
      const businessRule = businessRules?.find(
        (rule) => rule.id === item.businessRuleId
      );

      return {
        id: item.id,
        description: item.description || "No description",
        textKey: item.translationKey || "No text key",
        dataType: dataType?.name || "Unknown", // Map the data type name
        businessRule: businessRule?.name || "None", // Map the business rule name
        maximumLength: item.maximumLength || 0,
        minimumValue: item.minimumValue || 0,
        maximumValue: item.maximumValue || 0,
        defaultValue: item.defaultValue || "N/A",
        businessRuleQuestionText:
          item.businessRuleQuestionText || "No question",
        businessRuleQuestionTranslationKey:
          item.questionTranslationKey || "No translation key",
        errorMessageKey: item.errorTranslationKey || "No error message",
      };
    });
  };

  const handleDelete = (row) => {
    setFormData((prevData) => prevData.filter((item) => item.id !== row.id));
    setRows((prevRows) => prevRows.filter((item) => item.id !== row.id));
    setDeletedItems((prevDeletedItems) => [...prevDeletedItems, row.id]);
  };

  const handleRowDelete = (row) => {
    console.log("Delete row with id: ", row.id);
    // Implement delete functionality here
  };

  const countries =
    data?.countries?.map((country) => ({
      id: country.id,
      name: country.name,
    })) || [];

  useEffect(() => {
    setSelectedOption(selectedCountry ? selectedCountry.name : "");
  }, [selectedCountry]);

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
  };



  // const handleFormSubmit = (data) => {
  //   setFormData((prevData) => [
  //     ...prevData,
  //     { id: prevData.length + 1, ...data },
  //   ]);
  // };

  // const handleDelete = (row) => {
  //   setFormData((prevData) => {
  //     const updatedData = prevData.filter((item) => item.id !== row.id);
  //     // localStorage.setItem('formData', JSON.stringify(updatedData));
  //     return updatedData;
  //   });
  //   setRows((prevRows) => prevRows.filter((item) => item.id !== row.id));
  //   setDeletedItems((prevDeletedItems) => [...prevDeletedItems, row.id]);
  // };

  const handleFormSubmit = (data) => {
    
    const updatedFormData = [...formData, { id: formData.length + 1, ...data }];
    setFormData(updatedFormData);
    setShowDialog(true)
    // localStorage.setItem('formData', JSON.stringify(updatedFormData));
  };

  const handleDropdownChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);
    const selectedCountryObj = countries.find((c) => c.name === selectedValue);
    if (selectedCountryObj) {
      setSelectedCountry(selectedCountryObj);
    }
  };

  const handleAddCountry = (newCountry) => {
    if (!pacDisplayNames.includes(newCountry)) {
      setPacDisplayNames([newCountry, ...pacDisplayNames]);
    }
  };

  // const handleListItemClick = (item) => {
  //   setSelectedOption(item.displayName);
  //   setSelectedPacId(item.id);
  // };
  const handleListItemClick = (item) => {
    setSelectedOption(item.displayName);
    setSelectedPacId(item.id);

    // Save selectedPacId to localStorage
    // localStorage.setItem('selectedPacId', item.id);
  };

  // useEffect(() => {
  //   const savedPacId = localStorage.getItem('selectedPacId');
  //   if (savedPacId) {
  //     setSelectedPacId(savedPacId);
  //   }
  // }, []);

  const { data: attributeDetails } = useQuery(
    ["attributeDetails", selectedPacId],
    () => fetchAttributeDetailsByGuid(selectedPacId),
    {
      enabled: !!selectedPacId,
      onSuccess: (data) => {
        const details = data.patientAttributeConfigurationDetails;
        const mappedData = mapResponseToTableRows(details);
        setRows(mappedData);
        // localStorage.setItem("attributeDetails", JSON.stringify(mappedData));
      },
      onError: (error) => {
        console.error("Error fetching attribute details: ", error.message);
        setRows([]); // Reset the rows on error
      },
    }
  );

  const noDataMessage = selectedPacId ? (
    rows.length > 0 ? (
      <BeatLoader size={15} color="#3661eb" />
    ) : (
      "No results found"
    )
  ) : (
    "Please select a country"
  );

  //   const handleSaveChanges = async () => {
  //     if (!selectedPacId) {
  //       console.error("Selected PAC ID is not defined.");
  //       return;
  //     }

  //     const detailsToAddNew = formData
  //       .map((item) => {
  //         const dataType = dataTypes.find((dt) => dt.name === item.dataType);
  //         const businessRule = businessRules.find(
  //           (br) => br.name === item.businessRule
  //         );

  //         const dataTypeId = dataType ? dataType.id : null;
  //         const businessRuleId = businessRule ? businessRule.id : null;

  //         // Fetch choices if the data type supports multiple choices
  //         let patientAttributeConfigurationDetailChoices = null;
  //         if (dataType && dataType.name === "ChoicesAttribute") {
  //           patientAttributeConfigurationDetailChoices = [
  //             dataTypeId,
  //           ];
  //         }

  //         // Build the payload object conditionally based on whether businessRuleId is provided
  //         const payloadObject = {
  //           id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //           patientAttributeConfigurationId: selectedPacId,
  //           translationKey: item.translationKey || "",
  //           valueDescriptionTranslationKey:
  //             item.valueDescriptionTranslationKey || "",
  //           errorTranslationKey: item.errorTranslationKey || "",
  //           displayOrder: item.displayOrder || 0,
  //           description: item.description || "",
  //           errorDescription: item.errorDescription || "",
  //           valueDescription: item.valueDescription || "",
  //           allowDelete: item.allowDelete || false,
  //           minimumValue: item.minimumValue || 0,
  //           maximumValue: item.maximumValue || 0,
  //           maximumLength: item.maximumLength || 0,
  //           defaultValue: item.defaultValue || null,
  //           minimumValue: item.minimumValue || 0,
  //           maximumValue: item.maximumValue || 0,
  //           dataTypeId: dataTypeId, // Correct ID without extra properties
  //           businessRuleQuestionText: item.businessRuleQuestionText || "",
  //           questionTranslationKey: item.questionTranslationKey || "",
  //           textKey: item.textKey || "",
  //           patientAttributeConfigurationDetailChoices:
  //             patientAttributeConfigurationDetailChoices,
  //         };

  //         // Only add businessRuleId if it exists
  //         if (businessRuleId) {
  //           payloadObject.businessRuleId = businessRuleId;
  //         }

  //         // Return the final object
  //         return payloadObject;
  //       })
  //       .filter((item) => item !== null);

  //     const payload = {
  //       pacId: selectedPacId,
  //       detailsToAddNew,
  //       detailsToRemove: deletedItems,
  //     };

  //     try {
  //     const response = await studyServer.post(
  //       "/app/PatientAttributeConfiguration/Edit",
  //       payload
  //     );
  //     toast.success("Configuration Data successfully saved!");
  //   } catch (error) {
  //     toast.error("Error saving changes");
  //     console.error("Error saving changes: ", error.message);
  //   }
  // };

  const handleSaveChanges = async () => {
    if (!selectedPacId) {
      console.error("Selected PAC ID is not defined.");
      return;
    }
    setShowDialog(false);

    const detailsToAddNew = formData.map((item) => {
      const dataType = dataTypes.find((dt) => dt.name === item.dataType);
      const businessRule = businessRules.find(
        (br) => br.name === item.businessRule
      );

      const dataTypeId = dataType ? dataType.id : null;
      const businessRuleId = businessRule ? businessRule.id : null;

      // Fetch choices if the data type supports multiple choices
      let patientAttributeConfigurationDetailChoices = null;
      // if (dataType && dataType.name === "ChoicesAttribute") {
      //   patientAttributeConfigurationDetailChoices = (item.selectedChoices || []).map((choice) => ({
      //     id: choice.id || "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      //     patientAttributeConfigurationDetailId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      //     translationKey: choice.translationKey || "",
      //     displayOrder: choice.displayOrder || 0,
      //     text: choice.text || "",
      //   }));
      // }

      const payloadObject = {
        id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        patientAttributeConfigurationId: selectedPacId,
        translationKey: item.translationKey || "",
        valueDescriptionTranslationKey:
          item.valueDescriptionTranslationKey || "",
        errorTranslationKey: item.errorTranslationKey || "",
        displayOrder: item.displayOrder || 0,
        description: item.description || "",
        errorDescription: item.errorDescription || "",
        valueDescription: item.valueDescription || "",
        allowDelete: item.allowDelete || false,
        minimumValue: item.minimumValue || 0,
        maximumValue: item.maximumValue || 0,
        maximumLength: item.maximumLength || 0,
        defaultValue: item.defaultValue || null,
        dataTypeId: dataTypeId,
        businessRuleQuestionText: item.businessRuleQuestionText || "",
        questionTranslationKey: item.questionTranslationKey || "",
        textKey: item.textKey || "",
        patientAttributeConfigurationDetailChoices,
      };

      if (businessRuleId) {
        payloadObject.businessRuleId = businessRuleId;
      }

      return payloadObject;
    });

    const payload = {
      pacId: selectedPacId,
      detailsToAddNew,
      detailsToRemove: deletedItems,
    };

    try {
      const response = await studyServer.post(
        "/app/PatientAttributeConfiguration/Edit",
        payload
      );
      toast.success("Configuration Data successfully saved!");
    } catch (error) {
      toast.error("Error while saving changes");
      console.error("Error saving changes: ", error.message);
    }
  };

  return (
    <Container fluid className="h-100">
      <Row className="h-100">
        {/* Sidebar */}
        <Col xs={12} md={3} className="p-0">
          <div className="h-100 d-flex flex-column bg-[#3E4CF4] rounded-md">
            <SubjectSidebar
              countries={countries}
              selectedCountry={selectedCountry}
              onCountryChange={handleCountryChange}
              onListItemClick={handleListItemClick}
              onAddCountry={handleAddCountry}
            />
          </div>
        </Col>

        {/* Main Content */}
        <Col xs={12} md={9} className="p-3 d-flex flex-column">
          {/* Dropdown on top left */}
          <div className="d-flex justify-content-start mb-3">
            <input
              type="text"
              value={selectedOption}
              readOnly
              style={{ width: "200px" }}
              className="border border-gray-300 p-3 rounded-md outline-none"
            />
          </div>

          {/* Form and DataTable */}
          <div className="flex-grow-1 d-flex flex-column">
            <SubjectForm onSubmit={handleFormSubmit} />
            <SubjectDataTable
              rows={[...rows,...formData]}
              onEdit={(row) => console.log("Edit", row)}
              setShowDialog={setShowDialog}
              onDelete={handleDelete}
              className="mt-4"
              selectedPacId={selectedPacId}
              noDataMessage={noDataMessage}
            />
            <div className="flex justify-between">
              <Button
                onClick={handleSaveChanges}
                color="primary"
                variant="contained"
                className="!mt-3 !text-white !px-8 !py-5 !text-xl"
              >
                Save Changes
              </Button>
              <Button
                onClick={async () => {
                  navigate(`/study-management`);
                }}
                color="primary"
                variant="contained"
                className="!mt-3 !text-white !px-8 !py-5 !text-xl"
              >
                Back to study
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SubjectAttributes;
