import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, Form } from "react-bootstrap";
import {
  TextField,
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router";
import {
  addEditChoices,
  removeEditChoices,
  getChoicesByDetailId,
} from "../../../../services/subject_attributes";
import useSubjectAttributesDetails from "../../../../hooks/Api/useSubjectAttributesDetails";
import { useQuery, useQueryClient } from "react-query";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import DeleteModal from "../../../../components/DeleteModal";

const schema = yup.object().shape({
  choiceText: yup.string().required("Choice text is required"),
});

const EditChoice = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const [choices, setChoices] = useState([]);
  const [refreshCount, setRefreshCount] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedChoiceIndex, setSelectedChoiceIndex] = useState(null);

  const { fetchAttributeDetailsByGuid, addOrEditChoiceMutation } =
    useSubjectAttributesDetails();
  const [
    patientAttributeConfigurationDetailId,
    setPatientAttributeConfigurationDetailId,
  ] = useState(null);

  const queryParams = new URLSearchParams(location.search);
  const pacId = queryParams.get("pacId");

  useEffect(() => {
    if (patientAttributeConfigurationDetailId) {
      fetchChoices(patientAttributeConfigurationDetailId);
    }
  }, [patientAttributeConfigurationDetailId]);

  const fetchChoices = async (id) => {
    try {
      const data = await getChoicesByDetailId(id);
      const allChoices =
        data.result.patientAttributeConfigurationDetailChoices || [];
      setChoices(allChoices);
    } catch (error) {
      console.error("Error fetching choices: ", error.message);
      setChoices([]);
    }
  };

  const { data: attributeDetails } = useQuery(
    ["attributeDetails", pacId],
    () => fetchAttributeDetailsByGuid(pacId),
    {
      enabled: !!pacId,
      onSuccess: (data) => {
        const details = data.patientAttributeConfigurationDetails;
        if (details && details.length > 0) {
          const id = details[0].id;
          setPatientAttributeConfigurationDetailId(id);
        }
      },
      onError: (error) => {
        console.error("Error fetching attribute details: ", error.message);
      },
    }
  );

  const onSubmit = async (data) => {
    const choiceText = data.choiceText;
    addOrEditChoiceMutation.mutate(
      {
        patientAttributeConfigurationDetailId,
        choiceText,
      },
      {
        onSuccess: (response) => {
          if (response && response.data && response.data.result) {
            queryClient.invalidateQueries(["attributeDetails", pacId]);
            reset();
              setTimeout(() => {
              toast.success("Choice added successfully!");
            }, 1000);
            fetchChoices(patientAttributeConfigurationDetailId)
          }
        },
        onError: (error) => {
          console.error("Error adding choice:", error);
          toast.error("Failed to add choice.");
        },
      }
    );
  };
  
  const handleRemoveClick = (index) => {
    setSelectedChoiceIndex(index);
    setOpenDeleteModal(true);
  };

  const handleRemove = async () => {
    try {
      const choiceToRemove = choices[selectedChoiceIndex];
      const response = await removeEditChoices(
        patientAttributeConfigurationDetailId,
        choiceToRemove.id
      );
      

      if (response.status==200) {
        const newChoices = [...choices];
        newChoices.splice(selectedChoiceIndex, 1);
        setChoices(newChoices);
        toast.success("Choice removed successfully!");
      }
    } catch (error) {
      console.error("Error removing choice:", error);
      toast.error("Failed to remove choice.");
    }finally {
      setOpenDeleteModal(false);
    }
  };

  const handleCloseModal = () => {
    setOpenDeleteModal(false);
  };

  return (
    <Box sx={{ maxWidth: 800, margin: "0 auto", padding: 2 }}>
      <Typography
        variant="h4"
        gutterBottom
        align="center"
        sx={{ fontWeight: "bold" }}
      >
        Attribute Choices
      </Typography>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: 3,
            justifyContent: "center",
          }}
        >
          <Controller
            name="choiceText"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                variant="outlined"
                label="Enter choice text"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                fullWidth
                InputProps={{ style: { fontSize: "1.5rem" } }}
                InputLabelProps={{ style: { fontSize: "1.5rem" } }}
                sx={{ marginRight: 2, width: "70%" }}
              />
            )}
          />
          <Button
            type="submit"
            variant="primary"
            sx={{
              padding: "10px 20px",
              backgroundColor: "#007bff",
              color: "#fff",
            }}
          >
            Add Choice
          </Button>
        </Box>
      </Form>

      <TableContainer component={Paper} sx={{ marginBottom: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                sx={{ fontWeight: "bold", fontSize: "1.5rem" }}
              >
                ID
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: "bold", fontSize: "1.5rem" }}
              >
                Choice Text
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: "bold", fontSize: "1.5rem" }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {choices.length > 0 ? (
              choices.map((choice, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{ fontWeight: "bold", fontSize: "1.5rem" }}
                    align="center"
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: "bold", fontSize: "1.5rem" }}
                    align="center"
                  >
                    {choice.text}
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="danger"
                      sx={{
                        backgroundColor: "#dc3545",
                        color: "#fff",
                        padding: "5px 10px",
                      }}
                      onClick={() => handleRemoveClick(index)}
                    >
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "1.8rem",
                      fontWeight: "bold",
                      color: "#555",
                      textAlign: "center",
                    }}
                  >
                    No choices added yet.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ marginTop: 4, textAlign: "center" }}>
        <Button
          variant="primary"
          sx={{
            padding: "10px 20px",
            backgroundColor: "#007bff",
            color: "#fff",
          }}
          onClick={() => {
            navigate("/subject-attributes");
          }}
        >
          Back to Configuration
        </Button>
      </Box>
      <DeleteModal
        open={openDeleteModal}
        handleClose={handleCloseModal}
        handleDelete={handleRemove}
        subject={"choice"}
      />
      <ToastContainer />
    </Box>
  );
};

export default EditChoice;
