import React, { useState } from "react";
import "./Study.css";
import { Link, useNavigate } from "react-router-dom";
import { Container, Grid, Box, Typography, Button, FormControl, InputLabel, MenuItem, Select, Paper, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Stack, IconButton, } from "@mui/material";
import { CloseOutlined, Download, UploadFile } from "@mui/icons-material";
import * as XLSX from "xlsx";
import Papa from 'papaparse';
import { toast } from "react-toastify";
import { BASE_API_URL } from "../../../utils/api-integration";
import axios from "axios";
import { dataServer } from "../../../services/axios.config";
import { FaBullseye } from "react-icons/fa";

const ImportUser = () => {
  const navigate = useNavigate();

  const [delimiter, setDelimiter] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [validatedData, setValidatedData] = useState([]);
  const [validateErrorsData, setValidateErrorsData] = useState([]);
  const [isValidated, setIsValidated] = useState(false);
  const [loadValidate, setLoadValidate] = useState(false);
  const [loadImport, setLoadImport] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setDelimiter(event.target.value);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleDownloadTemplate = () => {
    // Simulate download of CSV template
    const csvContent = "email,firstName,lastName,isActive,isHelpDesk,studyName,roleName,siteName,siteNumber,staticPassword\n";
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", "user_template.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function validateErrors(errorArray) {
    const noErrorString = "No Error(s) in this Row";
    let validated = true;
    let errorMessages = [];

    for (let i = 0; i < errorArray.length; i++) {
        if (!errorArray[i].includes(noErrorString)) {
            validated = false;
            errorMessages.push(errorArray[i]);
        }
    }

    return {
        validated: validated,
        errorMessages: validated ? [] : errorMessages
    };
  }

  const handleValidateImport = async () => {
    if (!selectedFile) {
      toast.warning("Please select a file to validate");
      return;
    }
    setLoadValidate(true);
    Papa.parse(selectedFile, {
      header: true,
      complete: async function (results) {
        const data = results.data.map((item) => ({
          entity: {
            email: item.email,
            firstName: item.firstName,
            lastName: item.lastName,
            isActive: item.isActive === 'true',
            isHelpDesk: item.isHelpDesk === 'true',
            studyName: item.studyName,
            roleName: item.roleName,
            siteName: item.siteName,
            siteNumber: item.siteNumber,
            staticPassword: item.staticPassword === 'true',
          },
        }));

        // Send data to API
        try {
          const resp = await dataServer.post(`app/User/ValidateImport`, data?.slice(0, -1));
          let result = validateErrors(resp?.data);
          console.log(result);

          if(result?.validated){
            toast.success("Your file validated successfully, You can now proceed further for import.", {
              toastId: "successImportValidate",
            });
            setIsValidated(true);
            setValidatedData(data?.slice(0, -1));
            setLoadValidate(false);
          } else {
            // result?.errorMessages ko map karna hai
            setValidateErrorsData(result?.errorMessages)
            setIsValidated(false);
            setLoadValidate(false);
            handleClickOpen();
          }
        } catch (error) {
          console.log({error});
          setLoadValidate(false);
          toast.error(error.response?.data?.title, {
            toastId: "errorImportValidate",
          });
        }
      },
    });

    // const fileExtension = selectedFile.name.split(".").pop();
    // if (fileExtension === "csv") {
    //   // Validate CSV file
    //   const reader = new FileReader();
    //   reader.onload = () => {
    //     const text = reader.result;
    //     // Perform CSV validation (basic check)
    //     if (text.includes("email") && text.includes("firstName")) {
    //       toast.success(`File ${selectedFile.name} is valid.`);
    //     } else {
    //       toast.error(`File ${selectedFile.name} is invalid.`);
    //     }
    //   };
    //   reader.readAsText(selectedFile);
    // } else if (fileExtension === "xlsx") {
    //   // Validate Excel file
    //   const reader = new FileReader();
    //   reader.onload = () => {
    //     const data = new Uint8Array(reader.result);
    //     const workbook = XLSX.read(data, { type: "array" });
    //     const firstSheetName = workbook.SheetNames[0];
    //     const worksheet = workbook.Sheets[firstSheetName];
    //     const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    //     // Perform Excel validation (basic check)
    //     if (
    //       jsonData[0].includes("email") &&
    //       jsonData[0].includes("firstName")
    //     ) {
    //       toast.success(`File ${selectedFile.name} is valid.`);
    //     } else {
    //       toast.error(`File ${selectedFile.name} is invalid.`);
    //     }
    //   };
    //   reader.readAsArrayBuffer(selectedFile);
    // } else {
    //   toast.warning("Unsupported file format.");
    // }
  };

  const handleImport = async () => {
    try {
      if(isValidated){
        setLoadImport(true);
        const resp = await dataServer.post(`app/User/ImportUsers`, validatedData);
        console.log({resp});
        if(resp?.data == "imported"){
          setLoadImport(false);
          toast.success("Users from file imported successfully.", {
            toastId: "successImport",
          });
          navigate("/all-users");
        }
      }
    } catch (error) {
      console.log({error});
      setLoadImport(false);
      toast.error(error.response?.data?.title, {
        toastId: "errorImport",
      });
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h3" component="h1" gutterBottom>
        Import Users
      </Typography>
      <p className="admin-link" style={{ fontWeight: "600" }}>
        <Link to="/homepage">Home</Link>{" > "}
        <Link to="/all-users">All Users</Link>{" > "}
        <span
          style={{
            color: "#4b8ac0",
            cursor: "pointer",
          }}
        >
          Import User
        </span>
      </p>
      <Dialog open={open} onClose={handleClose} scroll="paper">
        <IconButton
          aria-label="close"
          onClick={handleClose}
          size="large"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.black,
          }}
        >
          <CloseOutlined />
        </IconButton>
        <DialogContent style={{marginTop: 20}}>
          <p style={{fontWeight: "bold", fontSize: 17}}>Your file have some data validation errors. Please solve these and then again import.</p>
          <p style={{fontWeight: "bold"}}>Errors are listed below :-</p>
          <ol>
            {
              validateErrorsData?.map((data, i) => <li key={i}>{data}</li>)
            }
          </ol>
        </DialogContent>
      </Dialog>
      <Paper elevation={3} sx={{ p: 4 }}>
        {/* <Box mb={4}>
          <FormControl fullWidth>
            <InputLabel id="delimiter-label" sx={{ fontSize: '1.25rem' }}>Delimiter</InputLabel>
            <Select
              labelId="delimiter-label"
              value={delimiter}
              onChange={handleChange}
              label="Delimiter"
              sx={{ fontSize: '1.25rem' }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value=",">Comma</MenuItem>
              <MenuItem value=";">Semicolon</MenuItem>
              <MenuItem value="\t">Tab</MenuItem>
            </Select>
          </FormControl>
        </Box> */}
        <Box mb={4}>
          <Typography variant="h5" sx={{ fontSize: '1.75rem' }}>Template</Typography>
          <Button
            variant="outlined"
            startIcon={<Download />}
            onClick={handleDownloadTemplate}
            sx={{ mt: 2, fontSize: '1.25rem' }}
          >
            Download Template
          </Button>
        </Box>
        <Box mb={4}>
          <Typography variant="h5" sx={{ fontSize: '1.75rem' }}>Import File</Typography>
          <input
            type="file"
            accept=".csv, .xlsx"
            style={{ display: "none" }}
            id="file-upload"
            onChange={handleFileChange}
          />
          <label htmlFor="file-upload">
            <Button
              variant="outlined"
              component="span"
              startIcon={<UploadFile />}
              sx={{ mt: 2, fontSize: '1.25rem', }}
            >
              Select File
            </Button>
          </label>
          {selectedFile && (
            <Typography variant="body2" sx={{ mt: 1, fontSize: '1rem' }}>
              Selected file: {selectedFile.name}
            </Typography>
          )}
        </Box>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleValidateImport}
              disabled={loadValidate}
              sx={{ fontSize: '1.25rem',fontWeight: '700', padding: '10px 20px' }}
            >
              {loadValidate ? "Validating..." : "Validate Import"}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleImport}
              disabled={loadImport}
              sx={{ fontSize: '1.25rem', fontWeight: '700' , padding: '10px 20px' }}
            >
              {loadImport ? "Importing..." : "Import"}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default ImportUser;
