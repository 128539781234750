import React from 'react';
import { GridOverlay } from '@mui/x-data-grid';
import BeatLoader from 'react-spinners/BeatLoader';
import { styled } from '@mui/material/styles';

const CustomNoRowsOverlay = styled(GridOverlay)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  font-size: 1.2em;
  color: grey;
`;

const CustomLoadingOverlay = styled(GridOverlay)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export function NoRowsOverlay({ message = "No results found" }) {
  return (
    <CustomNoRowsOverlay>
      {message}
    </CustomNoRowsOverlay>
  );
}

export function LoadingOverlay() {
  return (
    <CustomLoadingOverlay>
      <BeatLoader color="#1976d2" loading={true} size={15} />
    </CustomLoadingOverlay>
  );
}
