import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { Box, Stack, Switch } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import BeatLoader from "react-spinners/BeatLoader";
import { useCallbackPrompt } from "../../../../hooks/useCallbackPrompt";
import DialogBox from "../../../../components/DialogBox";

import {
  editSystemRegion,
  getSystemRegionById,
} from "../../../../services/system_region";

import "../../Users/User.css";
import { styled } from "@mui/material/styles";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const EditRegions = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [id, setId] = useState(location?.state?.id || null);

  const [load, setLoad] = React.useState(false);
  const [region, setRegion] = React.useState("");
  const [isActive, setIsActive] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const createCountrySchema = yup.object().shape({
    name: yup.string().required("This field is required"),
    isActive: yup.bool().oneOf([true, false], "This field is required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(createCountrySchema),
  });

  const fetchRegionById = async (id) => {
    try {
      setLoad(true);
      const res = await getSystemRegionById(id);

      if (res.status) {
        setLoad(false);
        const { name, isActive } = res.data;
        setIsActive(isActive);
        reset({ name, isActive });
      }
    } catch (err) {
      setLoad(false);
      console.log("Error: ", err.message);
    }
  };

  useEffect(() => {
    if (id) {
      fetchRegionById(id);
    } else {
      navigate("/error");
    }
  }, [id, reset, navigate]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (showDialog) {
        event.preventDefault();
        event.returnValue = "";
        return "Changes you made will not be saved. Are you sure?";
      }
    };

    const handlePopState = (event) => {
      if (showDialog) {
        const userConfirmed = window.confirm(
          "Changes you made will not be saved. Are you sure?"
        );
        if (!userConfirmed) {
          window.history.pushState(null, "", window.location.href);
        }
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopState);
    };
  }, [showDialog]);

  const notify = () =>
    toast.success("Region Updated Successfully", {
      // theme: "colored",
      toastId: "form-creation",
    });

  const requestFailed = () =>
    toast.error("Something went wrong", {
      // theme: "colored",
      toastId: "requestFailed",
    });

  const handleActiveChange = async (event) => {
    const activeChecked = event.target.checked;
    setIsActive(activeChecked);
  };

  const onSubmit = async (data) => {
    try {
      setLoad(true);
      setShowDialog(false);

      const payload = {
        ...data,
        isActive: isActive
      }
      
      const res = await editSystemRegion(id, payload);
      if (res.status) {
        setLoad(false);
        navigate("/system-settings");
        notify();
      }
    } catch (err) {
      requestFailed();
      console.log("Error: ", err.message);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    if (window.confirm("Changes you made will not be saved. Are you sure?")) {
      navigate("/system-settings");
    }
  };


  const handleNavigation = useCallback(
    (e, path) => {
      if (showDialog) {
        const userConfirmed = window.confirm(
          "Changes you made will not be saved. Are you sure?"
        );
        if (userConfirmed) {
          setShowDialog(false);
          e.preventDefault();
          navigate(path);
        }
      } else {
        navigate(path);
      }
    },
    [showDialog, navigate]
  );

  return (
    <>
      {load ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <BeatLoader color="#3661eb" />
          </div>
        </>
      ) : (
        <>
          <div className="content-body">
            <p className="admin-link" style={{ fontWeight: "600" }}>
            <button onClick={(e) => handleNavigation(e, "/homepage")}>
              <Link>Home</Link>
            </button>
              {" > "}
              <button onClick={(e) => handleNavigation(e, "/system-settings")}>
              <Link>System Settings</Link>
            </button>{" > "}
              <span
                style={{
                  color: "#4b8ac0",
                  cursor: "pointer",
                }}
              >
                Update Region
              </span>
            </p>

            <div>
              <DialogBox
                showDialog={showPrompt}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
              />
            </div>

            <Box sx={{ marginTop: "2%" }}>
              <p className="user-heading">Update Region</p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Row className="flex items-center">
                  <Col xs={12} sm={12} md={6} style={{paddingInline: "3%", paddingBlock: "1%"}}>
                    <label className="uploadInputLabel">
                      Name
                      <span className="error-highlight">*</span>
                    </label>
                    <input
                      className="nameField"
                      type="text"
                      name="name"
                      {...register("name", {
                        onChange: (e) => {
                          setShowDialog(true);
                        },
                      })}
                    />
                    <span className="error-text">{errors.name?.message}</span>
                  </Col>
                  <Col xs={12} sm={12} md={6} style={{paddingInline: "3%", paddingBlock: "1%", marginTop: 25}}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <AntSwitch
                        checked={isActive}
                        onChange={(event) => 
                          handleActiveChange(event)
                        }
                        inputProps={{ "aria-label": "ant design" }}
                      />
                    <p className="generalHead">Active</p>
                    </Stack>
                  </Col>
                </Row>

                <Row style={{ marginTop: "3%" }}>
                  <Col md={6}></Col>
                  <Col md={6}>
                    <div className="createSponsor-buttons">
                      <button
                        className="sponsorCancelButton"
                        onClick={(e) => {
                          handleCancel(e);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        disabled={load}
                        className="sponsorCreateButton"
                        type="submit"
                      >
                        Update
                      </button>
                    </div>
                  </Col>
                </Row>
              </form>
            </Box>
          </div>
        </>
      )}
    </>
  );
};

export default EditRegions;
