import { dataServer } from "./axios.config";
import axios from "axios";

// export const getAllStudy = (pageNumber, pageSize, sortingType, sortingField, debouncedSearchString) => {
//   return new Promise((resolve, reject) => {
//     dataServer
//       .get(`/app/Study?pageNumber=${pageNumber}&pageSize=${pageSize}&sorting=%5B%7B"Field"%3A"${sortingField}"%2C"Order"%3A"${sortingType}"%7D%5D%20&filter=%7B"items"%3A%5B%7B"Id"%3A1%2C"columnField"%3A"name"%2C"operatorValue"%3A"contains"%2C"value"%3A"${debouncedSearchString}"%7D%5D%2C"linkOperator"%3A"And"%2C"quickFilterLogicOperator"%3A"And"%7D`)
//       .then((res) => resolve(res))
//       .catch((err) => reject(err));
//   });
// };
export const getAllStudy = (pageNumber, pageSize, sortingType, sortingField, debouncedSearchString, filters) => {
  return new Promise((resolve, reject) => {
    const filterItems = (Array.isArray(filters) ? filters : []).map((filter, index) => ({
      Id: index + 1,
      columnField: filter.columnField,
      operatorValue: filter.operatorValue,
      value: filter.value,
    }));
    const filterPayload = encodeURIComponent(JSON.stringify({
      items: filterItems,
      linkOperator: "or",
      quickFilterLogicOperator: "or",
    }));
    const sortingPayload = encodeURIComponent(JSON.stringify([{
      Field: sortingField,
      sort: sortingType
    }]));
    const url = `/app/Study?pageNumber=${pageNumber}&pageSize=${pageSize}&sorting=${sortingPayload}&filter=${filterPayload}`;
    dataServer
      .get(url)
      .then((res) => {
        console.log("API Response:", res);
        resolve(res.data);
      })
      .catch((err) => {
        console.error("API Error:", err);
        reject(err);
      });
  });
};


export const getAllExportStudies = () => {
  return new Promise((resolve, reject) => {
    dataServer
    .get(`app/Study/StudyList`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};


export const getStudyById = (id) => {
  return new Promise((resolve, reject) => {
    dataServer
      .get(`/app/Study/${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getAllPms = (pageNumber, pageSize) => {
  return new Promise((resolve, reject) => {
    dataServer
      .get(`/app/ProjectManager?pageNumber=${pageNumber}&pageSize=${pageSize}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const deleteStudy = (id) => {
  return new Promise((resolve, reject) => {
    dataServer
      .delete(`/Study/delete-study?id=${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const createStudy = (data) => {
  const {
    SponsorId,
    PortalUrl,
    Name,
    ApiUrl,
    StudyTypeId,
    QuestionnaireBuilderUrl,
    ProjectManagerId,
    IsActive,
    StudyDbName,
    StudyDbJobId
  } = data || {};

  const formData = new FormData();

  formData.append("Name", Name);
  formData.append("PortalUrl", PortalUrl);
  formData.append("ApiUrl", ApiUrl);
  formData.append("QuestionnaireBuilderUrl", QuestionnaireBuilderUrl);
  formData.append("StudyTypeId", StudyTypeId);
  formData.append("SponsorId", SponsorId);
  if (Array.isArray(ProjectManagerId)) {
    ProjectManagerId.forEach((id) => formData.append("ProjectManagerId[]", id));
  }
  formData.append("StudyDbName", StudyDbName);
  formData.append("StudyDbJobId", StudyDbJobId);
  formData.append("IsActive", IsActive);

  return new Promise((resolve, reject) => {
    dataServer
      .post("/app/Study", formData)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};


// export const editStudy = (id, data) => {
//   const {
//     SponsorId,
//     PortalUrl,
//     Name,
//     ApiUrl,
//     StudyTypeId,
//     QuestionnaireBuilderUrl,
//     ProjectManagerId,
//     IsActive,
//     StudyDbName,
//     StudyDbJobId
//   } = data || {};

//   const formData = new FormData();

//   formData.append("Name", Name);
//   formData.append("PortalUrl", PortalUrl);
//   formData.append("ApiUrl", ApiUrl);
//   formData.append("QuestionnaireBuilderUrl", QuestionnaireBuilderUrl);
//   formData.append("StudyTypeId", StudyTypeId);
//   formData.append("SponsorId", SponsorId);
//   if (Array.isArray(ProjectManagerId)) {
//     ProjectManagerId.forEach((id) => formData.append("ProjectManagerId[]", id));
//   }
//   formData.append("StudyDbName", StudyDbName);
//   formData.append("StudyDbJobId", StudyDbJobId);
//   formData.append("IsActive", IsActive);

//   return new Promise((resolve, reject) => {
//     dataServer
//       .put(`app/Study?id=${id}`, formData)
//       .then((res) => resolve(res))
//       .catch((err) => reject(err));
//   });
// };

export const editStudy = (id, data) => {
  const {
    SponsorId,
    PortalUrl,
    Name,
    ApiUrl,
    StudyTypeId,
    QuestionnaireBuilderUrl,
    ProjectManagerId,
    IsActive,
    StudyDbName,
    StudyDbJobId
  } = data || {};

  const formData = new FormData();

  formData.append("Name", Name);
  formData.append("PortalUrl", PortalUrl);
  formData.append("ApiUrl", ApiUrl);
  formData.append("QuestionnaireBuilderUrl", QuestionnaireBuilderUrl);
  formData.append("StudyTypeId", StudyTypeId);
  formData.append("SponsorId", SponsorId);
  if (Array.isArray(ProjectManagerId)) {
    ProjectManagerId.forEach((id) => formData.append("ProjectManagerId[]", id));
  }
  
  // Append StudyDbName and StudyDbJobId only if they are not null
  if (StudyDbName !== null) {
    formData.append("StudyDbName", StudyDbName);
  }
  
  if (StudyDbJobId !== null) {
    formData.append("StudyDbJobId", StudyDbJobId);
  }
  
  formData.append("IsActive", IsActive);

  return new Promise((resolve, reject) => {
    dataServer
      .put(`app/Study?id=${id}`, formData)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const editStudyStatus = (id, status) => {
  return new Promise((resolve, reject) => {
    dataServer
      .patch(`app/Study/status?id=${id}&status=${status}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
