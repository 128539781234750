import React, { useEffect, useState } from "react";
import DeleteModal from "../../../../components/DeleteModal";
import { Col, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import DataGridComponent from "../../../../components/DataGridComponent";
import BeatLoader from "react-spinners/BeatLoader";
import { GridToolbar } from "@mui/x-data-grid-pro";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import {
  deleteDispensationDetailService,
  getAllDispensationDetails,
} from "../../../../services/dispensations";
import { toast } from "react-toastify";
import { StripedDataGrid } from "./Dispensations";

const getMuiTheme = createTheme({
  typography: {
    fontSize: 25,
  },
  ".css-1x51dt5-MuiInputBase-input-MuiInput-input": {
    color: "#000000 !important",
  },
});

const DispensastionDetails = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const dispensationId = location.state?.id;

  const [load, setLoad] = useState(false);
  const [dispensationDetailsData, setDispensationDetailsData] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [deleteId, setDeleteId] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const notifyDelete = () =>
    toast.success("Dispensation Deleted Successfully", {
      toastId: "form-creation",
    });

  const requestFailed = (msg) =>
    toast.error(`${msg}`, {
      toastId: "requestFailed",
    });

  const rows = dispensationDetailsData?.map((row) => ({
    id: row.id,
    drugTypeName: row.drugTypeName,
    dosageLevelName: row.dosageLevelName,
    quantity: row.quantity,
    doNotDispenseDays: row.doNotDispenseDays,
    edit: row.id,
    delete: row.id,
  }));

  const columns = [
    {
      field: "id",
      hide: true,
      headerName: "Id",
      width: 280,
      renderHeader: () => <div className="grid-heading">{"Id"}</div>,
      renderCell: (params) => {
        const id = params.row.id;

        return (
          <div className="grid-body">
            <p>{id}</p>
          </div>
        );
      },
    },
    {
      field: "drugTypeName",
      // hide: true,
      headerName: "Drug Type",
      width: 240,
      renderHeader: () => <div className="grid-heading">{"Drug Type"}</div>,
      renderCell: (params) => {
        const drug = params.row.drugTypeName;

        return (
          <div className="grid-body">
            <p>{drug}</p>
          </div>
        );
      },
    },
    {
      field: "dosageLevelName",
      // hide: true,
      headerName: "Dose Level",
      width: 240,
      renderHeader: () => <div className="grid-heading">{"Dose Level"}</div>,
      renderCell: (params) => {
        const dose = params.row.dosageLevelName;

        return (
          <div className="grid-body">
            <p>{dose}</p>
          </div>
        );
      },
    },
    {
      field: "quantity",
      // hide: true,
      headerName: "Quantity",
      width: 240,
      renderHeader: () => <div className="grid-heading">{"Quantity"}</div>,
      renderCell: (params) => {
        const quantity = params.row.quantity;

        return (
          <div className="grid-body">
            <p>{quantity}</p>
          </div>
        );
      },
    },
    {
      field: "doNotDispenseDays",
      // hide: true,
      headerName: "DoNotDispenseDays",
      width: 280,
      renderHeader: () => (
        <div className="grid-heading">{"Do Not Dispense Days"}</div>
      ),
      renderCell: (params) => {
        const dispenseDays = params.row.doNotDispenseDays;

        return (
          <div className="grid-body">
            <p>{dispenseDays}</p>
          </div>
        );
      },
    },
    {
      field: "edit",
      // hide: true,
      headerName: "Edit",
      renderHeader: () => <div className="grid-heading">{"Edit"}</div>,
      width: 140,
      renderCell: (params) => {
        const id = params.row.id;

        return (
          <button
            style={{
              cursor: "pointer",
              textAlign: "center",
              backgroundColor: "#337ab7",
              padding: "8px",
              border: "none",
              borderRadius: "5px",
            }}
            onClick={async () => {
              navigate(
                `/study-management/dispensations/edit-dispensation-detail`,
                {
                  state: {
                    id: id,
                    dispensationId: dispensationId,
                  },
                }
              );
            }}
          >
            <FaPencilAlt color="white" size={15} />
          </button>
        );
      },
    },
    {
      field: "delete",
      // hide: true,
      headerName: "Delete",
      renderHeader: () => <div className="grid-heading">{"Delete"}</div>,
      width: 140,
      renderCell: (params) => {
        const id = params.row.id;

        return (
          <button
            onClick={() => {
              setDeleteId(id);
              setShowConfirmModal(true);
            }}
            style={{
              cursor: "pointer",
              textAlign: "center",
              background: "red",
              border: "none",
              borderRadius: "5px",
              padding: "10px 10px",
            }}
          >
            <FaTrashAlt
              color="white"
              style={{
                fontSize: "15px",
              }}
            />
          </button>
        );
      },
    },
  ];

  const fetchAllDispensationDetails = async (id) => {
    try {
      const res = await getAllDispensationDetails(id);
      if (res.status) {
        setDispensationDetailsData(res.data?.result);
      }
    } catch (err) {
      console.log("Error: ", err.message);
    }
  };

  useEffect(() => {
    if (dispensationId) fetchAllDispensationDetails(dispensationId);
  }, [dispensationId]);

  const handleDeleteConfirm = async () => {
    try {
      setLoad(true);
      const res = await deleteDispensationDetailService(deleteId);

      if (res.status) {
        setLoad(false);
        fetchAllDispensationDetails(dispensationId);
        setShowConfirmModal(false);
        notifyDelete();
      }
    } catch (err) {
      console.log("Error: ", err.message);
      setLoad(false);
      setShowConfirmModal(false);
      requestFailed(err.message);
    }
  };

  return (
    <>
      <>
        {load ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "70vh",
              }}
            >
              <BeatLoader color="#3661eb" />
            </div>
          </>
        ) : (
          <div className="content-body">
            <p className="study-management-link" style={{ fontWeight: "600" }}>
              <Link to="/study-management">Manage Your Study</Link> |{" "}
              <Link to="/study-management/dispensations">Dispensations</Link>
            </p>
            <Row style={{ marginTop: "2%", marginBottom: "2%" }}>
              <Col md={10}>
                <div className="study-management-head-start">
                  <p className="study-management-heading">
                    Dispensations Details
                  </p>
                </div>
              </Col>
              <Col md={2} style={{ textAlign: "right" }}>
                <button
                  onClick={() => {
                    navigate(`/study-management/dispensations/create-detail`, {
                      state: {
                        id: dispensationId,
                      },
                    });
                  }}
                >
                  <div className="study-management-head-end">
                    <button className="study-management-create-btn-md">
                      Create Detail
                    </button>
                  </div>
                </button>
              </Col>
            </Row>

            <Box
              sx={{
                height: 459,
                maxHeight: 800,
                width: "100%",
                display: "flex",
              }}
            >
              <ThemeProvider theme={getMuiTheme}>
                <StripedDataGrid
                  className="allQuestionnaire-grid"
                  rows={rows}
                  columns={columns}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 20]}
                  components={{ Toolbar: GridToolbar }}
                  pagination
                  rowHeight={38}
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                  }
                />
              </ThemeProvider>
            </Box>

            <Row style={{ marginTop: "2%" }}>
              <Col md={6}></Col>
              <Col md={6}>
                <Link to="/study-management/dispensations">
                  <div className="study-management-head-end">
                    <button className="study-management-create-btn-lg">
                      Back
                    </button>
                  </div>
                </Link>
              </Col>
            </Row>
          </div>
        )}
        <DeleteModal
          open={showConfirmModal}
          handleClose={() => setShowConfirmModal(false)}
          handleDelete={handleDeleteConfirm}
          subject={"Dispensation detail"}
        />
      </>
    </>
  );
};

export default DispensastionDetails;
