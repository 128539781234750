import { Box } from "@mui/system";
import {
  createTheme,
  ThemeProvider,
  alpha,
  styled,
} from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { FaTrashAlt } from "react-icons/fa";
import { DataGridPro, GridToolbar, gridClasses } from "@mui/x-data-grid-pro";
import { toast } from "react-toastify";
import DeleteModal from "../../../../components/DeleteModal";
import {
  addNavigationService,
  deleteNavigationService,
  getAllVisits,
  getNavigationByIdService,
  getPatientStatusTypesService,
} from "../../../../services/visits";
import { FormControl, MenuItem, Select } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import DialogBox from "../../../../components/DialogBox";
import { useCallbackPrompt } from "../../../../hooks/useCallbackPrompt";

const getMuiTheme = createTheme({
  typography: {
    fontSize: 25,
  },
  ".css-1x51dt5-MuiInputBase-input-MuiInput-input": {
    color: "#000000 !important",
  },
});

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const linkButton = {
  background: "none",
  color: "#337AB7",
  border: "none",
  padding: "0",
  font: "inherit",
  cursor: "pointer",
};

const selectStyles = {
  width: "90%",
  marginTop: 1,
};

const textBoxStyles = {
  fontSize: 15,
  width: "90%",
  height: "10px",
};

const ConfigureNavigations = () => {
  const location = useLocation();
  const [load, setLoad] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const [navigationData, setNavigationData] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [visitName, setVisitName] = useState("");
  const [deleteId, setDeleteId] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);
  const id = location.state.id;
  const [allStatusData, setAllStatusData] = useState([]);
  const [allVisitData, setAllVisitData] = useState([]);

  const navigate = useNavigate();

  // This is for scrolling the page to the top specially when the user comes from the Create childVisitName page.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const createNavigationSchema = yup.object().shape({
    patientStatusTypeId: yup.string().required("This field is required"),
    childVisitId: yup.string().required("This field is required"),
  });

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createNavigationSchema),
  });

  const notifyDelete = () =>
    toast.success("Navigation Deleted Successfully", {
      // theme: "colored",
      toastId: "form-creation",
    });

  const notify = () =>
    toast.success("Navigation Added Successfully", {
      // theme: "colored",
      toastId: "form-creation",
    });

  const requestFailed = (msg) =>
    toast.error("Something went wrong", {
      // theme: "colored",
      toastId: "requestFailed",
    });

  useEffect(() => {
    fetchAllStatus();
    fetchAllVisits();
  }, []);

  useEffect(() => {
    if (id) {
      fetchNavigationsById(id);
    }
  }, [id]);

  //   console.log("datas-->", navigationData);

  const rows = navigationData?.map((item) => ({
    id: item?.id,
    patientStatusTypeName: item?.patientStatusTypeName,
    childVisitName: item?.childVisitName,
    delete: item?.id,
  }));

  const columns = [
    {
      field: "id",
      hide: true,
      headerName: "ID",
      width: 0,
      renderHeader: () => <div className="grid-heading-id">{"ID"}</div>,
      renderCell: (params) => {
        const id = params.row.id;

        return (
          <div className="grid-id">
            <p>{id}</p>
          </div>
        );
      },
    },
    {
      field: "patientStatusTypeName",
      // hide: true,
      headerName: "Patient Status",
      width: 300,
      renderHeader: () => (
        <div className="grid-heading">{"Patient Status"}</div>
      ),
      renderCell: (params) => {
        const patientStatusTypeName = params.row.patientStatusTypeName;

        return (
          <div className="grid-body">
            <p>{patientStatusTypeName}</p>
          </div>
        );
      },
    },
    {
      field: "childVisitName",
      // hide: true,
      headerName: "Navigate To Visit",
      width: 300,
      renderHeader: () => (
        <div className="grid-heading">{"Navigate To Visit"}</div>
      ),
      renderCell: (params) => {
        const childVisitName = params.row.childVisitName;

        return (
          <div className="grid-body">
            <p>{childVisitName}</p>
          </div>
        );
      },
    },
    {
      field: "delete",
      // hide: true,
      headerName: "Delete",
      renderHeader: () => <div className="grid-heading">{"Delete"}</div>,
      width: 250,
      renderCell: (params) => {
        const id = params.row.id;

        return (
          <button
            onClick={() => {
              setDeleteId(id);
              setShowConfirmModal(true);
            }}
            style={{
              cursor: "pointer",
              textAlign: "center",
              background: "red",
              border: "none",
              borderRadius: "5px",
              padding: "10px 10px",
            }}
          >
            <FaTrashAlt
              color="white"
              style={{
                fontSize: "15px",
              }}
            />
          </button>
        );
      },
    },
  ];

  const fetchNavigationsById = async (id) => {
    setLoad(true);
    try {
      const res = await getNavigationByIdService(id);
      if (res?.status) {
        setNavigationData(res?.data?.result?.navigationsData);
        setVisitName(res?.data?.result?.visitData?.name);
        setLoad(false);
      }
    } catch (err) {
      console.log("Error: ", err.message);
    }
  };

  const fetchAllStatus = async () => {
    try {
      const res = await getPatientStatusTypesService();
      if (res.status) {
        setAllStatusData(res?.data?.result);
      }
    } catch (err) {
      console.log("Error: ", err.message);
      requestFailed(err.message);
    }
  };

  const fetchAllVisits = async () => {
    try {
      const res = await getAllVisits();
      if (res.status) {
        setAllVisitData(res?.data?.result);
      }
    } catch (err) {
      console.log("Error: ", err.message);
      requestFailed(err.message);
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      setLoad(true);
      const data = {
        id: deleteId,
        visitId: id,
      };
      const res = await deleteNavigationService(data);
      if (res.status) {
        setLoad(false);
        fetchNavigationsById(id);
        setShowConfirmModal(false);
        notifyDelete();
      }
    } catch (err) {
      console.log("Error: ", err.message);
      setLoad(false);
      setShowConfirmModal(false);
      requestFailed(err.message);
    }
  };

  const onSubmit = async (data) => {
    // e.preventDefault();
    setLoad(true);
    // console.log("data", data);
    data.visitId = id;
    try {
      const res = await addNavigationService(data);
      if (res.status) {
        fetchNavigationsById(id);
        notify();
        reset();
        setLoad(false);
      }
    } catch (err) {
      setLoad(false);
      console.log("Error: ", err.message);
      requestFailed(err.message);
    }
  };

  return (
    <>
      {load ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <BeatLoader color="#3661eb" />
          </div>
        </>
      ) : (
        <div className="content-body">
          <p className="study-management-link" style={{ fontWeight: "600" }}>
            <Link to="/study-management">Manage Your Study</Link> |{" "}
            <Link to="/study-management/visits">Visits</Link> |{" "}
            <button
              style={linkButton}
              onClick={() => {
                navigate("/study-management/visits/edit", {
                  state: {
                    id: id,
                  },
                });
              }}
            >
              Edit Visit
            </button>
            |{" "}
            <button
              style={linkButton}
              onClick={() => {
                navigate("/study-management/visits/configure-navigation", {
                  state: {
                    id: id,
                  },
                });
              }}
            >
              Configure Navigations
            </button>
          </p>
          <Row style={{ marginTop: "2%", marginBottom: "2%" }}>
            <Col md={12}>
              <div className="study-management-head-start">
                <p className="study-management-heading">
                  {visitName} Navigations
                </p>
              </div>
            </Col>
          </Row>
          <div>
            <DialogBox
              showDialog={showPrompt}
              confirmNavigation={confirmNavigation}
              cancelNavigation={cancelNavigation}
            />
          </div>

          <Row>
            <Col md={8} sm={12}>
              <Box sx={{ height: 400, width: "100%", display: "flex" }}>
                <ThemeProvider theme={getMuiTheme}>
                  <StripedDataGrid
                    className="allQuestionnaire-grid"
                    rows={rows}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20]}
                    // components={{ Toolbar: GridToolbar }}
                    pagination
                    rowHeight={38}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                    getRowClassName={(params) =>
                      params.indexRelativeToCurrentPage % 2 === 0
                        ? "even"
                        : "odd"
                    }
                  />
                </ThemeProvider>
              </Box>
            </Col>
            <Col md={4} sm={12}>
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
                sx={{
                  border: "1px solid rgb(224 224 224)",
                  borderRadius: "5px",
                  padding: "20px",
                  height: "400px",
                }}
              >
                <h4>Add a New Visit Navigation</h4>
                <div style={{ marginTop: "30px" }}>
                  <h5 style={{ fontWeight: "bold" }}>
                    Status
                    <span className="error-highlight">*</span>
                  </h5>
                  <FormControl sx={selectStyles}>
                    <Select
                      name="patientStatusTypeId"
                      defaultValue={""}
                      inputProps={{
                        style: textBoxStyles,
                        "aria-label": "Without label",
                      }}
                      {...register("patientStatusTypeId", {
                        onChange: (e) => {
                          setValue("patientStatusTypeId", e.target.value, {
                            shouldValidate: true,
                          });
                          setShowDialog(true);
                        },
                      })}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {allStatusData &&
                        allStatusData?.map((item) => (
                          <MenuItem key={item?.id} value={item?.id}>
                            {item?.patientStatusDescription}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                {errors.patientStatusTypeId && (
                  <>
                    <div style={{ marginTop: "30px" }}>
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errors.patientStatusTypeId.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                <div style={{ marginTop: "30px" }}>
                  <h5 style={{ fontWeight: "bold" }}>
                    Visit To Navigate To {/* {errors.name?.message ? ( */}
                    <span className="error-highlight">*</span>
                    {/* ) : (
                      <></>
                    )} */}
                  </h5>
                  <FormControl sx={selectStyles}>
                    <Select
                      name="childVisitId"
                      defaultValue={""}
                      inputProps={{
                        style: textBoxStyles,
                        "aria-label": "Without label",
                      }}
                      {...register("childVisitId", {
                        onChange: (e) => {
                          setValue("childVisitId", e.target.value, {
                            shouldValidate: true,
                          });
                          setShowDialog(true);
                        },
                      })}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {allVisitData &&
                        allVisitData?.map((item) => (
                          <MenuItem key={item?.id} value={item?.id}>
                            {item?.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                {errors.childVisitId && (
                  <>
                    <div style={{ marginTop: "30px" }}>
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errors.childVisitId.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                <div style={{ marginTop: "30px", textAlign: "center" }}>
                  <button
                    type="submit"
                    style={{
                      cursor: "pointer",
                      textAlign: "center",
                      color: "white",
                      backgroundColor: "#337ab7",
                      padding: "8px 17px",
                      fontSize: "15px",
                      border: "none",
                      borderRadius: "5px",
                    }}
                  >
                    Add Navigation
                  </button>
                </div>
              </Box>
            </Col>
          </Row>

          <Row style={{ marginTop: "2%" }}>
            <Col md={6}></Col>
            <Col md={6}>
              {/* <Link to="/study-management/visits"> */}
              <div className="study-management-head-end">
                <button
                  className="study-management-create-btn-lg"
                  onClick={() => {
                    navigate("/study-management/visits/edit", {
                      state: {
                        id: id,
                      },
                    });
                  }}
                >
                  Back
                </button>
              </div>
              {/* </Link> */}
            </Col>
          </Row>
        </div>
      )}
      <DeleteModal
        open={showConfirmModal}
        handleClose={() => setShowConfirmModal(false)}
        handleDelete={handleDeleteConfirm}
        subject={"Navigation"}
      />
    </>
  );
};

export default ConfigureNavigations;
