import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import DialogBox from "../../../../../components/DialogBox";
import { useCallbackPrompt } from "../../../../../hooks/useCallbackPrompt";
import { Box } from "@mui/system";
import {
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";

const CreateQuestionnaires = () => {
  const labelStyles = {
    display: "flex",
    alignItems: "center",
    height: "20px",
  };

  const textBoxBody = {
    marginLeft: "10px",
  };

  const textBoxStyles = {
    fontSize: 15,
    width: "500px",
    height: "10px",
  };

  const loadContainerStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70vh",
  };

  const selectStyles = {
    width: 530,
    marginTop: 1,
  };

  const rowStyles = {
    marginTop: "2%",
  };

  const buttonLinkStyle = {
    background: "none",
    color: "#337AB7",
    border: "none",
    padding: "0",
    font: "inherit",
    cursor: "pointer",
  };

  const createQuestionnaireSchema = yup.object().shape({
    questionnaireId: yup.string().required("This field is required"),
    sequence: yup
      .number()
      .typeError("Value must be a number")
      .required("This field is required")
      .min(0, "Value cannot be less than 0"),
    isAudioEnabled: yup.bool().oneOf([true, false]),
    isVideoEnabled: yup.bool().oneOf([true, false]),
  });

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createQuestionnaireSchema),
  });

  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.id;

  const [load, setLoad] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const onSubmit = async (e) => {
    e.preventDefault();
  };

  const handleCancel = (e) => {
    e.preventDefault();
    navigate("/study-management/visits/visit-questionnaire", {
      state: {
        id: id,
      },
    });
  };

  return (
    <>
      {load ? (
        <>
          <div style={loadContainerStyles}>
            <BeatLoader color="#3661eb" />
          </div>
        </>
      ) : (
        <div className="content-body">
          <p className="study-management-link" style={{ fontWeight: "600" }}>
            <Link to="/study-management">Manage Your Study</Link> |{" "}
            <Link to="/study-management/visits">Visits</Link> |{" "}
            <button
              style={buttonLinkStyle}
              onClick={() => {
                navigate("/study-management/visits/visit-questionnaire", {
                  state: {
                    id: id,
                  },
                });
              }}
            >
              Visit Questionnaires
            </button>{" "}
            |{" "}
            <button
              style={buttonLinkStyle}
              onClick={() => {
                navigate("/study-management/visits/create-questionnaire", {
                  state: {
                    id: id,
                  },
                });
              }}
            >
              Create Questionnaires
            </button>
          </p>

          <div>
            <DialogBox
              showDialog={showPrompt}
              confirmNavigation={confirmNavigation}
              cancelNavigation={cancelNavigation}
            />
          </div>

          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ height: "auto", width: "100%" }}
            autoComplete="off"
          >
            <div className="createVisitBody">
              <div className="createVisitHeader">
                <h1>Create Questionnaire</h1>
              </div>
              <div className="createVisitFormBody">
                <div className="createVisitInput">
                  <p style={labelStyles}>
                    Questionnaire Id
                    <span className="error-highlight">*</span>
                  </p>
                  <FormControl sx={selectStyles}>
                    <Select
                      name="questionnaireId"
                      defaultValue={""}
                      inputProps={{
                        style: textBoxStyles,
                        "aria-label": "Without label",
                      }}
                      {...register("questionnaireId", {
                        onChange: (e) => {
                          setValue("questionnaireId", e.target.value, {
                            shouldValidate: true,
                          });
                          setShowDialog(true);
                        },
                      })}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {errors.questionnaireId && (
                  <>
                    <div className="createVisitFlexEnd">
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errors.questionnaireId.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                <div className="createVisitCheckbox">
                  <p>Is Audio Enabled</p>
                  <Checkbox
                    style={{ fontSize: "15px" }}
                    inputProps={{ "aria-label": "controlled" }}
                    {...register("isAudioEnabled", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                </div>

                {errors.isAudioEnabled && (
                  <>
                    <div className="createVisitFlexEnd">
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errors.isAudioEnabled.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}

                <div className="createVisitCheckbox">
                  <p>Is Video Enabled</p>
                  <Checkbox
                    style={{ fontSize: "15px" }}
                    inputProps={{ "aria-label": "controlled" }}
                    {...register("isVideoEnabled", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                </div>

                {errors.isVideoEnabled && (
                  <>
                    <div className="createVisitFlexEnd">
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errors.isVideoEnabled.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}

                <div className="createVisitInput">
                  <p style={labelStyles}>
                    Sequence
                    <span className="error-highlight">*</span>
                  </p>
                  <TextField
                    type={"number"}
                    style={textBoxBody}
                    inputProps={{
                      style: textBoxStyles,
                      min: 0,
                    }}
                    {...register("sequence", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                </div>
                {errors.sequence && (
                  <>
                    <div className="createVisitFlexEnd">
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errors.sequence.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}

                <Row style={rowStyles}>
                  <Col md={8}></Col>
                  <Col md={2}>
                    <div className="study-management-head-end">
                      <button
                        onClick={(e) => {
                          handleCancel(e);
                        }}
                        className="study-management-cancel-btn"
                      >
                        Cancel
                      </button>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="study-management-head-end">
                      <button
                        type="submit"
                        className="study-management-create-btn-md"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Box>
        </div>
      )}
    </>
  );
};

export default CreateQuestionnaires;
