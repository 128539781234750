import { studyServer } from "./study_axios"

export const getAllDoseLevels = (pageNumber, pageSize, sortingType, sortingField, filters) => {
  return new Promise((resolve, reject) => {
    const filterItems = (Array.isArray(filters) ? filters : []).map((filter, index) => ({
      Id: index + 1,
      columnField: filter.columnField,
      operatorValue: filter.operatorValue,
      value: filter.value,
    }));

    const filterPayload = encodeURIComponent(JSON.stringify({
      items: filterItems,
      linkOperator: "and",
      quickFilterLogicOperator: "and",
    }));

    let sortingPayload = "";
    if (sortingField) {
      sortingPayload = encodeURIComponent(JSON.stringify([{
        Field: sortingField,
        sort: sortingType
      }]));
    }

    // const url = `/app/DosageLevel/GetDosageLevelPaging?pageNumber=${pageNumber}&pageSize=${pageSize}` +
    //             (sortingPayload ? `&sorting=${sortingPayload}` : '') +
    //             `&filter=${filterPayload}`;
    const url = `/app/DosageLevel/GetDosageLevelPaging?pageNumber=${pageNumber}&pageSize=${pageSize}&sorting=${sortingPayload}&filter=${filterPayload}`;

    studyServer
      .get(url)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        const status = err.response?.status || 'Unknown Status';
        const message = err.response?.data?.message || err.message || 'An unknown error occurred';
        console.error(`API Error: Status ${status} - ${message}`);
        reject({ status, message });
      });
  });
};



export const createNewDoseLevel = (data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post("/app/DosageLevel/CreateDosageLevel", data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getDoseLevelById = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`/app/DosageLevel/GetDosageLevelByID`, {
        params: { Id: id }
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const editDoseLevels = (id, data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .put(`/app/DosageLevel/UpdateDosageLevel`, data, {
        params: { Id: id }
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};


export const deleteDoseLevels = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`/app/DosageLevel/DeleteDosageLevel`,{
        params: { id }
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getAllExportDose = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`/app/DosageLevel/GetDosageLevelList`,)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};