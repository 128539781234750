import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  FaEdit,
  FaTrashAlt,
  FaCheck,
  FaTimes,
  FaPencilAlt,
} from "react-icons/fa";
import BeatLoader from "react-spinners/BeatLoader";
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  createTheme,
  ThemeProvider,
  alpha,
  styled,
} from "@mui/material/styles";
import {
  getAllDispensations,
  deleteDispensation,
  getAllDispensationsService,
} from "../../../../services/dispensations";
import { DataGridPro, gridClasses, GridToolbar } from "@mui/x-data-grid-pro";

import "../StudySetup.css";
import DataGridComponent from "../../../../components/DataGridComponent";
import DeleteModal from "../../../../components/DeleteModal";

const getMuiTheme = createTheme({
  typography: {
    fontSize: 25,
  },
  ".css-1x51dt5-MuiInputBase-input-MuiInput-input": {
    color: "#000000 !important",
  },
});

export const ODD_OPACITY = 0.2;

export const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const Dispensations = () => {
  const navigate = useNavigate();

  const [load, setLoad] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);

  const [pageSize, setPageSize] = React.useState(5);

  const [dispensationData, setDispensationData] = React.useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);

  const fetchDispensations = async () => {
    try {
      setLoad(true);
      const res = await getAllDispensationsService(page, pageSize);
      if (res.status) {
        setLoad(false);
        setDispensationData(res?.data?.result);
        setTotalCount(res?.data?.totalCount);
      }
    } catch (err) {
      setLoad(false);
      console.log("Error: ", err.message);
      requestFailed(err.message);
    }
  };

  React.useEffect(() => {
    fetchDispensations();
  }, [page, pageSize]);

  const handlePageChange = (newPage) => {
    const updatedPage = newPage + 1; // Adjust for 1-based index

    // Ensure that page number doesn't go below 1
    if (updatedPage >= 1) {
      setPage(updatedPage);
    }
  };

  const notifyDelete = () =>
    toast.success("Dispensation Deleted Successfully", {
      toastId: "form-creation",
    });

  const notifyDeleteMsg = () => {
    toast.warn("Dispensation is already used", {
      toastId: "form-creation",
    });
  };

  const requestFailed = (msg) =>
    toast.error(`${msg}`, {
      toastId: "requestFailed",
    });

  const rows = dispensationData?.map((row) => ({
    id: row.id,
    visits: row.visitName,
    treatment: row.treatmentName,
    isRequired: row.isRequired,
    details: row.dispensationDetails,
    edit: row.id,
    delete: row.id,
  }));

  const columns = [
    {
      field: "id",
      hide: true,
      headerName: "Id",
      width: 290,
      renderHeader: () => <div className="grid-heading">{"Id"}</div>,
      renderCell: (params) => {
        const id = params.row.id;

        return (
          <div className="grid-body">
            <p>{id}</p>
          </div>
        );
      },
    },
    {
      field: "visitName",
      // hide: true,
      headerName: "Visits",
      width: 280,
      renderHeader: () => <div className="grid-heading">{"Visits"}</div>,
      renderCell: (params) => {
        const visits = params.row.visitName;

        return (
          <div className="grid-body">
            <p>{visits}</p>
          </div>
        );
      },
    },
    {
      field: "treatmentName",
      // hide: true,
      headerName: "Treatment",
      width: 280,
      renderHeader: () => <div className="grid-heading">{"Treatment"}</div>,
      renderCell: (params) => {
        const treatment = params.row.treatmentName;

        return (
          <div className="grid-body">
            <p>{treatment}</p>
          </div>
        );
      },
    },
    {
      field: "isRequired",
      // hide: true,
      headerName: "Is Required",
      width: 180,
      renderHeader: () => <div className="grid-heading">{"Is Required"}</div>,
      renderCell: (params) => {
        const isRequired = params.row.isRequired;

        return (
          <button
            style={{
              cursor: "pointer",
              textAlign: "center",
              background: "none",
              border: "none",
              borderRadius: "5px",
            }}
          >
            {isRequired ? (
              <FaCheck
                color="#3661eb"
                style={{
                  fontSize: "15px",
                }}
              />
            ) : (
              <FaTimes
                color="rgb(140 140 140)"
                style={{
                  fontSize: "15px",
                }}
              />
            )}
          </button>
        );
      },
    },
    {
      field: "edit",
      // hide: true,
      headerName: "Edit",
      renderHeader: () => <div className="grid-heading">{"Edit"}</div>,
      width: 180,
      renderCell: (params) => {
        const id = params.row.id;

        return (
          <button
            style={{
              cursor: "pointer",
              textAlign: "center",
              backgroundColor: "#337ab7",
              padding: "8px",
              border: "none",
              borderRadius: "5px",
            }}
            onClick={async () => {
              navigate(`/study-management/dispensations/edit`, {
                state: {
                  id: id,
                },
              });
            }}
          >
            <FaPencilAlt color="white" size={15} />
          </button>
        );
      },
    },
    {
      field: "details",
      // hide: true,
      headerName: "Dispensation Details",
      width: 180,
      renderHeader: () => (
        <div className="grid-heading">{"Dispenstaion Details"}</div>
      ),
      renderCell: (params) => {
        const id = params.row.id;

        return (
          <div className="grid-body">
            <button
              style={{
                background: "none",
                color: "#007bff",
                border: "none",
                padding: "0",
                font: "inherit",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(
                  `/study-management/dispensations/dispensation-detail`,
                  {
                    state: {
                      id: id,
                    },
                  }
                );
              }}
            >
              Details
            </button>
          </div>
        );
      },
    },
    {
      field: "delete",
      // hide: true,
      headerName: "Delete",
      renderHeader: () => <div className="grid-heading">{"Delete"}</div>,
      width: "120",
      renderCell: (params) => {
        const id = params.row.id;

        return (
          <button
            onClick={() => {
              setDeleteId(id);
              setShowConfirmModal(true);
            }}
            style={{
              cursor: "pointer",
              textAlign: "center",
              background: "red",
              border: "none",
              borderRadius: "5px",
              padding: "10px 10px",
            }}
          >
            <FaTrashAlt
              color="white"
              style={{
                fontSize: "15px",
              }}
            />
          </button>
        );
      },
    },
  ];

  const handleDeleteConfirm = async () => {
    try {
      setLoad(true);
      const res = await deleteDispensation(deleteId);

      if (res.status) {
        setLoad(false);
        fetchDispensations();
        setShowConfirmModal(false);
        if (!res?.data?.message?.includes("The")) notifyDelete();
        else {
          notifyDeleteMsg();
        }
      }
    } catch (err) {
      console.log("Error: ", err.message);
      setLoad(false);
      setShowConfirmModal(false);
      requestFailed(err.message);
    }
  };

  return (
    <>
      {load ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <BeatLoader color="#3661eb" />
          </div>
        </>
      ) : (
        <div className="content-body">
          <p className="study-management-link" style={{ fontWeight: "600" }}>
            <Link to="/study-management">Manage Your Study</Link> |{" "}
            <Link to="/study-management/dispensations">Dispensations</Link>
          </p>
          <Row style={{ marginTop: "2%", marginBottom: "2%" }}>
            <Col md={6}>
              <div className="study-management-head-start">
                <p className="study-management-heading">Dispensations</p>
              </div>
            </Col>
            <Col md={6}>
              <Link to="/study-management/dispensations/create">
                <div className="study-management-head-end">
                  <button className="study-management-create-btn-md">
                    Create Dispensation
                  </button>
                </div>
              </Link>
            </Col>
          </Row>

          <Box
            sx={{ height: 559, maxHeight: 800, width: "100%", display: "flex" }}
          >
            <ThemeProvider theme={getMuiTheme}>
              <StripedDataGrid
                className="allQuestionnaire-grid"
                rows={dispensationData}
                columns={columns}
                pageSize={pageSize}
                page={page - 1}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 20]}
                components={{ Toolbar: GridToolbar }}
                onPageChange={handlePageChange}
                pagination
                rowHeight={38}
                rowCount={totalCount}
                paginationMode="serve"
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
              />
            </ThemeProvider>
          </Box>

          <Row style={{ marginTop: "2%" }}>
            <Col md={6}></Col>
            <Col md={6}>
              <Link to="/study-management">
                <div className="study-management-head-end">
                  <button className="study-management-create-btn-lg">
                    Back
                  </button>
                </div>
              </Link>
            </Col>
          </Row>
        </div>
      )}
      <DeleteModal
        open={showConfirmModal}
        handleClose={() => setShowConfirmModal(false)}
        handleDelete={handleDeleteConfirm}
        subject={"Dispensation"}
      />
    </>
  );
};

export default Dispensations;
