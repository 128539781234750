export const API_ENDPOINTS = {
  AUTH: {
    LOGIN_API: "/api/Authenticate/login",
    CHANGE_PASSWORD: "/api/Authenticate/ChangePassword",
  },
  PRIVATE: {
    SPONSOR_IMAGE: "/app/Sponsor/image",
  },
  PUBLIC: {
    LOGIN_API: "/api/Authenticate/login",
    CHANGE_PASSWORD: "/api/Authenticate/ChangePassword",

    //users
    GET_ALL_USERS: "/app/User/getUsers",
    GET_USER_ByID: "/app/User/getUserById",
    CREATE_USER: "/app/User/createUser",
    UPDATE_USER: "/app/User/updateUser",
    USER_STATUS: "/app/User/userStatus",
  },
};
export const QUERIES = {
  AUTH: {},
  CATEGORY: {},
  PRIVATE: {
    SPONSOR_IMAGE: "/app/Sponsor/image",
    //users
    GET_ALL_USERS: "/app/User/getUsers",
    GET_USER_ByID: "/app/User/getUserById",
    CREATE_USER: "/app/User/createUser",
    UPDATE_USER: "/app/User/updateUser",
    USER_STATUS: "/app/User/userStatus",
  },
  PUBLIC: {
    LOGIN_API: "/api/Authenticate/login",
    CHANGE_PASSWORD: "/api/Authenticate/ChangePassword",
  },
};

export const getQueries = (queryObj = {}) => {
  let queryString = Object.keys(queryObj)
    .filter(
      (key) =>
        queryObj[key] &&
        !(Array.isArray(queryObj[key]) && !queryObj[key].length)
    )
    .map((key) => {
      return Array.isArray(queryObj[key])
        ? queryObj[key]
            .map(
              (item) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`
            )
            .join("&")
        : `${encodeURIComponent(key)}=${encodeURIComponent(queryObj[key])}`;
    })
    .join("&");
  return queryString ? `?${queryString}` : "";
};

export const BASE_API_URL = {
  GENESIS_CENTRAL_API_URL: "https://genesisapi.augursapps.com",
};
