// toastNotifications.js
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Notify = (message, type, id, navigateTo = null) => {
  const options = {
    toastId: id,
    // theme: 'colored', // Uncomment and set your desired theme here
  };

  switch (type) {
    case "success":
      toast.success(message, options);
      break;
    case "warn":
      toast.warn(message, options);
      break;
    case "error":
      toast.error(message, options);
      break;
    default:
      toast.info(message, options);
      break;
  }

  if (navigateTo) {
    const navigate = useNavigate();
    navigate(navigateTo);
  }
};

export default Notify;
