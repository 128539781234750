import { useMutation, useQuery } from "react-query";
import { useTreatmentManagement } from "../../context/studyManagement/Treatment/TreatmentContext";
import { createTreatment, deleteTreatment, editTreatment, getAllTreatments, getTreatmentById } from "../../services/treatments";

const useTreatmentDetails = () => {
    const { state, dispatch } = useTreatmentManagement();
    const { pageNumber, pageSize, sortingType, sortingField, filters } = state;

    const fetchTreatmentData = async () => {
        try {
            const res = await getAllTreatments(pageNumber, pageSize, sortingType, sortingField, filters);
            if (res?.status === "Success" && Array.isArray(res.result)) {
                const trimmedData = res.result.map((row) => ({
                    id: row.id,
                    description: row.description,
                    lastUpdate: row.lastUpdate,
                    notes: row.notes,
                    dispensations: row.dispensations
                }));
                return { data:trimmedData, totalCount: res.totalCount }
            } else {
                console.error("Invalid response structure:", res);
                throw new Error("Invalid response structure");
            }
        } catch (error) {
            console.error("Error fetching treatment data:", error.message);
            throw error;
        }
    };

    const fetchTreatmentById = async (id) => {
        try {
            const response = await getTreatmentById(id);
            if (response && response.data) {
                return response.data;
            } else {
                console.error("Error fetching treatment by ID:", response);
                throw new Error("Error fetching treatment level by ID");
            }
        } catch (error) {
            console.error("Error fetching treatment level by ID:", error.message);
            throw error;
        }
    };

    const treatmentQuery = useQuery(
        ["treatmentData", { pageNumber, pageSize, sortingType, sortingField, filters }],
        fetchTreatmentData,
        {
            onSuccess: (data) => {
                dispatch({ type: "SET_TREATMENT_DATA", payload: data.data });
                dispatch({ type: "SET_TOTAL_COUNT", payload: data.totalCount });
                dispatch({ type: "DISABLE_LOADING" });
            },
            onError: (error) => {
                console.error("Error fetching treatment data:", error.message);
                dispatch({ type: "DISABLE_LOADING" });
            },
            refetchOnWindowFocus: false,
        }
    );

    const treatmentByIdQuery = (id) => useQuery(
        ["treatmentById", id],
        () => fetchTreatmentById(id),
        {
            enabled: !!id,
            onSuccess: (data) => {
                dispatch({ type: "SET_TREATMENT_BY_ID", payload: data });
            },
            onError: (error) => {
                console.error("Error fetching treatment by ID:", error.message);
            },
        }
    );

    const createTreatmentMutation = useMutation(createTreatment, {
        onSuccess: (data) => {
            dispatch({ type: "CREATE_TREATMENT_DATA", payload: data.data });
        },
        onError: (error) => {
            console.error("Error creating treatment:", error.message);
        }
    });

    const updateTreatmentMutation = useMutation(({ id, updatedTreatment }) => editTreatment(id, updatedTreatment), {
        onSuccess: (data) => {
            dispatch({ type: "UPDATE_TREATMENT_DATA", payload: data });
        },
        onError: (error) => {
            console.error("Error updating treatment:", error.message);
        }
    });

    const deleteTreatmentMutation = useMutation(deleteTreatment, {
        onSuccess: () => {
            dispatch({ type: "DELETE_TREATMENT_DATA" });
            // Optionally refetch treatment data to update the list
            treatmentQuery.refetch();
        },
        onError: (error) => {
            console.error("Error deleting treatment:", error.message);
        }
    });

    return {
        treatmentQuery,
        treatmentByIdQuery,
        createTreatmentMutation,
        updateTreatmentMutation,
        deleteTreatmentMutation
    };
};

export default useTreatmentDetails;
