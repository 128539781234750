import React, { createContext, useReducer, useContext } from "react";
import subjectAttributeManagementReducer, { initialState as subjectAttributeInitialState } from "./SubjectAttributeReducer";

const SubjectAttributeManagementContext = createContext();

export const SubjectAttributeManagementProvider = ({ children }) => {
    const [state, dispatch] = useReducer(subjectAttributeManagementReducer, subjectAttributeInitialState);

    return (
        <SubjectAttributeManagementContext.Provider value={{ state, dispatch }}>
            {children}
        </SubjectAttributeManagementContext.Provider>
    );
};

export const useSubjectAttributeManagement = () => useContext(SubjectAttributeManagementContext);

export default SubjectAttributeManagementContext;
