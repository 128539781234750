import { dataServer } from "./axios.config";

export const getAllSystemCountries = (
  pageNumber, 
  pageSize, 
  sortingType, 
  sortingField, 
  debouncedSearchString, 
  filters, 
  isCalledFromOuter
) => {
  return new Promise((resolve, reject) => {
    let filterPayload;
    let sortingPayload;

    if (isCalledFromOuter) {
      // Case when IsCalledFromOuter is true
      filterPayload = encodeURIComponent(JSON.stringify({
        searchValue: debouncedSearchString
      }));
      sortingPayload = encodeURIComponent(JSON.stringify([`${sortingField} ${sortingType}`]));
    } else {
      // Case when IsCalledFromOuter is false
      const filterItems = (Array.isArray(filters) ? filters : []).map((filter, index) => ({
        Id: index + 1,
        columnField: filter.columnField,
        operatorValue: filter.operatorValue,
        value: filter.value,
      }));

      filterPayload = encodeURIComponent(JSON.stringify({
        items: filterItems,
        linkOperator: "and",
        quickFilterLogicOperator: "and",
      }));

      sortingPayload = encodeURIComponent(JSON.stringify([{
        Field: sortingField,
        sort: sortingType
      }]));
    }

    const url = `/app/SystemCountry/GetSystemCountries?pageNumber=${pageNumber}&pageSize=${pageSize}&sorting=${sortingPayload}&filter=${filterPayload}&IsCalledFromOuter=${isCalledFromOuter}`;

    console.log("API URL:", url);

    dataServer
      .get(url)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.error("API Error:", err);
        reject(err);
      });
  });
};


export const getAllSystemCountriesPaging = (pageNumber, pageSize, debouncedSearchStringSystemCountries, sortingField, sortingType) => {
  return new Promise((resolve, reject) => {
    const filterPayload = encodeURIComponent(JSON.stringify({
      SearchValue: debouncedSearchStringSystemCountries,
    }));
    const sortingPayload = encodeURIComponent(JSON.stringify([`${sortingField} ${sortingType}`]));
    const url = `/app/SystemCountry/GetSystemCountries?pageNumber=${pageNumber}&pageSize=${pageSize}&sorting=${sortingPayload}&filter=${filterPayload}`;
    dataServer
      .get(url)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.error("API Error:", err);
        reject(err);
      });
  });
};



export const getAllRegions = () => {
  return new Promise((resolve, reject) => {
    dataServer
      .get("/app/SystemRegion", {
        params: {
          pageNumber: 1,
          pageSize: 100000,
        },
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getSystemCountryById = (id) => {
  return new Promise((resolve, reject) => {
    dataServer
      .get(`/app/SystemCountry/${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const deleteSystemCountry = (id) => {
  return new Promise((resolve, reject) => {
    dataServer
      .delete(`/SystemCountry/${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const createSystemCountry = (data) => {
  return new Promise((resolve, reject) => {
    dataServer
      .post("/app/SystemCountry", data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

// export const editSystemCountry = (id, data) => {
//   const { isActive } = data;
//   return new Promise((resolve, reject) => {
//     dataServer
//       .put(`/app/SystemCountry/{id}/${id}?IsActive=${isActive}`, data)
//       .then((res) => resolve(res))
//       .catch((err) => reject(err));
//   });
// };

export const editSystemCountry = (id, data) => {
  const { name, regionId, isoId, isActive } = data;

  const payload = {
    name: name || '',
    isoId: isoId || '',
    regionId: regionId || '',
    isActive: isActive,
  };

  // const queryParams = new URLSearchParams({
  //   IsActive: data.isActive ?? false, // Assuming false is a valid default value
  // });

  return new Promise((resolve, reject) => {
    dataServer
      .put(`/app/SystemCountry/${id}`, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};


export const getAllExportCountry = () => {
  return new Promise((resolve, reject) => {
    dataServer
      .get(`app/SystemCountry/SystemCountryList`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getAllExportLanguage = () => {
  return new Promise((resolve, reject) => {
    dataServer
      .get(`app/SystemLanguage/LanguageList`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getAllExportRegion = () => {
  return new Promise((resolve, reject) => {
    dataServer
      .get(`app/SystemRegion/RegionList`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getAllExportRole = () => {
  return new Promise((resolve, reject) => {
    dataServer
      .get(`app/SystemRoles/SystemRoleList`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};




