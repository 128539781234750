import { createContext, useReducer } from "react";
import studyReducer from "./StudyReducer";
import { getAllStudy, getStudyById } from "../../services/studies";
import { mapStudyData, transformStudyData } from "../../utils/dataMapping";

// const access_token = localStorage.getItem('accessToken');

const StudyContext = createContext();

export const StudyProvider = ({ children }) => {
  const initialState = {
    studyData: [],
    studyByIdData: [],
    load: false,
    totalStudyCount: 0,
  };

  const [state, dispatch] = useReducer(studyReducer, initialState);

  // const fetchStudies = async (pageNumber, pageSize, sortingType, sortingField, debouncedSearchString,filters) => {
  //   setLoading();
  //   try {
  //     const res = await getAllStudy(pageNumber, pageSize, sortingType, sortingField, debouncedSearchString,filters)
  //     console.log(res,'fdgdfgd')
  //     const mappedData = mapStudyData(res.result);
  //     console.log(mappedData,'fgdsogdfog')
  //     dispatch({
  //       type: "GET_STUDIES",
  //       payload: mappedData,
  //     });
  //     dispatch({
  //       type: "SET_TOTAL_STUDY_COUNT",
  //       payload: res.totalCount,
  //     });
  //   } catch (err) {
  //     disableLoading();
  //     console.log("Error: ", err.message);
  //   }
  // };

  const fetchStudies = async (pageNumber, pageSize, sortingType, sortingField, debouncedSearchString, filters) => {
    setLoading();
    try {
      const res = await getAllStudy(pageNumber, pageSize, sortingType, sortingField, debouncedSearchString, filters);
      if (res.status === "Success") {
        let mappedData = mapStudyData(res.result);
        if (sortingField && (sortingType === 'asc' || sortingType === 'desc')) {
          mappedData = mappedData.sort((a, b) => {
            const valueA = a[sortingField] ?? "";
            const valueB = b[sortingField] ?? "";
            if (typeof valueA === 'string' && typeof valueB === 'string') {
              return sortingType === 'asc'
                ? valueA.localeCompare(valueB)
                : valueB.localeCompare(valueA);
            } else {
              // Handle non-string cases if necessary, or provide fallback
              return 0;
            }
          });
        }
        
        dispatch({
          type: "GET_STUDIES",
          payload: mappedData,
        });
        dispatch({
          type: "SET_TOTAL_STUDY_COUNT",
          payload: res.totalCount,
        });
      } else {
        console.error("Failed to fetch studies:", res.message);
        requestFailed();
      }
    } catch (err) {
      disableLoading();
      console.error("Error: ", err.message);

    } finally {
      disableLoading();
    }
  };

  const fetchStudyById = async (id) => {
    try {
      setLoading();
      const res = await getStudyById(id);
      dispatch({
        type: "GET_STUDY_BY_ID",
        payload: res.data,
      });
    } catch (err) {
      console.log("Error: ", err.message);
    } finally {
      disableLoading();
    }
  };

  // Set Loading
  const setLoading = () => dispatch({ type: "SET_LOADING" });

  // Disable Loading
  const disableLoading = () => dispatch({ type: "DISABLE_LOADING" });

  // useEffect(() => {
  //   if (access_token) {
  //     fetchStudies();
  //   }
  // }, [access_token]);

  return (
    <StudyContext.Provider
      value={{
        studyData: state.studyData,
        studyByIdData: state.studyByIdData,
        load: state.load,
        setLoading,
        disableLoading,
        fetchStudies,
        fetchStudyById,
        totalStudyCount: state.totalStudyCount,
      }}
    >
      {children}
    </StudyContext.Provider>
  );
};

export default StudyContext;
