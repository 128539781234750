import React, { useEffect, useState } from "react";
import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";

import { Button } from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  alpha,
  styled,
} from "@mui/material/styles";
import { useNavigate } from "react-router";
import {
  LoadingOverlay,
  NoRowsOverlay,
} from "../../../../components/CutomOverlays";
import useSubjectAttributesDetails from "../../../../hooks/Api/useSubjectAttributesDetails";
import { Visibility } from "@mui/icons-material";
import DeleteModal from "../../../../components/DeleteModal";

const getMuiTheme = createTheme({
  typography: {
    fontSize: 25,
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        cell: {
          "&:focus": {
            outline: "none",
          },
        },
      },
    },
  },
});
const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.background.default,
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    ffontWeight: "bold",
    fontSize: 18,
    color: "#000",
    backgroundColor: theme.palette.grey[300],
  },
}));

const SubjectDataTable = ({
  rows,
  onEdit,
  onDelete,
  selectedPacId,
  noDataMessage,
  setShowDialog,
}) => {
  const navigate = useNavigate();
  const { dataTypeDetailsQuery } = useSubjectAttributesDetails();
  const { data: dataTypes, isLoading } = dataTypeDetailsQuery;

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);

  const handleDeleteClick = (row) => {
    setRowToDelete(row);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = () => {
    if (rowToDelete) {
      onDelete(rowToDelete);
      setDeleteModalOpen(false);
      setRowToDelete(null);
      setShowDialog(true)
    }
  };

  const handleDeleteCancel = () => {
    setDeleteModalOpen(false);
    setRowToDelete(null);
  };

  // useEffect(() => {
  //   if (rows.length > 0) {
  //     localStorage.setItem('subjectDataTableRows', JSON.stringify(rows));
  //   }
  // }, [rows]);

  // useEffect(() => {
  //   const savedRows = JSON.parse(localStorage.getItem('subjectDataTableRows'));
  //   if (savedRows && savedRows.length > 0) {
  //     onEdit(savedRows);
  //   }
  // }, []);

  const columns = [
    { field: "description", headerName: "Description", flex: 1, minWidth: 220 },
    // { field: "textKey", headerName: "Text Key", flex: 1, minWidth: 220 },
    { field: "dataType", headerName: "Data Type", flex: 1, minWidth: 220 },
    {
      field: "minimumValue",
      headerName: "Minimum Value",
      flex: 1,
      minWidth: 220,
    },
    {
      field: "maximumValue",
      headerName: "Maximum value",
      flex: 1,
      minWidth: 220,
    },
    {
      field: "businessRule",
      headerName: "Business Rule",
      flex: 1,
      minWidth: 220,
    },
    {
      field: "maximumLength",
      headerName: "Maximum Length",
      flex: 1,
      minWidth: 220,
    },
    {
      field: "defaultValue",
      headerName: "Default Value",
      flex: 1,
      minWidth: 220,
    },
    // {
    //   field: "businessRuleQuestionText",
    //   headerName: "BusinessRuleQuestionText",
    //   flex: 1,
    //   minWidth: 220,
    // },
    // {
    //   field: "businessRuleQuestionTranslationKey",
    //   headerName: "BusinessRuleQuestionTranslationKey",
    //   flex: 1,
    //   minWidth: 220,
    //   Visibility: false
    // },
    // {
    //   field: "errorMessageKey",
    //   headerName: "ErrorMessageKey",
    //   flex: 1,
    //   minWidth: 220,
    // },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => {
        const dataType = dataTypes?.find(
          (type) => type.name === params.row.dataType
        );

        return (
          <>
            <Button
              variant="danger"
              sx={{
                backgroundColor: "#dc3545",
                color: "#fff",
                padding: "5px 10px",
                marginRight: "5px",
                "&:hover": {
                  backgroundColor: "#ff0018",
                },
                "&:focus": {
                  outline: "none",
                },
              }}
              onClick={() => handleDeleteClick(params.row)}
            >
              Remove
            </Button>
            {/* Conditionally render "Edit Choices" button if isMultipleChoice is true */}
            {dataType?.isMultipleChoice && (
              <Button
                sx={{
                  backgroundColor: "#1976d2",
                  padding: "5px 10px",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#1565c0",
                  },
                }}
                onClick={() => {
                  navigate(`/edit-choice?pacId=${selectedPacId}`);
                }}
              >
                Edit Choices
              </Button>
            )}
          </>
        );
      },
      flex: 1,
      minWidth: 220,
    },
  ];

  return (
    <ThemeProvider theme={getMuiTheme}>
      <div style={{ height: 400, width: "100%" }}>
        <StripedDataGrid
          rows={rows}
          columns={columns}
          loading={isLoading}
          pageSize={5}
          className="allQuestionnaire-grid"
          components={{
            NoRowsOverlay: NoRowsOverlay,
            LoadingOverlay: LoadingOverlay,
          }}
          componentsProps={{
            noRowsOverlay: { message: noDataMessage },
          }}
        />
      </div>
      <DeleteModal
        open={deleteModalOpen}
        handleClose={handleDeleteCancel}
        handleDelete={handleDeleteConfirm}
        subject={`subject attributes`}
      />
    </ThemeProvider>
  );
};

export default SubjectDataTable;
