// actionTypes.js
export const SET_TREATMENT_DATA = "SET_TREATMENT_DATA";
export const CREATE_TREATMENT_DATA = "CREATE_TREATMENT_DATA";
export const UPDATE_TREATMENT_DATA = "UPDATE_TREATMENT_DATA";
export const SET_TREATMENT_BY_ID = "SET_TREATMENT_BY_ID";
export const DISABLE_LOADING = "DISABLE_LOADING";
export const SET_PAGE_NUMBER = "SET_PAGE_NUMBER";
export const SET_PAGE_SIZE = "SET_PAGE_SIZE";
export const SET_SORTING = "SET_SORTING";
export const SET_FILTERS = "SET_FILTERS";
export const SET_TOTAL_COUNT = "SET_TOTAL_COUNT";
export const DELETE_TREATMENT_DATA = "DELETE_TREATMENT_DATA"