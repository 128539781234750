import React, { useState } from "react";
import { Grid, MenuItem, Select, Typography, InputLabel } from "@mui/material";

function TimeSelector({
  selectedDays,
  setSelectedDays,
  selectedHours,
  setSelectedHours,
  selectedMinutes,
  setSelectedMinutes,
  handleDaysChange,
  handleHoursChange,
  handleMinutesChange,
}) {
  const inputStyle = {
    fontSize: 11,
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={4}>
        <InputLabel sx={inputStyle}>Days</InputLabel>
        <Select
          type="number"
          value={selectedDays}
          onChange={handleDaysChange}
          fullWidth
          inputProps={{
            "aria-label": "Without label",
          }}
        >
          <MenuItem key={""} value={""}>
            None
          </MenuItem>
          {Array.from({ length: 31 }, (_, i) => (
            <MenuItem key={i} value={i}>
              {i}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={4}>
        <InputLabel sx={inputStyle}>Hours</InputLabel>
        <Select
          value={selectedHours}
          onChange={handleHoursChange}
          fullWidth
          inputProps={{
            "aria-label": "Without label",
          }}
        >
          <MenuItem key={""} value={""}>
            None
          </MenuItem>

          {Array.from({ length: 24 }, (_, i) => (
            <MenuItem key={i} value={i}>
              {i}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={4}>
        <InputLabel sx={inputStyle}>Minutes</InputLabel>
        <Select
          value={selectedMinutes}
          onChange={handleMinutesChange}
          fullWidth
          inputProps={{
            "aria-label": "Without label",
          }}
        >
          <MenuItem key={""} value={""}>
            None
          </MenuItem>

          {Array.from({ length: 60 }, (_, i) => (
            <MenuItem key={i} value={i}>
              {i}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
}

export default TimeSelector;
