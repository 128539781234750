import { studyServer } from "./study_axios"

export const getAllTreatmentsService = () => {
    return new Promise((resolve, reject) => {
        studyServer
            .get("/app/Treatment/GetTreatments")
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};


export const getAllTreatments = (pageNumber, pageSize, sortingType, sortingField, filters) => {
  return new Promise((resolve, reject) => {
    const filterItems = (Array.isArray(filters) ? filters : []).map((filter, index) => ({
      Id: index + 1,
      columnField: filter.columnField,
      operatorValue: filter.operatorValue,
      value: filter.value,
    }));
    const filterPayload = encodeURIComponent(JSON.stringify({
      items: filterItems,
      linkOperator: "or",
      quickFilterLogicOperator: "or",
    }));
    const sortingPayload = encodeURIComponent(JSON.stringify([{
      Field: sortingField,
      sort: sortingType
    }]));
    const url = `/app/Treatment/GetTreatments?pageNumber=${pageNumber}&pageSize=${pageSize}&sorting=${sortingPayload}&filter=${filterPayload}`;

    studyServer
      .get(url)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        const status = err.response?.status || 'Unknown Status';
        const message = err.response?.data?.message || err.message || 'An unknown error occurred';
        console.error(`API Error: Status ${status} - ${message}`);
        reject({ status, message });
      });
  });
};

export const createTreatment = (data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post(`/app/Treatment/PostTreatment`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getTreatmentById = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`/app/Treatment/${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const editTreatment = (id, data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .put(`/app/Treatment/PutTreatment`, data, {
        params: { Id: id }
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const deleteTreatment = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`/app/Treatment/DeleteTreatment`, {
        params: { id }
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getAllExportTreatment = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`/app/Treatment/GetTreatmentList`,)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
