import CoreAPIService from "./CoreAPIService";
import {
  API_ENDPOINTS,
  BASE_API_URL,
  getQueries,
} from "../utils/api-integration";

const {
  PUBLIC: {
    GET_ALL_USERS,
    GET_USER_ByID,
    CREATE_USER,
    UPDATE_USER,
    USER_STATUS,
  },
} = API_ENDPOINTS;

class UsersServicesApi {
  constructor() {
    this.services = new CoreAPIService(BASE_API_URL.GENESIS_CENTRAL_API_URL);
  }

  getAllUsers = async (pageNumber, pageSize) => {
    const queryParams = getQueries({ pageNumber, pageSize });
    const endpoint = `${GET_ALL_USERS}?${queryParams}`;
    return this.services.get(endpoint);
  };

  getUserById = async (userId) => {
    const endpoint = `${GET_USER_ByID}/${userId}`;
    console.log(endpoint, "Fetching user by ID");
    return this.services.get(endpoint);
  };

  postCreateUser = async (userData) => {
    const endpoint = CREATE_USER;
    console.log(endpoint, "Creating new user");
    return this.services.post(endpoint, userData);
  };

  updateUser = async (id, userData) => {
    const endpoint = `${UPDATE_USER}/?id=${id}`;
    console.log(endpoint, "Updating user");
    return this.services.put(endpoint, userData)
   
  };

  updateUserStatus = async (userId, status) => {
    const endpoint = `${USER_STATUS}/${userId}`;
    console.log(endpoint, "Updating user status");
    return this.services.patch(endpoint, { status });
  };
}

const userApiInstance = new UsersServicesApi();

// export const authApiInstanceLogin = new AuthAPIService(
//   BASE_API_URL.USER_LOGIN_API_URL
// );

export default userApiInstance;
