import { studyServer } from "../../../../../services/study_axios";

export const getAllTabDataService = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .post(`app/StudyCustom/GetAllGroupedValues`, [])
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const updateValueService = (key, value) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post(`app/StudyCustom/Update?Key=${key}&Value=${value}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
