import React, { useState, useEffect } from "react";
import { ListGroup, Dropdown, Button } from "react-bootstrap";
import useSubjectAttributesDetails from "../../../../hooks/Api/useSubjectAttributesDetails";
import BeatLoader from "react-spinners/BeatLoader";
import { toast } from "react-toastify";

const SubjectSidebar = ({
  countries,
  selectedCountry,
  onCountryChange,
  onListItemClick,
  onAddCountry,
}) => {
  const { subjectAttributeQuery, createSubjectAttributetMutation } =
    useSubjectAttributesDetails();
  const { data, isLoading } = subjectAttributeQuery;

  const [pacDisplayNames, setPacDisplayNames] = useState([]);

  // const [pacData, setPacData] = useState([]);

  // useEffect(() => {
  //   if (data?.pac) {
  //     const displayNames = data.pac.map((pacItem) => pacItem.displayName);
  //     setPacDisplayNames(displayNames);
  //   }
  // }, [data]);
  useEffect(() => {
    if (data?.pac) {
      // Sort the countries by displayName in ascending order by default
      const sortedPacDisplayNames = data.pac.sort((a, b) =>
        a.displayName.localeCompare(b.displayName)
      );
      setPacDisplayNames(sortedPacDisplayNames);
    }
  }, [data]);

  useEffect(() => {
    if (
      createSubjectAttributetMutation.isSuccess &&
      createSubjectAttributetMutation.data?.pac
    ) {
      setPacDisplayNames(createSubjectAttributetMutation.data.pac);
    }
  }, [
    createSubjectAttributetMutation.isSuccess,
    createSubjectAttributetMutation.data,
  ]);

  const handleAddCountry = () => {
    if (selectedCountry) {
      const Id = selectedCountry.id;
      const AddCountryId = "";

      createSubjectAttributetMutation.mutate(
        { Id, AddCountryId },
        {
          onSuccess: () => {
            window.location.reload();
            setPacDisplayNames((prevNames) => [
              ...prevNames,
              { displayName: selectedCountry.name },
            ]);
            onCountryChange(null);
            toast.success("Country added successfully!");
          },
          onError: (error) => {
            console.error("Failed to add country:", error);
            toast.error("Failed to add country.");
          },
        }
      );
    } else {
      console.error("Selected country is not defined");
    }
  };

  const handleSort = () => {

  }

  return (
    <div className="flex flex-col justify-between h-screen rounded-md">
      <div className="px-4 py-3 bg-blue-600 text-white text-xl font-bold shadow-md rounded-t-md">
        Added Countries
      </div>
      <ListGroup className={`!px-[10px] py-3 max-h-[60rem] overflow-y-auto`}>
        {isLoading ? (
          <div className="flex justify-center items-center">
            <BeatLoader color="#fff" loading={true} size={15} />
          </div>
        ) : (
          pacDisplayNames.map((item, index) => (
            <ListGroup.Item
              key={index}
              onClick={() => onListItemClick(item)}
              className="cursor-pointer select-none hover:bg-blue-100 transition-colors duration-300"
            >
          
              {item.displayName.charAt(0).toUpperCase() + item.displayName.slice(1).toLowerCase()}
            </ListGroup.Item>
          ))
        )}
      </ListGroup>

      <div className="mt-auto flex flex-col px-4 py-2">
        <Dropdown className="w-full">
          <Dropdown.Toggle
            variant="primary"
            className="!outline-none !bg-white !text-black w-full"
            id="dropdown-basic"
          >
            {selectedCountry?.name || "Select Country"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {isLoading ? (
              <div className="flex justify-center items-center px-4 py-2">
                <BeatLoader color="#1976d2" loading={true} size={15} />
              </div>
            ) : countries.length === 0 ? (
              <div className="flex justify-center items-center px-4 py-2">
                No country found !
              </div>
            ) : (
              countries.map((country, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => onCountryChange(country)}
                  className="flex flex-col gap-3 pl-2"
                >
                 {country.name.charAt(0).toUpperCase() + country.name.slice(1).toLowerCase()}
                </Dropdown.Item>
              ))
            )}
          </Dropdown.Menu>
        </Dropdown>

        <Button
          className="mt-3 !bg-white px-6 py-2 !text-black outline-none"
          onClick={handleAddCountry}
          disabled={!selectedCountry}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export default SubjectSidebar;
