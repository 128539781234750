// import { Box, Select, MenuItem, FormControl, InputLabel, Pagination as MuiPagination, Typography } from "@mui/material";

// export const CustomPagination = ({ currentPage, totalCount, pageSize, onPageChange, onPageSizeChange }) => {
//   const totalPages = Math.ceil(totalCount / pageSize);
//   return (
//     <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', my: 2 }}>
//       <Box>
//         <Typography>Total Entries : {totalCount}</Typography>
//       </Box>
//       <Box sx={{ display: 'flex', flexDirection: "row", alignItems: 'center', }}>
//         <FormControl variant="outlined" sx={{ minWidth: 120, marginRight: "10px" }}>
//           <InputLabel>Page Size</InputLabel>
//           <Select
//             value={pageSize}
//             onChange={onPageSizeChange}
//             label="Page Size"
//             size="small"
//           >
//             <MenuItem value={10}>10</MenuItem>
//             <MenuItem value={20}>20</MenuItem>
//             <MenuItem value={30}>30</MenuItem>
//             <MenuItem value={40}>40</MenuItem>
//             <MenuItem value={50}>50</MenuItem>
//           </Select>
//         </FormControl>
//         <MuiPagination
//           count={totalPages}
//           page={currentPage}
//           onChange={onPageChange}
//           color="primary"
//           variant="outlined"
//           shape="rounded" 
//         />
//       </Box>
//     </Box>
//   );
// };

import { Box, Select, MenuItem, FormControl, InputLabel, Pagination as MuiPagination, Typography } from "@mui/material";

export const CustomPagination = ({ currentPage, totalCount, pageSize, onPageChange, onPageSizeChange }) => {
  const totalPages = Math.ceil(totalCount / pageSize);

  const handlePageChange = (event, value) => {
    console.log("CustomPagination Page Change:", value);
    onPageChange(event, value);
  };

  const handlePageSizeChange = (event) => {
    const newPageSize = event.target.value;
    onPageSizeChange(event);
    onPageChange(null, 1); 
  };
  return (
    <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', my: 2 }}>
      <Box>
        <Typography>Total Entries: {totalCount}</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: "row", alignItems: 'center' }}>
        <FormControl variant="outlined" sx={{ minWidth: 120, marginRight: "10px" }}>
          <InputLabel>Page Size</InputLabel>
          <Select
            value={pageSize}
            onChange={handlePageSizeChange}
            label="Page Size"
            size="small"
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
            <MenuItem value={40}>40</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
        <MuiPagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          variant="outlined"
          shape="rounded" 
        />
      </Box>
    </Box>
  );
};
