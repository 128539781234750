import * as yup from "yup";
// Validation schema using Yup
export const schema = yup.object().shape({
    description: yup.string().required("Description is required"),
    textKey: yup.string().required("Text Key is required"),
    errorDescription: yup.string().required("Error Description is required"),
    errorMessageKey: yup.string().required("Error Message Key is required"),
    valueDescription: yup.string().required("Value Description is required"),
    valueDescriptionKey: yup.string().required("Value Description Key is required"),
    maximumLength: yup.number().required("Maximum Length is required").positive().integer(),
    defaultValue: yup.string().required("Default Value is required"),
    dataType: yup.string().required("Data Type is required"),
    businessRule: yup.string().required("Business Rule is required"),
      // businessRule: yup.string().nullable(),
    businessRuleQuestionText: yup.string().required("Business Rule Question text is required"),
    businessRuleQuestionTranslationKey: yup.string().required("Business Rule Question Translation key is required"),
    errorTranslationKey: yup.string().required("errorTranslationKey key is required"),
    translationKey: yup.string().required("translationKey key is required"),
    valueDescriptionTranslationKey: yup.string().required("valueDescriptionTranslationKey key is required"),
    questionTranslationKey: yup.string().required("questionTranslationKey key is required"),
    maximumValue: yup.number().required("Maximum Value is required").positive().integer(),
    minimumValue: yup.number().required("Minimum Value is required").positive().integer(),

  });