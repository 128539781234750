import { studyServer } from "../../study_axios";

export const getAllBlindingGroup = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get("/app/DrugTypes/GetBlindingGroupList")
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getBlindingGroupById = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`/BlindingGroup/${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const createBlindingGroup = (data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post("/BlindingGroup", data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const updateBlindingGroup = (id, data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`/BlindingGroup/${id}`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
