import { styled, Switch } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import * as yup from "yup";
import PropTypes from "prop-types";
import { Typography, Box } from "@mui/material";
import Notify from "./Notify";

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

// utils.js

export const generateValidationSchema = (fields) => {
  const schema = {};

  fields.forEach((field) => {
    switch (field.type) {
      case "text":
      case "email":
        schema[field.name] = yup
          .string()
          .max(255, `${field.label} must be at most 255 characters`)
          .required(`${field.label} is required`);
        if (field.type === "email") {
          schema[field.name] = schema[field.name].email("Invalid email format");
        }
        break;
      case "checkbox":
        schema[field.name] = yup.array().min(1, `${field.label} is required`);
        break;
      default:
        break;
    }
  });

  return yup.object().shape(schema);
};

export const validationSchemaUser = yup.object().shape({
  firstName: yup.string().max(255).required("First name is required"),
  middleName: yup.string().max(255).required("Middle name is required"),
  email: yup.string().email().required("User email is required"),
  lastName: yup.string().max(255).required("Last name is required"),
});

export const validationSchemaAdmin = yup.object().shape({
  adminFirstName: yup.string().max(255).required("First name is required"),
  adminMiddleName: yup.string().max(255).required("Middle name is required"),
  adminEmail: yup.string().email().required("Admin email is required"),
  adminLastName: yup.string().max(255).required("Last name is required"),
});

export const validationSchemaSponsor = yup.object().shape({
  checkbox: yup.array().min(1),
});

export const validationSchemaStudy = yup.object().shape({
  checkboxStudy: yup.array().min(1),
});

export const formFields = [
  { name: "firstName", label: "First Name", type: "text" },
  { name: "middleName", label: "Middle Name", type: "text" },
  { name: "lastName", label: "Last Name", type: "text" },
  { name: "email", label: "Email", type: "email" },
  { name: "phone", label: "Phone Number", type: "phone" },
];

export const initialData = {
  firstName: "",
  middleName: "",
  lastName: "",
  email: "",
  phone: "",
  isAdmin: false,
  isStatic: false,
  isActive: true,
};

//top utility functions
export const label = { inputProps: { "aria-label": "Checkbox demo" } };

export const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

//constants

export const initialStaticStudyRoles = [
  {
    description: "BioStats",
    isBlinded: false,
    lastUpdate: "2022-11-16T11:05:09.256",
    autoAssigneNewSites: true,
    id: "ce84a2a4-1bf2-e611-80d8-000d3a1029a9",
    name: "BS",
    normalizedName: "BS",
    concurrencyStamp: "95efc3f4-5928-45d1-bef2-3f42c795e300",
  },
  {
    description: "Clinical Research Associate\r\n",
    isBlinded: false,
    lastUpdate: "2022-11-16T11:05:09.256",
    autoAssigneNewSites: true,
    id: "cf84a2a4-1bf2-e611-80d8-000d3a1029a9\r\n",
    name: "CR",
    normalizedName: "CR",
    concurrencyStamp: "0ff47c6d-bc57-4318-bdb3-6ba978450437\r\n",
  },
  {
    description: "Clinical Supply Manager",
    isBlinded: false,
    lastUpdate: "2022-11-16T11:05:09.256",
    autoAssigneNewSites: true,
    id: "d084a2a4-1bf2-e611-80d8-000d3a1029a9",
    name: "CS",
    normalizedName: "CS",
    concurrencyStamp: "2213f213-d093-4381-9e52-08835d84b9aa",
  },
  {
    description: "Depot Vendor",
    isBlinded: false,
    lastUpdate: "2022-11-16T11:05:09.256",
    autoAssigneNewSites: true,
    id: "d184a2a4-1bf2-e611-80d8-000d3a1029a9",
    name: "DV",
    normalizedName: "DV",
    concurrencyStamp: "0f9b3ee6-0ee8-4e7d-b1ec-7941d9056e12",
  },
  {
    description: "Investigator",
    isBlinded: false,
    lastUpdate: "2022-11-16T11:05:09.256",
    autoAssigneNewSites: true,
    id: "d284a2a4-1bf2-e611-80d8-000d3a1029a9",
    name: "IV",
    normalizedName: "IV",
    concurrencyStamp: "c3310f92-0661-40f8-bc44-7d2038da34e8",
  },
  {
    description: "Medical Monitor",
    isBlinded: false,
    lastUpdate: "2022-11-16T11:05:09.256",
    autoAssigneNewSites: true,
    id: "d384a2a4-1bf2-e611-80d8-000d3a1029a9",
    name: "MM",
    normalizedName: "MM",
    concurrencyStamp: "6af24697-82ff-4937-a1e4-966a0ced45b7",
  },
  {
    description: "Pharmacist",
    isBlinded: false,
    lastUpdate: "2022-11-16T11:05:09.256",
    autoAssigneNewSites: true,
    id: "d484a2a4-1bf2-e611-80d8-000d3a1029a9",
    name: "PH",
    normalizedName: "PH",
    concurrencyStamp: "dd6c00d0-668e-4722-aa9f-a85ad994e93f",
  },
  {
    description: "Clinical Project Manager",
    isBlinded: false,
    lastUpdate: "2022-11-16T11:05:09.256",
    autoAssigneNewSites: true,
    id: "d584a2a4-1bf2-e611-80d8-000d3a1029a9",
    name: "PM",
    normalizedName: "PM",
    concurrencyStamp: "b7878951-67ed-4724-a375-b75657ae4c2a",
  },
  {
    description: "Pharmacovigilence",
    isBlinded: false,
    lastUpdate: "2022-11-16T11:05:09.256",
    autoAssigneNewSites: true,
    id: "d684a2a4-1bf2-e611-80d8-000d3a1029a9",
    name: "PV",
    normalizedName: "PV",
    concurrencyStamp: "77c64551-395e-4553-b7c2-5a78eba98584",
  },
  {
    description: "Reports User",
    isBlinded: false,
    lastUpdate: "2022-11-16T11:05:09.256",
    autoAssigneNewSites: true,
    id: "d784a2a4-1bf2-e611-80d8-000d3a1029a9",
    name: "RP",
    normalizedName: "RP",
    concurrencyStamp: "8b62e233-de6b-4a87-9d84-b02f4d6f0648",
  },
  {
    description: "Study Coordinator",
    isBlinded: false,
    lastUpdate: "2022-11-16T11:05:09.256",
    autoAssigneNewSites: true,
    id: "d884a2a4-1bf2-e611-80d8-000d3a1029a9",
    name: "SC",
    normalizedName: "SC",
    concurrencyStamp: "a62aa0e0-5cad-414e-a120-608986ce2b7b",
  },
  {
    description: "Sub-Investigator",
    isBlinded: false,
    lastUpdate: "2022-11-16T11:05:09.256",
    autoAssigneNewSites: true,
    id: "d984a2a4-1bf2-e611-80d8-000d3a1029a9",
    name: "SI",
    normalizedName: "SI",
    concurrencyStamp: "17ee29b2-8386-4394-a4db-4de59e34e477",
  },
  {
    description: "Sponsor",
    isBlinded: false,
    lastUpdate: "2022-11-16T11:05:09.256",
    autoAssigneNewSites: true,
    id: "da84a2a4-1bf2-e611-80d8-000d3a1029a9",
    name: "SP",
    normalizedName: "SP",
    concurrencyStamp: "a6be84c6-6ecd-4144-b59e-9be3eb474dc8",
  },
  {
    description: "Subject",
    isBlinded: false,
    lastUpdate: "2022-11-16T11:05:09.256",
    autoAssigneNewSites: true,
    id: "db84a2a4-1bf2-e611-80d8-000d3a1029a9",
    name: "SU",
    normalizedName: "SU",
    concurrencyStamp: "d773a3fe-0bb0-48d7-81d5-ac96e9176f87",
  },
  {
    description: "Trial Manager",
    isBlinded: false,
    lastUpdate: "2022-11-16T11:05:09.256",
    autoAssigneNewSites: true,
    id: "dc84a2a4-1bf2-e611-80d8-000d3a1029a9",
    name: "TM",
    normalizedName: "TM",
    concurrencyStamp: "5e3718b1-97f0-424b-8174-b4d6dc522a1f",
  },
  {
    description: "Unblinded System Approver",
    isBlinded: false,
    lastUpdate: "2022-11-16T11:05:09.256",
    autoAssigneNewSites: true,
    id: "dd84a2a4-1bf2-e611-80d8-000d3a1029a9",
    name: "UA",
    normalizedName: "UA",
    concurrencyStamp: "ce85c509-f5fe-49c1-8ffd-c03be8b049e3",
  },
  {
    description: "Unblinded CRA",
    isBlinded: false,
    lastUpdate: "2022-11-16T11:05:09.256",
    autoAssigneNewSites: true,
    id: "de84a2a4-1bf2-e611-80d8-000d3a1029a9",
    name: "UB",
    normalizedName: "UB",
    concurrencyStamp: "aa7803be-df77-4f19-b072-9fade635b49c",
  },
  {
    description: "Genesis",
    isBlinded: false,
    lastUpdate: "2022-11-16T11:05:09.256",
    autoAssigneNewSites: true,
    id: "df84a2a4-1bf2-e611-80d8-000d3a1029a9",
    name: "ADM",
    normalizedName: "ADM",
    concurrencyStamp: "9c1efc66-20d4-42ae-a853-b37c932e2b92",
  },
];

export const initialStudySites = [
  { id: 1, name: "Initial Site 1", editId: 1 },
  { id: 2, name: "Initial Site 2", editId: 2 },
  { id: 3, name: "Initial Site 3", editId: 3 },
  { id: 4, name: "Initial Site 4", editId: 4 },
  { id: 5, name: "Initial Site 5", editId: 5 },
  { id: 6, name: "Initial Site 6", editId: 6 },
  { id: 7, name: "Initial Site 7", editId: 7 },
  { id: 8, name: "Initial Site 8", editId: 8 },
  { id: 9, name: "Initial Site 9", editId: 9 },
  { id: 10, name: "Initial Site 10", editId: 10 },
];

/********************************  toast notifications functions  ********************************/

// notifications.js

export const allStudiesRemoved = () => {
  Notify("Select Study After User", "success", "allStudiesRemoved");
};

export const studyRemoved = () => {
  Notify("Study Removed", "success", "studyRemoved");
};

export const notifyPhoneNumber = () => {
  Notify("Phone number is required", "warn", "noPhoneNumber");
};

export const notifyUserNumber = () => {
  Notify("Phone number is required", "warn", "noUserNumber");
};

export const notifyFirstName = () => {
  Notify("First Name is required", "warn", "noFirstName");
};

export const checkBoxOne = () => {
  Notify("Atleast one sponsor is required", "warn", "noFirstName");
};

export const notifyEmail = () => {
  Notify("Email is required", "warn", "noEmail");
};

export const notifyEmailVerify = () => {
  Notify("Please enter a valid email.", "warn", "noEmail");
};

export const notifyStudy = (isEdited = false) => {
  const message = isEdited ? "Study Details Edited" : "Study Details Added";
  Notify(message, "success", "createStudyDetailsToast");
};

export const noStudyFound = () => {
  Notify("No Study Found", "warn", "noStudyFound");
};

export const notifyUser = (isEdited = false) => {
  const message = isEdited ? "User Details Edited" : "User Details Added";
  Notify(message, "success", "createUserSuccessToast");
};

export const notifyAdmin = (isEdited = false) => {
  const message = isEdited ? "Admin Details Edited" : "Admin Details Added";
  Notify(message, "success", "createAdminSuccessToast");
};

export const notifySponsor = (isEdited = false) => {
  const message = isEdited ? "Sponsor Details Edited" : "Sponsor Details Added";
  Notify(message, "success", "createSponsorSuccessToast");
};

export const reqFailed = () => {
  Notify("Something Went Wrong", "error", "reqFailed");
};

/*****************************************************************************************/
