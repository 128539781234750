// import axios from "axios";
import { dataServer } from "./axios.config";

// export const getSystemRoles = (pageNumberRoles, pageSizeRoles, sortingField, sortingType, debouncedSearchStringRoles) => {
//   return new Promise((resolve, reject) => {
//     dataServer
//       .get(pageNumberRoles && pageSizeRoles ? `/app/SystemRoles/GetSystemRoles?pageNumber=${pageNumberRoles}&pageSize=${pageSizeRoles}&sorting=%5B%7B"Field"%3A"shortName"%2C"Order"%3A"${sortingType}"%7D%5D%20&filter=%7B"items"%3A%5B%7B"Id"%3A1%2C"columnField"%3A"shortName"%2C"operatorValue"%3A"contains"%2C"value"%3A"${debouncedSearchStringRoles}"%7D%5D%2C"linkOperator"%3A"And"%2C"quickFilterLogicOperator"%3A"And"%7D` : `/app/SystemRoles?pageNumber=1&pageSize=10000000`)
//       .then((res) => resolve(res))
//       .catch((err) => reject(err));
//   });
// };
export const getSystemRoles = (pageNumber, pageSize, sortingType, sortingField, debouncedSearchString, roleFilters) => {
  return new Promise((resolve, reject) => {
    const filterItems = (Array.isArray(roleFilters) ? roleFilters : []).map((filter, index) => ({
      Id: index + 1,
      columnField: filter.columnField,
      operatorValue: filter.operatorValue,
      value: filter.value,
    }));
    console.log(filterItems,"role dfj")
    const filterPayload = encodeURIComponent(JSON.stringify({
      items: filterItems,
      linkOperator: "or",
      quickFilterLogicOperator: "or",
    }));

    const sortingPayload = encodeURIComponent(JSON.stringify([{
      sort: sortingField,
      Field: sortingType
    }]));
    const url = `/app/SystemRoles/GetSystemRoles?pageNumber=${pageNumber}&pageSize=${pageSize}&sorting=${sortingPayload}&filter=${filterPayload}`;
    dataServer
      .get(url)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.error("API Error:", err);
        reject(err);
      });
  });
};


export const createSystemRole = (data) => {
  return new Promise((resolve, reject) => {
    dataServer
      .post("/app/SystemRoles", data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getSystemRoleById = (id) => {
  return new Promise((resolve, reject) => {
    dataServer
      .get(`/app/SystemRoles/${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const editSystemRole = (id, data) => {
  return new Promise((resolve, reject) => {
    dataServer
      .put(`/app/SystemRoles/${id}`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
