// drugManagementReducer.js
import { SET_DRUG_DATA, CREATE_DRUG_DATA, DISABLE_LOADING, SET_FILTERS, SET_PAGE_NUMBER, SET_PAGE_SIZE, SET_SORTING, SET_TOTAL_COUNT, DELETE_DRUG_DATA, } from './actionTypes';

export const initialState = {
    drugData: [],
    pageNumber: 1,
    pageSize: 10,
    isLoading: true,
    filters: [],
    filterEnabled: false,
    createDrugData: [],
    totalCount: 0,
    sortingType: null,
    sortingField: '',
    deleteDrug: false
};

const drugManagementReducer = (state, action) => {
    switch (action.type) {
        case SET_DRUG_DATA:
            return {
                ...state,
                drugData: action.payload,
            };
        case CREATE_DRUG_DATA:
            return {
                ...state,
                createDrugData: action.payload
            };
        case SET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: action.payload,
            };

        case DISABLE_LOADING:
            return {
                ...state,
                isLoading: false,
            };
        case SET_PAGE_NUMBER:
            return {
                ...state,
                pageNumber: action.payload,
            };
        case SET_PAGE_SIZE:
            return {
                ...state,
                pageSize: action.payload,
                pageNumber: 1,
            };
        case SET_SORTING:
            return {
                ...state,
                sortingType: action.payload.sortingType,
                sortingField: action.payload.sortingField,
            };
        case SET_FILTERS:
            return {
                ...state,
                filters: action.payload,
                pageNumber: 1,
            };
            case DELETE_DRUG_DATA: 
            return{
                ...state,
                deleteDrug: action.payload
            }
        default:
            return state;
    }
};

export default drugManagementReducer;
