import {
  FaEdit,
  FaTrashAlt,
  FaCheck,
  FaTimes,
  FaPencilAlt,
} from "react-icons/fa";
import BeatLoader from "react-spinners/BeatLoader";
import { Box, Chip, MenuItem } from "@mui/material";
import { toast } from "react-toastify";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  createTheme,
  ThemeProvider,
  alpha,
  styled,
} from "@mui/material/styles";
import {
  DataGridPro,
  gridClasses,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import { CustomPagination } from "../../../components/CustomPagination";
import useDrugManagementDetails from "../../../hooks/Api/useDrugManagement";
import {
  drugColumns,
  mapDrugData,
  mapDrugDataPdf,
} from "../../../utils/dataMapping";
import { useDrugManagement } from "../../../context/studyManagement/DrugManagement/DrugManagementContext";
import { getAllExportDrugs } from "../../../services/drugManagement";
import {
  getDownloadReport,
  postExportExcel,
  postExportPDF,
} from "../../../services/sponsors";
import {
  LoadingOverlay,
  NoRowsOverlay,
} from "../../../components/CutomOverlays";
import DeleteModal from "../../../components/DeleteModal";

const getMuiTheme = createTheme({
  typography: {
    fontSize: 25,
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        cell: {
          "&:focus": {
            outline: "none",
          },
        },
      },
    },
  },
});

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.background.default,
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    ffontWeight: "bold",
    fontSize: 18,
    color: "#000",
    backgroundColor: theme.palette.grey[300],
  },
}));

const DrugType = React.memo(() => {
  const navigate = useNavigate();
  const [drugAllData, setDrugAllData] = useState([]);
  const [load, setLoad] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
    name: true,
    code: true,
    isBulk: true,
    maxReturnablePillCount: true,
    packageId: false,
    triggerTemperatureMin: true,
    triggerTemparatureMax: true,
    absoluteTemperatureMin: true,
    absoluteTemperatureMax: true,
    allowedDurationBelowMin: true,
    allowedDurationAboveMax: true,
    edit: true,
    delete: true,
  });
  const {
    state,
    dispatch,
    setPageNumber,
    setPageSize,
    setSorting,
    setFilters,
  } = useDrugManagement();
  const {
    drugData,
    pageNumber,
    pageSize,
    sortingType,
    sortingField,
    filters,
    totalCount,
  } = state;
  const { drugQuery, deleteDrugMutation } = useDrugManagementDetails();
  const { isLoading, data } = drugQuery;
  useEffect(() => {
    if (drugQuery.isFetching) {
      dispatch({ type: "DISABLE_LOADING" });
    }
  }, [drugQuery.isFetching, dispatch]);

  const notifyDelete = () =>
    toast.success("Drug Level Deleted Successfully", {
      toastId: "form-creation",
    });

  const requestFailed = (msg) =>
    toast.error(`${msg}`, {
      toastId: "requestFailed",
    });

  const handleDelete = (id) => {
    deleteDrugMutation.mutate(id, {
      onSuccess: () => {
        fetchAllDataForExport();
        notifyDelete();
      },
      onError: (error) => {
        requestFailed(error.message);
      },
    });
  };

  const columns = [
    ...drugColumns.map((col) => {
      // Determine if we should apply special render logic for the "name" column
      const renderCell = (params) => {
        const value = params.row[col.field];

        if (col.field === "name") {
          // Render the "name" field as a clickable link
          const id = params.row.id; // Assuming "id" is available in the row
          return (
            <a
              href={`/edit-drug-types`}
              style={{
                color: "#337ab7",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();
                navigate(`/edit-drug-types`, { state: { id } });
              }}
            >
              {value}
            </a>
          );
        }

        if (col.type === "boolean") {
          return (
            <Chip
              label={value ? "Yes" : "No"}
              color={value ? "success" : "error"}
              size="small"
            />
          );
        }

        return <div>{value}</div>;
      };

      return {
        field: col.field,
        headerName: col.headerName,
        flex: 1,
        minWidth: 220,
        hide: columnVisibilityModel[col.field],
        filterable: ![
          "isBulk",
          "allowedDurationBelowMin",
          "allowedDurationAboveMax",
          "packageId",
        ].includes(col.field),
        sortable: col.field !== "triggerTemparatureMax",
        renderCell,
      };
    }),
    {
      field: "edit",
      headerName: "Edit",
      flex: 1,
      minWidth: 220,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        const id = params.row.id;
        return (
          <button
            style={{
              cursor: "pointer",
              textAlign: "center",
              backgroundColor: "#337ab7",
              padding: "8px",
              border: "none",
              borderRadius: "5px",
            }}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/edit-drug-types`, { state: { id } });
            }}
          >
            <FaPencilAlt color="white" size={15} />
          </button>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      hide: columnVisibilityModel.delete,
      filterable: false,
      sortable: false,
      flex: 1,
      minWidth: 220,
      renderCell: (params) => {
        const id = params.row.id;

        return (
          <button
            onClick={() => {
              setSelectedId(id);
              setShowConfirmModal(true);
            }}
            // onClick={() => handleDelete(id)}
            style={{
              cursor: "pointer",
              textAlign: "center",
              background: "red",
              border: "none",
              borderRadius: "5px",
              padding: "10px 10px",
            }}
          >
            <FaTrashAlt
              color="white"
              style={{
                fontSize: "15px",
              }}
            />
          </button>
        );
      },
    },
  ];

  const handleDeleteConfirm = async () => {
    try {
      setLoad(true);
      await handleDelete(selectedId);
      setLoad(false);
      notifyDelete();
    } catch (err) {
      console.log("Error: ", err.message);
      setLoad(false);
      requestFailed(err.message);
    } finally {
      setShowConfirmModal(false);
    }
  };

  const rows = drugData?.map((row) => ({
    id: row.id || "-",
    name: row.name || "-",
    code: row.code || "-",
    isBulk: row.isBulk,
    maxReturnablePillCount: row.MaxReturnablePillCount || "-",
    packageId: row.PackageId || "-",
    triggerTemperatureMin: row.TriggerTemparatureMin || "-",
    triggerTemparatureMax: row.TriggerTemparatureMax || "-",
    absoluteTemperatureMin: row.AbsoluteTemparatureMin || "-",
    absoluteTemperatureMax: row.AbsoluteTemparatureMax || "-",
    allowedDurationBelowMin: row.AllowedDurationBelowMin || "-",
    allowedDurationAboveMax: row.AllowedDurationAboveMax || "-",
    edit: row.id,
    delete: row.id,
  }));

  const fetchAllDataForExport = async () => {
    setLoad(true);
    try {
      const res = await getAllExportDrugs();
      if (res.status === 200) {
        const mappedData = mapDrugDataPdf(res.data);
        setDrugAllData(mappedData);
        setLoad(false);
        return res.data;
      }
    } catch (err) {
      console.log("ERROR: ", err);
      setLoad(false);
    }
    return [];
  };

  const handleColumnVisibilityChange = (newModel) => {
    setColumnVisibilityModel(newModel);
  };

  const handleSortModelChange = (sortModel) => {
    if (sortModel.length > 0) {
      const { field, sort } = sortModel[0];
      setSorting(sort, field);
    } else {
      setSorting("asc", "");
    }
  };

  const handleFilterChange = (filterModel) => {
    const updatedFilters = filterModel.items.map((filter) => ({
      columnField: filter.columnField,
      operatorValue: filter.operatorValue,
      value: filter.value || "",
    }));
    if (
      !updatedFilters.length ||
      updatedFilters.some((filter) => !filter.value)
    ) {
      setPageNumber(1);
    }
    setFilters(updatedFilters);
  };

  const handleClearFilters = () => {
    setFilters([]); // Clear all filters
    setPageNumber(1); // Reset to page 1
    drugQuery.refetch(); // Refetch data with no filters
  };

  useEffect(() => {
    fetchAllDataForExport();
  }, []);

  const GridExportToPDF = (props) => {
    return (
      <MenuItem
        onClick={async () => {
          try {
            const res = await postExportPDF(props.rows);
            if (res.status === 200) {
              const res2 = await getDownloadReport(res.data);
              if (res2.status === 200) {
                const blob = new Blob([res2.data], { type: "application/pdf" });
                saveAs(blob, "DrugType.pdf");
              }
            }
            props.hideMenu?.();
          } catch (err) {
            console.log("ERROR: ", err);
          }
        }}
      >
        Export PDF
      </MenuItem>
    );
  };

  const GridExportToExcel = (props) => {
    return (
      <MenuItem
        onClick={async () => {
          try {
            const res = await postExportExcel(props.rows);
            if (res.status === 200) {
              const res2 = await getDownloadReport(res.data);
              if (res2.status === 200) {
                const blob = new Blob([res2.data], {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                saveAs(blob, "DrugType.xlsx");
              }
            }
            props.hideMenu?.();
          } catch (err) {
            console.log("ERROR: ", err);
          }
        }}
      >
        Export Excel
      </MenuItem>
    );
  };
  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridExportToExcel rows={drugAllData} />
      <GridExportToPDF rows={drugAllData} />
    </GridToolbarExportContainer>
  );

  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <CustomExportButton />
    </GridToolbarContainer>
  );

  // const onRowClickHandler = (params) => {
  //   navigate(`/edit-drug-types`, { state: { id: params.row.id } });
  // };

  return (
    <ThemeProvider theme={getMuiTheme}>
      <div className="content-body">
        <p className="study-management-link" style={{ fontWeight: "600" }}>
          <Link to="/study-management">Manage Your Study</Link> |{" "}
          <Link to="/drug-types">Drug Type</Link>
        </p>
        <Row>
          <Col md={6}>
            <div className="study-management-head-start">
              <p className="study-management-heading">Drug Type</p>
            </div>
          </Col>
          <Col md={6}>
            <Link to="/create-drug-types">
              <div className="study-management-head-end">
                <button className="study-management-create-btn-md">
                  Add New
                </button>
              </div>
            </Link>
          </Col>
        </Row>

        <Box
          sx={{
            height: 669,
            width: "100%",
            "& .MuiDataGrid-columnHeaders": {
              minHeight: "58px !important",
            },
          }}
        >
          {" "}
          <ThemeProvider theme={getMuiTheme}>
            <StripedDataGrid
              className="allQuestionnaire-grid"
              rows={rows}
              columns={columns}
              disableSelectionOnClick={true}
              // onRowClick={(params) => navigate(`/edit-drug-types`, { state: { id: params.row.id } })}
              // onRowClick={onRowClickHandler}
              experimentalFeatures={{ newEditingApi: true }}
              loading={isLoading}
              components={{
                Toolbar: CustomToolbar,
                NoRowsOverlay: NoRowsOverlay,
                LoadingOverlay: LoadingOverlay,
              }}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={handleColumnVisibilityChange}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
              filterMode="server"
              onFilterModelChange={handleFilterChange}
              sortingMode="server"
              sortModel={
                sortingField && sortingType
                  ? [{ field: sortingField, sort: sortingType }]
                  : []
              }
              onSortModelChange={handleSortModelChange}
              sx={{
                "& .MuiDataGrid-row:hover": {
                  cursor: "pointer",
                  backgroundColor: "#f5f5f5",
                },
              }}
            />
            <CustomPagination
              currentPage={pageNumber}
              totalCount={totalCount || 0}
              pageSize={pageSize}
              size="large"
              onPageChange={(event, page) =>
                dispatch({ type: "SET_PAGE_NUMBER", payload: page })
              }
              onPageSizeChange={(event) =>
                dispatch({
                  type: "SET_PAGE_SIZE",
                  payload: event.target.value,
                })
              }
            />
          </ThemeProvider>
          <Row style={{ marginTop: "3%" }}>
            <Col md={6}></Col>
            <Col md={6}>
              <div className="createSponsor-buttons">
                <Link to="/study-management">
                  <button className="backToDrugButton">Back</button>
                </Link>
              </div>
            </Col>
          </Row>
        </Box>
      </div>
      <DeleteModal
        open={showConfirmModal}
        handleClose={() => setShowConfirmModal(false)}
        handleDelete={handleDeleteConfirm}
        subject={"Dose Type"}
      />
    </ThemeProvider>
  );
});

export default DrugType;
