import { studyServer } from "./study_axios";

export const getAllDispensations = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get("app/Dispensation/GetDispensationList")
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getAllDispensationsService = (
  pageNumber,
  pageSize,
  filter = "",
  sorting = "",
  isCalledFromOuter = false
) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get("app/Dispensation/GetDispensationSearchPaging", {
        params: {
          pageNumber: pageNumber,
          pageSize: pageSize,
          filter: filter,
          sorting: sorting,
          isCalledFromOuter: isCalledFromOuter,
        },
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const createNewDispensation = (data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post("app/Dispensation/CreateDispensation", data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const deleteDispensation = (Id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`app/Dispensation/DeleteDispensation`, {
        params: { Id },
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getDispensationById = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`app/Dispensation/GetDispensationByID`, {
        params: { id },
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const editDispensation = (id, data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .put(`app/Dispensation/UpdateDispensation`, data, {
        params: { id },
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getAllDispensationDetails = (DispensationId) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get("app/DispensationDetail/GetDetailsOnDispensationID", {
        params: { DispensationId },
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getAllDrugTypesService = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get("app/DrugTypes/GetDrugTypeList")
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getAllDrugLevelService = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get("app/DosageLevel/GetDosageLevelList")
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const createNewDispensationDetailService = (data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post("app/DispensationDetail/CreateDispensationDetail", data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const deleteDispensationDetailService = (Id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`app/DispensationDetail/DeleteDispensationDetail`, {
        params: { Id },
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getDispensationDetailsByIdService = (Id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`app/DispensationDetail/GetDispensationDetailByID`, {
        params: { Id },
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const editDispensationDetailService = (id, data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .put(`app/DispensationDetail/UpdateDispensationDetail`, data, {
        params: { id },
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
