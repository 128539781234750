import { studyServer } from "./study_axios"

export const getAllSubjectAttributes = () => {
    return new Promise((resolve, reject) => {
        studyServer.get("/app/PatientAttributeConfiguration/GetLoadingData").then((res) => resolve(res))
            .catch((err) => reject(err))
    })
}

export const postAllSubjectAttribute = (data) => {
    return new Promise((resolve, reject) => {
        studyServer.post("/app/PatientAttributeConfiguration/PostPAC", data).then((res) => resolve(res))
            .catch((err) => reject(err))
    })
}

export const getAllBusinessDetail = () => {
    return new Promise((resolve, reject) => {
        studyServer.get("/app/PatientAttributeConfiguration/GetBusinessRules").then((res) => resolve(res))
            .catch((err) => reject(err))
    })
}

export const getAllDataTypeDetail = () => {
    return new Promise((resolve, reject) => {
        studyServer.get("/app/PatientAttributeConfiguration/GetDataTypes").then((res) => resolve(res))
            .catch((err) => reject(err))
    })
}

export const getAllSubjectPac = (AddCountryId, Id) => {
    const queryParams = new URLSearchParams();
    
    // Use an empty string if Id is not provided
    queryParams.append("Id", Id || "");
    if (AddCountryId) {
        queryParams.append("AddCountryId", AddCountryId);
    }

    const url = `/app/PatientAttributeConfiguration/GetLoadingData?${queryParams.toString()}`;
    return new Promise((resolve, reject) => {
        studyServer.get(url)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};


export const postSubjectDetailConfig = (data) => {
    return new Promise((resolve, reject) => {
        studyServer.post("/app/PatientAttributeConfiguration/Edit", data).then((res) => resolve(res))
            .catch((err) => reject(err))
    })
}


export const editPatientAttributeConfig = (guid) => {
    const queryParams = new URLSearchParams();
    if (guid) queryParams.append("guid", guid);
    const url = `/app/PatientAttributeConfiguration/Edit?${queryParams.toString()}`
    return new Promise((resolve, reject) => {
        studyServer.get(url).then((res) => resolve(res))
            .catch((err) => reject(err))
    })
}

export const addEditChoices = (PatientAttributeConfigurationDetailId, Text) => {
    const queryParams = new URLSearchParams();
    if (PatientAttributeConfigurationDetailId) queryParams.append("PatientAttributeConfigurationDetailId", PatientAttributeConfigurationDetailId);
    if (Text) queryParams.append("Text", Text);
    const url = `/app/PatientAttributeConfigurationDetail/AddChoice?${queryParams.toString()}`
    return new Promise((resolve, reject) => {
        studyServer.get(url).then((res) => resolve(res))
            .catch((err) => reject(err))
    })
}

export const removeEditChoices = async(PatientAttributeConfigurationDetailId, Id) => {
    const queryParams = new URLSearchParams({
        PatientAttributeConfigurationDetailId,
        Id
    });
    const url = `/app/PatientAttributeConfigurationDetail/RemoveChoice?${queryParams.toString()}`;
    return studyServer.get(url)
        .then(res => Promise.resolve(res))
        .catch(err => Promise.reject(err));
};


//subject configuration

export const getAllSingleAttributeConfig = (groupName) => {
    return new Promise((resolve, reject) => {
        studyServer.get('/app/PatientAttributeConfiguration/SingleConfiguration', {
            params: {
                GroupName: groupName
            }
        })
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}

export const updateSingleAttributeConfig = (key, value) => {
    return new Promise((resolve, reject) => {
        studyServer.get('/app/PatientAttributeConfiguration/UpdateStudyCustom', {
            params: {
                key: key,
                value: value
            }
        })
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}

  export const getChoicesByDetailId = (patientAttributeConfigurationDetailId) => {
    return new Promise((resolve, reject) => {
        studyServer.get(`/app/PatientAttributeConfigurationDetail/GetChoiceListOnDetailId`, {
            params: {
                patientAttributeConfigurationDetailId,
              },
        })
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}