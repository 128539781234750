import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import {
  createTheme,
  ThemeProvider,
  alpha,
  styled,
} from "@mui/material/styles";
import {
  DataGridPro,
  gridClasses,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Box, Chip, MenuItem } from "@mui/material";
import { getUserById } from "../../../services/users";
import { BASE_API_URL } from "../../../utils/api-integration";
import { LoadingOverlay, NoRowsOverlay } from "../../../components/CutomOverlays";

const getMuiTheme = createTheme({
  typography: {
    fontSize: 25,
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        cell: {
          "&:focus": {
            outline: "none",
          },
        },
      },
    },
  },
});

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.background.default,
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    fontWeight: "bold",
    fontSize: 18,
    color: "#000",
    backgroundColor: theme.palette.grey[300],
  },
}));

const SponsorsStudies = () => {
  const [load, setLoad] = useState(true);
  const [studiesData, setStudiesData] = useState([]);
  const navigate = useNavigate();

  const getUsersByIdF = async () => {
    try {
      const res = await getUserById(localStorage.getItem("userId"));
  
      if (res?.data?.result) {
        const sponsors = res.data?.result?.sponsor;
        const studies = res.data?.result?.studies.map(study => {
          const sponsor = sponsors.find(s => s.id === study.sponsorId);
          return {
            ...study,
            sponsorName: sponsor ? sponsor.sponsorName : "Unknown",
          };
        });
  
        setStudiesData(studies);
        setSponsorsData(sponsors);
        setLoad(false);
      }
    } catch (error) {
      setLoad(false);
      console.log({ error });
    }
  };
  

  useEffect(() => {
    getUsersByIdF();
  }, []);

  const columnsStudies = [
    {
      field: "id",
      hide: true,
      headerName: "ID",
      width: 0,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 220,
    },
    {
      field: "sponsorName",
      headerName: "SponsorName",
      flex: 1,
      minWidth: 220,
    },
    {
      field: "portalUrl",
      headerName: "Portal Url",
      flex: 1,
      minWidth: 220,
      renderCell: (params) => {
        if (!params.value) return "-"; // Handle null or undefined value
  
        const url = params.value.startsWith("http")
          ? params.value
          : `http://${params.value}`;
        return (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {params.value}
          </a>
        );
      },
    },
    // {
    //   field: "apiUrl",
    //   headerName: "API Url",
    //   flex: 1,
    //   minWidth: 220,
    //   renderCell: (params) => {
    //     if (!params.value) return "-"; // Handle null or undefined value
  
    //     const url = params.value.startsWith("http")
    //       ? params.value
    //       : `http://${params.value}`;
    //     return (
    //       <a href={url} target="_blank" rel="noopener noreferrer">
    //         {params.value}
    //       </a>
    //     );
    //   },
    // },
    // {
    //   field: "questionnaireBuilderUrl",
    //   headerName: "Question Builder Url",
    //   flex: 1,
    //   minWidth: 220,
    //   renderCell: (params) => (params.value ? params.value : "-"),
    // },
  ];
  
  

  const GridExportToPDF = (props) => {
    return (
      <MenuItem
        onClick={async () => {
          try {
            const res = await postExportPDF(props.rows);
            if (res.status === 200) {
              const res2 = await getDownloadReport(res.data);
              if (res2.status === 200) {
                const blob = new Blob([res2.data], { type: "application/pdf" });
                saveAs(blob, "Sponsors.pdf");
              }
            }
            props.hideMenu?.();
          } catch (err) {
            console.log("ERROR: ", err);
          }
        }}
      >
        Export PDF
      </MenuItem>
    );
  };

  const GridExportToExcel = (props) => {
    return (
      <MenuItem
        onClick={async () => {
          try {
            const res = await postExportExcel(props.rows);
            if (res.status === 200) {
              const res2 = await getDownloadReport(res.data);
              if (res2.status === 200) {
                const blob = new Blob([res2.data], {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                saveAs(blob, "Sponsors.xlsx");
              }
            }
            props.hideMenu?.();
          } catch (err) {
            console.log("ERROR: ", err);
          }
        }}
      >
        Export Excel
      </MenuItem>
    );
  };

  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridExportToExcel rows={studiesData} />
      <GridExportToPDF rows={studiesData} />
    </GridToolbarExportContainer>
  );

  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <CustomExportButton />
    </GridToolbarContainer>
  );

  return (
    <ThemeProvider theme={getMuiTheme}>
        <div className="content-body">
          <Row>
            <Col md={6}>
              <p className="sponsor-heading">Studies</p>
            </Col>
          </Row>
          <Box
            sx={{
              height: 669,
              width: "100%",
              "& .MuiDataGrid-columnHeaders": {
                minHeight: "58px !important",
              },
            }}
          >
            <ThemeProvider theme={getMuiTheme}>
              <StripedDataGrid
                rows={studiesData}
                columns={columnsStudies}
                loading={load}
                components={{
                  Toolbar: CustomToolbar,
                  NoRowsOverlay: NoRowsOverlay,
                  LoadingOverlay: LoadingOverlay,
                }}
                disableSelectionOnClick={true}
                onRowClick={(params) => {
                  navigate("/study-management");
                }}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
                sx={{
                  '& .MuiDataGrid-row:hover': {
                    cursor: 'pointer',
                    backgroundColor: '#f5f5f5',
                  },
                }}
              
              />
            </ThemeProvider>
          </Box>
        </div>
    
    </ThemeProvider>
  );
};

export default SponsorsStudies;
