import { studyServer } from "../study_axios";

export const getAllDrugTypes = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get("/DrugType/get-drugtype")
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getAllDrugPackages = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get("/app/DrugTypes/GetPackageList")
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getDrugTypeById = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`/app/DrugTypes/${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const createDrugType = (data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post("/app/DrugTypes/PostDrugType", data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const updateDrugType = (id, data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .put(`/app/DrugTypes/PutDrugType?id=${id}`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const deleteDrugType = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .delete(`/DrugType/${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
