import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useLocation, useBlocker } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import {
  Box,
  TextField,
  Dialog,
  DialogActions,
  Checkbox,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useCallbackPrompt } from "../../../../hooks/useCallbackPrompt";
import DialogBox from "../../../../components/DialogBox";
import { makeStyles } from "@mui/styles";
import TimeSelector from "./TimeSelector";
import "../StudySetup.css";
import {
  getAllDrugPackages,
  getDrugTypeById,
  updateDrugType,
} from "../../../../services/drug-types-services/drug-types";
import { getAllBlindingGroup } from "../../../../services/drug-types-services/blinding-group/blinding-group";
import { drugSchema, useStyles } from "./DrugUtils";

const EditDrugTypes = () => {
  const navigate = useNavigate();
  const location = useLocation();

  var id = location?.state?.id;

  const [load, setLoad] = useState(false);
  const [blindingData, setBlindingData] = useState([]);
  const [resupplyData, setResupplyData] = useState([]);
  const [shippingData, setShippingData] = useState([]);
  const [packageData, setPackageData] = useState([]);
  const [name, setIsName] = useState();
  const [code, setIsCode] = useState();
  const [reSupplyGroup, setReSupplyGroup] = useState();
  const [shippingGroup, setShippingGroup] = useState();
  const [maxReturnablePillCount, setMaxReturnablePillCount] = useState();
  const [triggerTempMin, setTriggerTempMin] = useState();
  const [triggerTempMax, setTriggerTempMax] = useState();
  const [absoluteTempMin, setAbsoluteTempMin] = useState();
  const [absoluteTempMax, setAbsoluteTempMax] = useState();

  const [showDialog, setShowDialog] = useState(false);
  // const [showPrompt, confirmNavigation, cancelNavigation] =
  //   useCallbackPrompt(showDialog);

  const [selectedDays, setSelectedDays] = useState("");
  const [selectedHours, setSelectedHours] = useState("");
  const [selectedMinutes, setSelectedMinutes] = useState("");

  const [isBulk, setIsBulk] = useState(false);
  const [reSupplyIdState, setSupplyIdState] = useState();
  const [shippingIdState, setShippingIdState] = useState();
  const [blindingIdState, setBlindingIdState] = useState("");
  const [packageIdState, setPackageIdState] = useState("");

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [submitting, setSubmitting] = useState(false);
  const [blockedNavigation, setBlockedNavigation] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(null);

  const [selectedDaysTwo, setSelectedDaysTwo] = useState("");
  const [selectedHoursTwo, setSelectedHoursTwo] = useState("");
  const [selectedMinutesTwo, setSelectedMinutesTwo] = useState("");

  const handleDaysChange = (event) => {
    setSelectedDays(event.target.value);
  };

  const handleHoursChange = (event) => {
    setSelectedHours(event.target.value);
  };

  const handleMinutesChange = (event) => {
    setSelectedMinutes(event.target.value);
  };

  const handleDaysChangeTwo = (event) => {
    setSelectedDaysTwo(event.target.value);
  };

  const handleHoursChangeTwo = (event) => {
    setSelectedHoursTwo(event.target.value);
  };

  const handleMinutesChangeTwo = (event) => {
    setSelectedMinutesTwo(event.target.value);
  };

  // const handleCancel = (e) => {
  //   e.preventDefault();
  //   const confirmLeave = window.confirm(
  //     "Are you sure you want to leave without updating?"
  //   );
  //   if (confirmLeave) {
  //     navigate("/drug-types");
  //   }
  // };
  const handleCancel = () => {
    setOpenConfirmDialog(true);
  };

  // const handleDialogClose = () => {
  //   setOpenConfirmDialog(false);
  // };

  // const handleConfirmNavigation = () => {
  //   setOpenConfirmDialog(false);
  //   navigate("/drug-types");
  // };

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(drugSchema),
  });

  const shouldBlock = useCallback(
    ({ currentLocation, nextLocation }) => {
      if (isDirty && !submitting && !blockedNavigation) {
        // Check submitting state
        setBlockedNavigation(true);
        setPendingNavigation(() => () => navigate(nextLocation.pathname));
        setOpenConfirmDialog(true);
        return true;
      }
      return false;
    },
    [isDirty, navigate, submitting, blockedNavigation]
  );

  useBlocker(shouldBlock, isDirty);

  const fetchBlindingGroup = async () => {
    try {
      setLoad(true);
      const res = await getAllBlindingGroup();
      setBlindingData(res?.data?.result);
    } catch (err) {
      console.log("Error: ", err);
    } finally {
      setLoad(false);
    }
  };

  const fetchDrugPackage = async () => {
    try {
      setLoad(true);
      const res = await getAllDrugPackages();
      setPackageData(res?.data?.result);
    } catch (err) {
      console.log("Error: ", err);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    fetchBlindingGroup();
    fetchDrugPackage();
  }, []);

  useEffect(() => {
    if (packageData?.length) {
      fetchDrugTypeById(id);
    }
  }, [packageData, id]);

  const fetchDrugTypeById = async (id) => {
    try {
      setLoad(true);
      const res = await getDrugTypeById(id);
      if (res.status) {
        const editData = res?.data;
        if (packageData?.length) {
          const selectedPackage = packageData.find(
            (item) => String(item.id) === String(editData.packageId)
          );
          if (selectedPackage) {
            setPackageIdState(selectedPackage.id);
            setValue("packageId", selectedPackage.id);
          } else {
            console.error("Package ID not found:", editData.packageId);
            setPackageIdState("");
            setValue("packageId", "");
          }
        } else {
          console.log("Package data not loaded yet");
        }

        reset(editData);
        setIsBulk(editData.isBulk);
        setShippingIdState(editData.shippingGroup);
        setBlindingIdState(editData.blindingGroupId);
        // setPackageIdState(editData.packageId);

        setIsName(editData.name);
        setIsCode(editData.code);
        setReSupplyGroup(editData.resupplyGroup);
        setMaxReturnablePillCount(editData.maxReturnablePillCount);
        setAbsoluteTempMax(editData.absoluteTemperatureMax);
        setAbsoluteTempMin(editData.absoluteTemperatureMin);
        setTriggerTempMax(editData.triggerTemperatureMax);
        setTriggerTempMin(editData.triggerTemperatureMin);
        // setSupplyIdState(editData.resupplyGroup);
        setShippingIdState(editData.shippingGroup);
        // setBlindingIdState(editData.blindingGroupId);
        // setPackageIdState(editData.packageId);
        setSelectedDays(editData.allowedDurationBelowMin?.days);
        setSelectedHours(editData.allowedDurationBelowMin?.hours);
        setSelectedMinutes(editData.allowedDurationBelowMin?.minutes);
        setSelectedDaysTwo(editData.allowedDurationAboveMax?.days);
        setSelectedHoursTwo(editData.allowedDurationAboveMax?.hours);
        setSelectedMinutesTwo(editData.allowedDurationAboveMax?.minutes);
      }
    } catch (err) {
      console.log("Error: ", err);
    } finally {
      setLoad(false);
    }
  };

  const classes = useStyles();

  const notify = () =>
    toast.success("Drug Type Edited Successfully", {
      // theme: "colored",
      toastId: "form-creation",
    });

  const isRequired = (data) =>
    toast.warn(`${data} Are Required`, {
      // theme: "colored",
      toastId: "is-required",
    });

  const requestFailed = (msg) =>
    toast.error(`${msg}`, {
      // theme: "colored",
      toastId: "requestFailed",
    });

  const onSubmit = async (data) => {
    try {
      setSubmitting(true);
      setBlockedNavigation(false);
      setLoad(true);
      setShowDialog(false);

      // if (!selectedDays || !selectedDaysTwo) {
      //   isRequired("Days");
      //   return;
      // } else if (!selectedHours || !selectedHoursTwo) {
      //   isRequired("Hours");
      //   return;
      // } else if (!selectedMinutes || !selectedMinutesTwo) {
      //   isRequired("Minutes");
      //   return;
      // }

      const newData = {
        ...data,
        packageId: packageIdState,
        allowedDurationBelowMin: {
          days: selectedDays || 0,
          hours: selectedHours || 0,
          minutes: selectedMinutes || 0,
        },
        allowedDurationAboveMax: {
          days: selectedDaysTwo || 0,
          hours: selectedHoursTwo || 0,
          minutes: selectedMinutesTwo || 0,
        },
        notes: "Test Notes",
      };

      const res = await updateDrugType(id, newData);
      if (res.status) {
        setLoad(false);
        notify();
        navigate("/drug-types");
      }
    } catch (err) {
      setLoad(false);
      setShowDialog(false);
      console.log("Error: ", err.message);
      requestFailed(err.message);
    } finally {
      setLoad(false);
      setSubmitting(false);
    }
  };

  const handleConfirmNavigation = () => {
    setBlockedNavigation(false);
    setOpenConfirmDialog(false);
    if (pendingNavigation) {
      pendingNavigation();
      setPendingNavigation(null);
    }
  };

  const handleDialogClose = () => {
    setOpenConfirmDialog(false);
    setBlockedNavigation(true);
    setPendingNavigation(null);
  };

  // const handleCancel = (e) => {
  //   e.preventDefault();
  //   navigate("/drug-types");
  // };

  const loadContainerStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70vh",
  };

  const rowStyles = {
    marginTop: "2%",
    marginBottom: "2%",
  };

  const labelStyles = {
    display: "flex",
    alignItems: "center",
    height: "30px",
  };

  const textBoxBody = {
    // marginLeft: "2px",
  };

  const textBoxStyles = {
    fontSize: 15,
    width: "100% !important",
    height: "5px",
  };

  return (
    <>
      {load ? (
        <>
          <div style={loadContainerStyles}>
            <BeatLoader color="#3661eb" />
          </div>
        </>
      ) : (
        <div className="content-body">
          <p className="study-management-link" style={{ fontWeight: "600" }}>
            <Link to="/study-management">Manage Your Study</Link> |{" "}
            <Link to="/drug-types">Drug Types</Link> |{" "}
            <p className="study-edit-link">Edit</p>
          </p>
          <Row style={rowStyles}>
            <Col md={6}>
              <div className="study-management-head-start">
                <p className="study-management-heading">Edit Drug Type</p>
              </div>
            </Col>
            <Col md={6}></Col>
          </Row>

          {/* <div>
            <DialogBox
              showDialog={showPrompt}
              confirmNavigation={confirmNavigation}
              cancelNavigation={cancelNavigation}
            />
          </div> */}

          <Box
            component="form"
            // onSubmit={handleSubmit(onSubmit)}
            onSubmit={(e) => {
              e.preventDefault();
              if (isDirty) {
                setShowDialog(true);
              } else {
                toast.error("No changes made to save.", {
                  toastId: "no-changes",
                });
              }
            }}
            sx={{ height: "auto", width: "100%" }}
            autoComplete="off"
          >
            <Row style={rowStyles}>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Name
                  <span className="error-highlight">*</span>
                </p>
              </Col>
              <Col md={4}>
                <TextField
                  style={textBoxBody}
                  // value={name}
                  inputProps={{
                    style: textBoxStyles,
                  }}
                  {...register("name")}
                />
                {errors.name && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.name.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>

            <Row style={rowStyles}>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Code
                  <span className="error-highlight">*</span>
                </p>
              </Col>
              <Col md={4}>
                <TextField
                  style={textBoxBody}
                  // value={code}
                  inputProps={{
                    style: textBoxStyles,
                  }}
                  {...register("code")}
                />
                {errors.code && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.code.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>

            <Row style={rowStyles}>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Is Bulk
                  <span className="error-highlight">*</span>
                </p>
              </Col>
              <Col md={4}>
                <div className={classes.customCheckboxWrapper}>
                  <Checkbox
                    checked={isBulk}
                    className={classes.customCheckbox}
                    inputProps={{ "aria-label": "controlled" }}
                    {...register("isBulk", {
                      onChange: (e) => {
                        setIsBulk(!isBulk);
                        // setShowDialog(true);
                      },
                    })}
                  />
                </div>
                {errors.isBulk && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.isBulk.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>

            {/* <Row style={rowStyles}>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Re Supply Group
                  <span className="error-highlight">*</span>
                </p>
              </Col>
              <Col md={4}>
                <TextField
                  // value={reSupplyGroup}
                  inputProps={{
                    style: textBoxStyles,
                  }}
                  {...register("resupplyGroup")}
                />
                {errors.code && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.code.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row> */}

            {/* <Row style={rowStyles}>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Shipping Group
                  <span className="error-highlight">*</span>
                </p>
              </Col>
              <Col md={4}>
                <TextField
                  // value={shippingIdState}
                  inputProps={{
                    style: textBoxStyles,
                  }}
                  {...register("shippingGroup")}
                />
                {errors.code && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.code.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row> */}

            <Row style={rowStyles}>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Blinding Group
                  <span className="error-highlight">*</span>
                </p>
              </Col>
              <Col md={4}>
                <Select
                  className={classes.customSelect}
                  name="blindingGroupId"
                  value={blindingIdState}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  {...register("blindingGroupId", {
                    onChange: (e) => {
                      setBlindingIdState(e.target.value);
                      // setShowDialog(true);
                    },
                  })}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {blindingData?.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.id}
                    </MenuItem>
                  ))}
                </Select>
                {errors.blindingGroupId && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.blindingGroupId.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>

            <Row style={rowStyles}>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Max Returnable Pill Count
                  <span className="error-highlight">*</span>
                </p>
              </Col>
              <Col md={4}>
                <TextField
                  className={classes.input}
                  // value={maxReturnablePillCount}
                  type="number"
                  style={textBoxBody}
                  inputProps={{
                    style: textBoxStyles,
                  }}
                  {...register("maxReturnablePillCount")}
                />
                {errors.maxReturnablePillCount && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.maxReturnablePillCount.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>

            <Row style={rowStyles}>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Package
                  <span className="error-highlight">*</span>
                </p>
              </Col>
              <Col md={4}>
                <Select
                  className={classes.customSelect}
                  name="packageId"
                  value={packageIdState}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  {...register("packageId", {
                    onChange: (e) => {
                      setPackageIdState(e.target.value);
                      // setShowDialog(true);
                    },
                  })}
                  // onChange={(e) => {
                  //   const selectedPackage = packageData.find(item => item.id === e.target.value);
                  //   if (selectedPackage) {
                  //     setPackageIdState(selectedPackage.id);  // Set the package ID in the state
                  //     setValue("packageId", selectedPackage.id);  // Set the package ID in the form
                  //   }
                  // }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {packageData?.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.packageId && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.packageId.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>

            <Row style={rowStyles}>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Trigger Temperature Min
                  <span className="error-highlight">*</span>
                </p>
              </Col>
              <Col md={4}>
                <TextField
                  className={classes.input}
                  type="number"
                  // value={triggerTempMin}
                  style={textBoxBody}
                  inputProps={{
                    style: textBoxStyles,
                  }}
                  InputProps={{
                    inputProps: {
                      style: {
                        // Hide the default increment and decrement arrows
                        "-webkit-appearance": "none",
                        "-moz-appearance": "textfield",
                        appearance: "none",
                      },
                    },
                  }}
                  {...register("triggerTemperatureMin")}
                />
                {errors.triggerTemperatureMin && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.triggerTemperatureMin.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>

            <Row style={rowStyles}>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Trigger Temperature Max
                  <span className="error-highlight">*</span>
                </p>
              </Col>
              <Col md={4}>
                <TextField
                  className={classes.input}
                  type="number"
                  // value={triggerTempMax}
                  style={textBoxBody}
                  inputProps={{
                    style: textBoxStyles,
                  }}
                  InputProps={{
                    inputProps: {
                      style: {
                        // Hide the default increment and decrement arrows
                        "-webkit-appearance": "none",
                        "-moz-appearance": "textfield",
                        appearance: "none",
                      },
                    },
                  }}
                  {...register("triggerTemperatureMax")}
                />
                {errors.triggerTemperatureMax && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.triggerTemperatureMax.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>

            <Row style={rowStyles}>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Absolute Temperature Min
                  <span className="error-highlight">*</span>
                </p>
              </Col>
              <Col md={4}>
                <TextField
                  className={classes.input}
                  type="number"
                  // value={absoluteTempMin}
                  style={textBoxBody}
                  inputProps={{
                    style: textBoxStyles,
                  }}
                  InputProps={{
                    inputProps: {
                      style: {
                        // Hide the default increment and decrement arrows
                        "-webkit-appearance": "none",
                        "-moz-appearance": "textfield",
                        appearance: "none",
                      },
                    },
                  }}
                  {...register("absoluteTemperatureMin")}
                />
                {errors.absoluteTemperatureMin && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.absoluteTemperatureMin.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>

            <Row style={rowStyles}>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Absolute Temperature Max
                  <span className="error-highlight">*</span>
                </p>
              </Col>
              <Col md={4}>
                <TextField
                  className={classes.input}
                  type="number"
                  // value={absoluteTempMax}
                  style={textBoxBody}
                  inputProps={{
                    style: textBoxStyles,
                  }}
                  InputProps={{
                    inputProps: {
                      style: {
                        // Hide the default increment and decrement arrows
                        "-webkit-appearance": "none",
                        "-moz-appearance": "textfield",
                        appearance: "none",
                      },
                    },
                  }}
                  {...register("absoluteTemperatureMax")}
                />
                {errors.absoluteTemperatureMax && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.absoluteTemperatureMax.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>

            <Row style={rowStyles}>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Allow Duration Below Min
                  <span className="error-highlight">*</span>
                </p>
              </Col>
              <Col md={4}>
                <TimeSelector
                  selectedDays={selectedDays}
                  setSelectedDays={setSelectedDays}
                  selectedHours={selectedHours}
                  setSelectedHours={setSelectedHours}
                  selectedMinutes={selectedMinutes}
                  setSelectedMinutes={setSelectedMinutes}
                  handleDaysChange={handleDaysChange}
                  handleHoursChange={handleHoursChange}
                  handleMinutesChange={handleMinutesChange}
                />
                {/* {errors.allowedDurationBelowMin && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.allowedDurationBelowMin.message}
                        </span>
                      </div>
                    </div>
                  </>
                )} */}
              </Col>
            </Row>

            <Row style={rowStyles}>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Allow Duration Above Max
                  <span className="error-highlight">*</span>
                </p>
              </Col>
              <Col md={4}>
                <TimeSelector
                  selectedDays={selectedDaysTwo}
                  setSelectedDays={setSelectedDaysTwo}
                  selectedHours={selectedHoursTwo}
                  setSelectedHours={setSelectedHoursTwo}
                  selectedMinutes={selectedMinutesTwo}
                  setSelectedMinutes={setSelectedMinutesTwo}
                  handleDaysChange={handleDaysChangeTwo}
                  handleHoursChange={handleHoursChangeTwo}
                  handleMinutesChange={handleMinutesChangeTwo}
                />
                {/* {errors.allowedDurationAboveMax && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.allowedDurationAboveMax.message}
                        </span>
                      </div>
                    </div>
                  </>
                )} */}
              </Col>
            </Row>

            <Row style={rowStyles}>
              <Col md={8}></Col>
              <Col md={2}>
                <div className="study-management-head-end">
                  <button
                    onClick={handleCancel}
                    type="button"
                    className="study-management-cancel-btn"
                  >
                    Cancel
                  </button>
                </div>
              </Col>
              <Col md={2}>
                <div className="study-management-head-end">
                  <button
                    type="submit"
                    className="study-management-create-btn-md"
                  >
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </Box>

          <Dialog
            open={showDialog}
            onClose={() => setShowDialog(false)}
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-description"
          >
            <DialogTitle
              id="confirm-dialog-title"
              className="!text-2xl !font-semibold"
            >
              Confirm Submission
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="confirm-dialog-description"
                className="!text-xl"
              >
                Are you sure you want to submit the changes?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setShowDialog(false)}
                color="primary"
                className="!text-xl"
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit(onSubmit)}
                color="primary"
                autoFocus
                className="!text-xl"
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openConfirmDialog}
            onClose={handleDialogClose}
            aria-labelledby="confirm-leave-dialog-title"
            aria-describedby="confirm-leave-dialog-description"
          >
            <DialogTitle
              id="confirm-leave-dialog-title"
              className="!text-2xl !font-semibold"
            >
              Unsaved Changes
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="confirm-leave-dialog-description"
                className="!text-xl"
              >
                You have unsaved changes. Are you sure you want to leave this
                page?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleDialogClose}
                color="primary"
                className="!text-xl"
              >
                Stay
              </Button>
              <Button
                onClick={handleConfirmNavigation}
                color="primary"
                autoFocus
                className="!text-xl"
              >
                Leave
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default EditDrugTypes;
