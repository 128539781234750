import React, { useContext, useEffect, useState } from "react";
import SponsorContext from "../../../context/sponsor/SponsorContext";
import { Link, useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { FaPencilAlt, FaEye, FaTrash } from "react-icons/fa";
import {
  createTheme,
  ThemeProvider,
  alpha,
  styled,
} from "@mui/material/styles";
import {
  DataGridPro,
  gridClasses,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  Box,
  Chip,
  Button,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Paper,
  InputBase,
  Portal,
} from "@mui/material";
// SearchSharp
import "./Sponsors.css";
import { CustomPagination } from "../../../components/CustomPagination";
import {
  getAllExportSponsors,
  getDownloadReport,
  postExportExcel,
  postExportPDF,
} from "../../../services/sponsors";
import { SearchSharp } from "@mui/icons-material";
import { mapSponsorData } from "../../../utils/dataMapping";
import moment from "moment";

const getMuiTheme = createTheme({
  typography: {
    fontSize: 25,
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        cell: {
          "&:focus": {
            outline: "none",
          },
        },
      },
    },
  },
});

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.background.default,
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    ffontWeight: "bold",
    fontSize: 18,
    color: "#000",
    backgroundColor: theme.palette.grey[300],
  },
}));

const AllSponsors = () => {
  const { sponsorsData, fetchSponsors, totalCount } =
    useContext(SponsorContext);

  const [sortingField, setSortingField] = useState(null);
  const [sortingType, setSortingType] = useState(null);
  const [searchString, setSearchString] = useState("");
  const [debouncedSearchString, setDebouncedSearchString] = useState("");
  const [filters, setFilters] = useState([]);
  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sponsorAllData, setSponsorAllData] = useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
    sponsorName: true,
    isActive: true,
    dateCreatedAt: true,
    actions: true,
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchString(searchString);

      // Construct the filter payload based on the search query
      const searchColumns = ["sponsorName", "dateCreatedAt"]; // Add other columns if needed
      const newFilters = searchColumns.map((columnField, index) => ({
        Id: index + 1,
        columnField,
        operatorValue: "contains",
        value: searchString,
      }));

      setFilters(newFilters);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchString]);

  useEffect(() => {
    if (sponsorsData?.length > 0) {
      setLoad(false);
    }
  }, [sponsorsData]);

  // useEffect(() => {
  //   // Update filters based on debounced search string
  //   setFilters([
  //     { columnField: "Name", operatorValue: "contains", value: debouncedSearchString },
  //     // Add more filters as needed
  //   ]);
  // }, [debouncedSearchString]);
  useEffect(() => {
    fetchAllDataForExport();
  }, []);

  useEffect(() => {
    fetchSponsors(
      pageNumber,
      pageSize,
      sortingType,
      sortingField,
      debouncedSearchString,
      filters
    );
  }, [
    pageNumber,
    pageSize,
    debouncedSearchString,
    filters,
    sortingType,
    sortingField,
  ]);

  const handlePageChange = (event, newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPageNumber(1); // Reset to first page on page size change
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    // Add your delete logic here
    console.log("Sponsor deleted");
    setOpen(false);
  };


  const fetchAllDataForExport = async () => {
    setLoad(true);
    try {
      const res = await getAllExportSponsors();
      if (res.status === 200) {
        const mappedData = mapSponsorData(res.data);
        setSponsorAllData(mappedData);
        setLoad(false);
        return res.data;
      }
    } catch (err) {
      console.log("ERROR: ", err);
      setLoad(false);
    }
    return [];
  };

  const handleColumnVisibilityChange = (newModel) => {
    setColumnVisibilityModel(newModel);
  };
  const navigate = useNavigate();

  const handleFilterChange = (filterModel) => {
    const formattedFilters = filterModel.items.map((filter, index) => ({
      Id: index + 1,
      columnField: filter.columnField,
      operatorValue: filter.operatorValue,
      value: filter.value,
    }));
    setFilters(formattedFilters);
  };

  const handleSortModelChange = (sortModel) => {
    if (sortModel.length > 0) {
      const { field, sort } = sortModel[0];
      setSortingField(field);
      setSortingType(sort === "asc" ? "asc" : "desc");
    } else {
      setSortingField(null);
      setSortingType(null);
    }
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value;
    setSearchString(searchValue);

    // Construct the filter payload based on the search query
    const searchColumns = ["sponsorName","dateCreatedAt"]; // Add other columns if needed
    const newFilters = searchColumns.map((columnField, index) => ({
      Id: index + 1,
      columnField,
      operatorValue: "contains",
      value: searchValue,
    }));

    setFilters(newFilters);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 0,
      hide: columnVisibilityModel.id,
      filterable: false,
      sortable: true,
    },
    {
      field: "sponsorName",
      headerName: "Name",
      flex: 1,
      minWidth: 220,
      filterable: true,
      hide: columnVisibilityModel.name,
      sortable: true,
    },
    {
      field: "isActive",
      headerName: "Active",
      flex: 1,
      minWidth: 220,
      sortable: true,
      filterable: false,
      hide: columnVisibilityModel.isActive,
      renderCell: (params) => (
        <Chip
          label={params.row.isActive === "Active" ? "Yes" : "No"}
          color={params.row.isActive === "Active" ? "success" : "error"}
          size="small"
        />
      ),
    },
    {
      field: "dateCreatedAt",
      headerName: "Date Added",
      flex: 1,
      minWidth: 220,
      sortable: true,
      filterable: false,
      hide: columnVisibilityModel.dateCreatedAt,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 220,
      sortable: true,
      filterable: false,
      hide: columnVisibilityModel.actions,
      renderCell: (params) => {
        const { id, name, imageUrl, isActive } = params.row;
        return (
          <div>
            <button
              className="bg-green-500"
              style={{
                cursor: "pointer",
                textAlign: "center",
                padding: "8px",
                border: "none",
                borderRadius: "5px",
              }}
              onClick={() =>
                navigate("/view-sponsor", {
                  state: {
                    id,
                    name,
                    fileUrl: imageUrl,
                    isactive: isActive === "Active",
                  },
                })
              }
            >
              <FaEye color="white" size={15} />
            </button>
            <button
              style={{
                cursor: "pointer",
                textAlign: "center",
                backgroundColor: "#337ab7",
                padding: "8px",
                border: "none",
                borderRadius: "5px",
                marginLeft: "10px",
              }}
              onClick={() =>
                navigate(`/edit-sponsor/`, {
                  state: {
                    id,
                    name,
                    fileUrl: imageUrl,
                    isactive: isActive === "Active",
                  },
                })
              }
            >
              <FaPencilAlt color="white" size={15} />
            </button>
            {/* <button className="bg-red-500" style={{ cursor: "pointer", textAlign: "center", padding: "8px", border: "none", borderRadius: "5px", marginLeft: "10px" }}>
              <FaTrash color="white" size={15} onClick={handleClickOpen} />
            </button> */}
          </div>
        );
      },
    },
  ];
  const GridExportToPDF = (props) => {
    return (
      <MenuItem
        onClick={async () => {
          try {
            const res = await postExportPDF(props.rows);
            if (res.status === 200) {
              const res2 = await getDownloadReport(res.data);
              if (res2.status === 200) {
                const blob = new Blob([res2.data], { type: "application/pdf" });
                saveAs(blob, "Sponsors.pdf");
              }
            }
            props.hideMenu?.();
          } catch (err) {
            console.log("ERROR: ", err);
          }
        }}
      >
        Export PDF
      </MenuItem>
    );
  };

  const GridExportToExcel = (props) => {
    return (
      <MenuItem
        onClick={async () => {
          try {
            const res = await postExportExcel(props.rows);
            if (res.status === 200) {
              const res2 = await getDownloadReport(res.data);
              if (res2.status === 200) {
                const blob = new Blob([res2.data], {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                saveAs(blob, "Sponsors.xlsx");
              }
            }
            props.hideMenu?.();
          } catch (err) {
            console.log("ERROR: ", err);
          }
        }}
      >
        Export Excel
      </MenuItem>
    );
  };

  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridExportToExcel rows={sponsorAllData} />
      <GridExportToPDF rows={sponsorAllData} />
    </GridToolbarExportContainer>
  );

  // const CustomToolbar = (props) => (
  //   <GridToolbarContainer {...props}>
  //     <GridToolbarColumnsButton />
  //     <GridToolbarFilterButton />
  //     <GridToolbarDensitySelector />
  //     <CustomExportButton />
  //   </GridToolbarContainer>
  // );
  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <CustomExportButton />
      {/* <Portal container={() => document.getElementById("filter-panel")}>
        <GridToolbarQuickFilter />
      </Portal> */}
    </GridToolbarContainer>
  );

  return (
    <ThemeProvider theme={getMuiTheme}>
      <div className="content-body">
        <p className="admin-link" style={{ fontWeight: "600" }}>
          <Link to="/homepage">Home</Link> {" > "}{" "}
          <Link to="/all-sponsors">All Sponsors</Link>
        </p>
        <Row>
          <Col md={6}>
            <p className="sponsor-heading">All Sponsors</p>
          </Col>
          <Col sm={4}>
            <Paper
              component="form"
              sx={{
                p: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <SearchSharp />
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search Sponsors"
                inputProps={{ "aria-label": "search sponsors" }}
                // onChange={(e) => setSearchString(e.target.value)}
                onChange={handleSearch}
              />
            </Paper>
          </Col>
          <Col md={2}>
            <div className="userButtonContainer">
              <Link to="/create-sponsor">
                <button
                  className="addUserButton font-extrabold"
                  style={{ borderRadius: "5px" }}
                >
                  + Add Sponsor
                </button>
              </Link>
            </div>
          </Col>
        </Row>
        <Box
          sx={{
            height: 669,
            width: "100%",
            "& .MuiDataGrid-columnHeaders": {
              minHeight: "58px !important",
            },
          }}
        >
          <ThemeProvider theme={getMuiTheme}>
            {load ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "70vh",
                }}
              >
                <BeatLoader color="#3661eb" />
              </div>
            ) : sponsorsData.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "70vh",
                  fontSize: "24px",
                }}
              >
                No Results Found
              </div>
            ) : (
              <StripedDataGrid
                rows={sponsorsData}
                columns={columns}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={handleColumnVisibilityChange}
                disableSelectionOnClick={true}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
                components={{
                  Toolbar: CustomToolbar,
                }}
                filterMode="server"
                onFilterModelChange={handleFilterChange}
                sortingMode="server"
                sortModel={sortingField && sortingType ? [{ field: sortingField, sort: sortingType }] : []} 
                onSortModelChange={handleSortModelChange}
              />
            )}
            <CustomPagination
              currentPage={pageNumber}
              totalCount={totalCount}
              pageSize={pageSize}
              size="large"
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
          </ThemeProvider>
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default AllSponsors;
