import React, { useContext } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {
  BrowserRouter,
  createBrowserRouter,
  unstable_HistoryRouter as Router,
  RouterProvider,
} from "react-router-dom";
import history from "./hooks/history";
import App from "./App";
import { QueryClient, QueryClientProvider } from "react-query";
const queryClient = new QueryClient();
import { AuthProvider } from "./context/auth/AuthContext";
import { SponsorProvider } from "./context/sponsor/SponsorContext";
import { StudyProvider } from "./context/study/StudyContext";
import { UserProvider } from "./context/user/UserContext";
import { SystemSettingProvider } from "./context/systemSettings/SystemSettings";
import { LicenseInfo } from "@mui/x-license-pro";
import { SystemSettingsProvider } from "./context/systemSetting/SystemSettingsContext";
import { StudyManagementProvider } from "./context/studyManagement/StudyManagementContext";

LicenseInfo.setLicenseKey(
  "e82cb24297efeaa3f4849e0aa6cac863Tz05MDcwNyxFPTE3NDc0ODY2NzgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);
const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter([
  {
    path: "/*", // adjust the path as necessary
    element: (
      <AuthProvider>
        <App />
      </AuthProvider>
    ),
  },
]);

root.render(
  // <AuthProvider authConfig={authConfig}>
  <QueryClientProvider client={queryClient}>
    {/* <React.StrictMode> */}
    <SponsorProvider>
      <StudyProvider>
        <UserProvider>
          <SystemSettingsProvider>
              <StudyManagementProvider>
                {/* <BrowserRouter> */}
                {/* <Router history={history}>
                  <AuthProvider>
                    <App />
                  </AuthProvider>
                  </Router> */}
                {/* </BrowserRouter> */}
                <RouterProvider router={router} />

              </StudyManagementProvider>
          </SystemSettingsProvider>
        </UserProvider>
      </StudyProvider>
    </SponsorProvider>

    {/* </React.StrictMode> */}
  </QueryClientProvider>
  // </AuthProvider>
);