import { useState, createContext, useReducer } from "react";
import userReducer from "./UserReducer";

import { userApiInstance } from "../../services";
import { getAllUsers } from "../../services/users";
// const access_token = localStorage.getItem('accessToken');

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const initialState = {
    usersData: [],
    load: false,
    totalCount: 0
  };

  const [state, dispatch] = useReducer(userReducer, initialState);

  const [value, setValue] = useState(0);

  const fetchUsers = async (pageNumber, pageSize, sortingType, sortingField, debouncedSearchString, filters) => {
    setLoading();
    try {
      const res = await getAllUsers(pageNumber, pageSize, sortingType, sortingField, debouncedSearchString, filters);

      dispatch({
        type: "GET_USERS",
        payload: res?.result,
      });
      dispatch({
        type: "SET_TOTAL_COUNT",
        payload: res?.totalCount
      })
    } catch (err) {
      disableLoading();
      console.log("Error: ", err.message);
    }
  };
  // Set Loading
  const setLoading = () => dispatch({ type: "SET_LOADING" });

  // Disable Loading
  const disableLoading = () => dispatch({ type: "DISABLE_LOADING" });

  // useEffect(() => {
  //   if (access_token) {
  //     fetchUsers();
  //   }
  // }, [access_token]);

  return (
    <UserContext.Provider
      value={{
        usersData: state.usersData,
        load: state.load,
        setLoading,
        disableLoading,
        fetchUsers,
        value,
        setValue,
        totalCount: state.totalCount
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
