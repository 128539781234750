import React, { useCallback, useEffect, useState } from "react";
import { Link, useBlocker, useNavigate } from "react-router-dom";
import {
  FaEdit,
  FaTrashAlt,
  FaCheck,
  FaTimes,
  FaPencilAlt,
} from "react-icons/fa";
import BeatLoader from "react-spinners/BeatLoader";
import { Box, Chip, MenuItem } from "@mui/material";
import { toast } from "react-toastify";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  createTheme,
  ThemeProvider,
  alpha,
  styled,
} from "@mui/material/styles";
import {
  getAllDoseLevels,
  deleteDoseLevels,
  getAllExportDose,
} from "../../../../services/dose_levels";
import {
  DataGridPro,
  gridClasses,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";
import "../StudySetup.css";
import { useDoseManagement } from "../../../../context/studyManagement/DoseManagement/DoseManagementContext";
import useDoseManagementDetails from "../../../../hooks/Api/useDoseManagement";
import { CustomPagination } from "../../../../components/CustomPagination";
import {
  getDownloadReport,
  postExportExcel,
  postExportPDF,
} from "../../../../services/sponsors";
import { mapDoseDataPdf, mapDrugDataPdf } from "../../../../utils/dataMapping";
import {
  LoadingOverlay,
  NoRowsOverlay,
} from "../../../../components/CutomOverlays";
import DeleteModal from "../../../../components/DeleteModal";

const getMuiTheme = createTheme({
  typography: {
    fontSize: 25,
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        cell: {
          "&:focus": {
            outline: "none",
          },
        },
      },
    },
  },
});

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.background.default,
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    ffontWeight: "bold",
    fontSize: 18,
    color: "#000",
    backgroundColor: theme.palette.grey[300],
  },
}));

function debounce(fn, delay) {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}

const DoseLevels = () => {
  const navigate = useNavigate();
  const [load, setLoad] = React.useState(false);
  const [doseAllData, setDoseAllData] = useState([]);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const {
    state,
    setPageNumber,
    setPageSize,
    setSorting,
    setFilters,
    dispatch,
  } = useDoseManagement();
  const {
    doseData,
    pageNumber,
    pageSize,
    sortingType,
    sortingField,
    filters,
    totalCount,
  } = state;
  const { doseQuery, deleteDrugMutation } = useDoseManagementDetails();
  const { isLoading, data, refetch } = doseQuery;
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
    name: true,
    isRecommended: true,
    isEnabled: true,
    edit: true,
    delete: true,
  });

  const notifyDelete = () =>
    toast.success("Dose Level Deleted Successfully", {
      toastId: "form-creation",
    });

  const requestFailed = (msg) =>
    toast.error(`${msg}`, {
      toastId: "requestFailed",
    });

  const rows = data?.data?.map((row) => ({
    id: row.id,
    name: row.name,
    isRecommended: row.isRecommended,
    isEnabled: row.isEnabled,
    // canDispense: row.canDispense,
    edit: row.id,
    delete: row.id,
  }));

  const columns = [
    {
      field: "id",
      hide: true,
      headerName: "ID",
      width: 0,
      hide: columnVisibilityModel.id,
      filterable: false,
      sortable: false,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 220,
      hide: columnVisibilityModel.name,
      filterable: true,
      sortable: true,
    },
    {
      field: "isRecommended",
      headerName: "Is Recommended",
      flex: 1,
      minWidth: 220,
      // hide: columnVisibilityModel.isRecommended,
      filterable: false,
      sortable: true,
      renderCell: (params) => (
        <Chip
          label={params.row.isRecommended ? "Yes" : "No"}
          color={params.row.isRecommended ? "success" : "error"}
          size="small"
        />
      ),
    },
    {
      field: "isEnabled",
      headerName: "Is Enabled",
      flex: 1,
      minWidth: 220,
      hide: columnVisibilityModel.isEnabled,
      filterable: false,
      sortable: true,
      renderCell: (params) => (
        <Chip
          label={params.row.isEnabled ? "Yes" : "No"}
          color={params.row.isEnabled ? "success" : "error"}
          size="small"
        />
      ),
    },
    {
      field: "edit",
      headerName: "Edit",
      flex: 1,
      hide: columnVisibilityModel.edit,
      filterable: false,
      sortable: false,
      minWidth: 220,
      renderCell: (params) => {
        const id = params.row.id;

        return (
          <button
            style={{
              cursor: "pointer",
              textAlign: "center",
              backgroundColor: "#337ab7",
              padding: "8px",
              border: "none",
              borderRadius: "5px",
            }}
            onClick={async () => {
              navigate(`/edit-dose`, {
                state: {
                  id: id,
                },
              });
            }}
          >
            <FaPencilAlt color="white" size={15} />
          </button>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      flex: 1,
      hide: columnVisibilityModel.delete,
      filterable: false,
      sortable: false,
      minWidth: 220,
      renderCell: (params) => {
        const id = params.row.id;

        return (
          <button
            onClick={() => {
              setSelectedId(id);
              setShowConfirmModal(true);
            }}
            style={{
              cursor: "pointer",
              textAlign: "center",
              background: "red",
              border: "none",
              borderRadius: "5px",
              padding: "10px 10px",
            }}
          >
            <FaTrashAlt color="white" style={{ fontSize: "15px" }} />
          </button>
        );
      },
    },
  ];

  const handleDeleteConfirm = async () => {
    try {
      setLoad(true);
      await deleteDrugMutation.mutateAsync(selectedId);
      setLoad(false);
      refetch();
      notifyDelete();
    } catch (err) {
      console.log("Error: ", err.message);
      setLoad(false);
      requestFailed(err.message);
    } finally {
      setShowConfirmModal(false);
    }
  };

  const handleSortModelChange = useCallback(
    (sortModel) => {
      if (sortModel?.length > 0) {
        const { field, sort } = sortModel[0];
        setSorting(sort, field);
      } else {
        setSorting(null);
      }
    },
    [setSorting]
  );

  const handleFilterChange = useCallback(
    (filterModel) => {
      const updatedFilters = filterModel.items.map((filter) => ({
        columnField: filter.columnField,
        operatorValue: filter.operatorValue,
        value: filter.value,
      }));

      setFilters(updatedFilters);
      setPageNumber(1);
    },
    [setFilters, setPageNumber]
  );

  const handleColumnVisibilityChange = useCallback(
    (newModel) => {
      setColumnVisibilityModel(newModel);
    },
    [setColumnVisibilityModel]
  );

  // const handleClearFilters = () => {
  //   setFilters([]);
  //   setPageNumber(1);
  //   doseQuery.refetch();
  // };

  const fetchAllDataForExport = async () => {
    setLoad(true);
    try {
      const res = await getAllExportDose();
      if (res.status === 200) {
        const mappedData = mapDoseDataPdf(res.data);
        setDoseAllData(mappedData);
        setLoad(false);
        return res.data;
      }
    } catch (err) {
      console.log("ERROR: ", err);
      setLoad(false);
    }
    return [];
  };

  useEffect(() => {
    fetchAllDataForExport();
  }, []);

  const GridExportToPDF = (props) => {
    return (
      <MenuItem
        onClick={async () => {
          try {
            const res = await postExportPDF(props.rows);
            if (res.status === 200) {
              const res2 = await getDownloadReport(res.data);
              if (res2.status === 200) {
                const blob = new Blob([res2.data], { type: "application/pdf" });
                saveAs(blob, "DoseLevel.pdf");
              }
            }
            props.hideMenu?.();
          } catch (err) {
            console.log("ERROR: ", err);
          }
        }}
      >
        Export PDF
      </MenuItem>
    );
  };

  const GridExportToExcel = (props) => {
    return (
      <MenuItem
        onClick={async () => {
          try {
            const res = await postExportExcel(props.rows);
            if (res.status === 200) {
              const res2 = await getDownloadReport(res.data);
              if (res2.status === 200) {
                const blob = new Blob([res2.data], {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                saveAs(blob, "DoseLevel.xlsx");
              }
            }
            props.hideMenu?.();
          } catch (err) {
            console.log("ERROR: ", err);
          }
        }}
      >
        Export Excel
      </MenuItem>
    );
  };

  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridExportToExcel rows={doseAllData} />
      <GridExportToPDF rows={doseAllData} />
    </GridToolbarExportContainer>
  );

  const GridToolbar = React.memo((props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <CustomExportButton />
    </GridToolbarContainer>
  ));

  return (
    <ThemeProvider theme={getMuiTheme}>
      <div className="content-body">
        <p className="study-management-link" style={{ fontWeight: "600" }}>
          <Link to="/study-management">Manage Your Study</Link> |{" "}
          <Link to="/dose-levels">Dosage Level</Link>
        </p>
        <Row>
          <Col md={6}>
            <div className="study-management-head-start">
              <p className="study-management-heading">Dosage Level</p>
            </div>
          </Col>
          <Col md={6}>
            <Link to="/create-dose">
              <div className="study-management-head-end">
                <button className="study-management-create-btn-md">
                  Add New
                </button>
              </div>
            </Link>
          </Col>
        </Row>
        <Box
          sx={{
            height: 669,
            width: "100%",
            "& .MuiDataGrid-columnHeaders": {
              minHeight: "58px !important",
            },
          }}
        >
          {" "}
          <ThemeProvider theme={getMuiTheme}>
            <StripedDataGrid
              className="allQuestionnaire-grid"
              rows={rows || []}
              columns={columns}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={handleColumnVisibilityChange}
              loading={isLoading}
              components={{
                Toolbar: GridToolbar,
                NoRowsOverlay: NoRowsOverlay,
                LoadingOverlay: LoadingOverlay,
              }}
              disableSelectionOnClick={true}
              experimentalFeatures={{ newEditingApi: true }}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
              filterMode="server"
              onFilterModelChange={handleFilterChange}
              sortingMode="server"
              sortModel={
                sortingField && sortingType
                  ? [{ field: sortingField, sort: sortingType }]
                  : []
              }
              onSortModelChange={handleSortModelChange}
            />
            <CustomPagination
              currentPage={pageNumber}
              totalCount={totalCount || 0}
              pageSize={pageSize}
              size="large"
              onPageChange={(event, page) =>
                dispatch({ type: "SET_PAGE_NUMBER", payload: page })
              }
              onPageSizeChange={(event) =>
                dispatch({
                  type: "SET_PAGE_SIZE",
                  payload: event.target.value,
                })
              }
            />
          </ThemeProvider>
          <Row style={{ marginTop: "3%" }}>
            <Col md={6}></Col>
            <Col md={6}>
              <div className="createSponsor-buttons">
                <Link to="/study-management">
                  <button className="backToDrugButton">Back</button>
                </Link>
              </div>
            </Col>
          </Row>
        </Box>
        {/* )} */}
      </div>
      {/* )} */}
      <DeleteModal
        open={showConfirmModal}
        handleClose={() => setShowConfirmModal(false)}
        handleDelete={handleDeleteConfirm}
        subject={"dose Level"}
      />
    </ThemeProvider>
  );
};

export default DoseLevels;
