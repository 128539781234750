import { studyServer } from "./study_axios";

export const getAllVisits = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get("/app/Visit/GetVisitList")
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const createNewVisit = (data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post("app/Visit/PostVisit", data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getVisitById = (visitId) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`app/Visit/EditVisit`, {
        params: {
          visitId: visitId,
        },
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const editVisit = (id, data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .put(`app/Visit/putVisit`, data, {
        params: {
          Id: id,
        },
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getAllBusinessRules = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get("app/PatientAttributeConfiguration/GetBusinessRules")
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getAllResonsService = (selectedValue = 0) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get("app/Visit/GetReasonsOfVisit", {
        params: selectedValue,
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getPatientStatusTypesService = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get("app/Visit/GetPatientStatusTypes")
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const deleteVisit = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post(`app/Visit/DeleteVisitConfirmed?visitId=${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getNavigationByIdService = (visitId) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get("app/Visit/GetVisitNavigationIndexOnload", {
        params: {
          visitId,
        },
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const addNavigationService = (data) => {
  const { visitId, patientStatusTypeId, childVisitId } = data;
  return new Promise((resolve, reject) => {
    studyServer
      .get("app/Visit/AddVisitNavigation", {
        params: {
          visitId: visitId,
          patientStatusTypeId: patientStatusTypeId,
          childVisitId: childVisitId,
        },
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const deleteNavigationService = (data) => {
  const { id, visitId } = data;
  return new Promise((resolve, reject) => {
    studyServer
      .get("app/Visit/RemoveVisitNavigation", {
        params: {
          visitId: visitId,
          id: id,
        },
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
