// src/utils/dataMapper.js

import moment from "moment/moment";

export const mapSponsorData = (data) => {

  return data.map((row) => ({
    id: row.id,
    sponsorName: row.sponsorName.trim(),
    dateCreatedAt: moment(row.dateCreatedAt).format("ll"),
    imageUrl: row.imageUrl,
    isActive: row.isActive ? "Active" : "Inactive",
  }));
};

export const mapUserData = (data) => {

  return data.map((row) => ({
    "First Name": row.firstName.trim(),
    "Last Name": row.lastName.trim(),
    Email: row.email,
    "Date Created At": moment(row.dateCreatedAt).format("ll"),
    "Phone Number": row.phoneNumber,
    "Role": row.role,
    isActive: row.isActive ? "Active" : "Inactive",
  }));
};

const flattenObject = (ob) => {
  let result = {};

  for (const i in ob) {
    if (typeof ob[i] === 'object' && !Array.isArray(ob[i])) {
      const temp = flattenObject(ob[i]);
      for (const j in temp) {
        result[i + '.' + j] = temp[j];
      }
    } else if (Array.isArray(ob[i])) {
      ob[i].forEach((item, index) => {
        if (typeof item === 'object') {
          const temp = flattenObject(item);
          for (const j in temp) {
            result[i + '.' + index + '.' + j] = temp[j];
          }
        } else {
          result[i + '.' + index] = item;
        }
      });
    } else {
      result[i] = ob[i];
    }
  }
  return result;
};

export const mapStudyData = (studyData) => {
  return studyData.map((row) => {
    const flattenedRow = flattenObject(row);
    return {
      id: row.id,
      ...flattenedRow,
    };
  });
};

export const mapExportData = (studyData) => {
  if (!Array.isArray(studyData)) {
    console.error("Invalid study data format or empty array.");
    return [];
  }

  return studyData.map((row) => {
    // Ensure row is an object
    if (typeof row !== "object" || row === null) {
      console.error("Invalid study data item:", row);
      return null;
    }
    return {
      Name: row.name || "",
      "Portal URL": row.portalUrl || "",
      "API URL": row.apiUrl || "",
      "Questionnaire URL": row.questionnaireBuilderUrl || "",
      "Sponsor Name": row.sponsorName || "",
      "Study Type": row.studyType || "",
      "Is Active": row.isActive ? "Active" : "Inactive",
      "Project Managers": row.projectManagers || "",
    };
  });
};


export const mapCountryData = (data) => {
  return data.map((row) => ({
    Name: row.name,
    "ISO Id": row.isoId,
    Region: row?.systemRegion?.name,
    Status: row?.isActive,
  }));
};


export const mapLanguageData = (data) => {
  return data.map((row) => ({
    "Culture Name": row.cultureName,
    Name: row.name,
    "Display Name": row.displayName,
    "Is Right To Left": row.isRightToLeft,
    Status: row?.isActive,
  }));
};

export const mapRolesData = (data) => {
  return data.map((row) => ({
    Name: row.shortName,
    Description: row.description,
    "Is Blinded": row.isBlinded,
    "Auto Assign New Sites": row?.autoAssignNewSites,
  }));
};

export const mapRegionData = (data) => {
  return data.map((row) => ({
    Name: row.name,
    Status: row?.isActive,
  }));
};


//drug type data 

export const mapDrugData = (data) => {
  return data.map((item) => ({
    id: item.id,
    name: item.name,
    code: item.code,
    isBulk: item.isBulk,
    isRecommended: item.isRecommended || false,
    isEnabled: item.isEnabled || false,
    MaxReturnablePillCount: item.maxReturnablePillCount,
    PackageId: item.packageId,
    TriggerTemparatureMin: item.triggerTemperatureMin,
    TriggerTemparatureMax: item.triggerTemperatureMax,
    AbsoluteTemparatureMin: item.absoluteTemperatureMin,
    AbsoluteTemparatureMax: item.absoluteTemperatureMax,
    AllowedDurationBelowMin: item.allowedDurationBelowMin,
    AllowedDurationAboveMax: item.allowedDurationAboveMax,
  }));
};

//drug columns 
export const drugColumns = [
  { field: "name", headerName: "Name" },
  { field: "code", headerName: "Code"},
  { field: "isBulk", headerName: "Is Bulk", type: "boolean" },
  { field: "maxReturnablePillCount", headerName: "Max Returnable Pill Count" },
  { field: "packageId", headerName: "Package ID" },
  { field: "triggerTemperatureMin", headerName: "Trigger Temperature Min" },
  { field: "triggerTemparatureMax", headerName: "Trigger Temperature Max" },
  { field: "absoluteTemperatureMin", headerName: "Absolute Temperature Min" },
  { field: "absoluteTemperatureMax", headerName: "Absolute Temperature Max" },
  { field: "allowedDurationBelowMin", headerName: "Allowed Duration Below Min" },
  { field: "allowedDurationAboveMax", headerName: "Allowed Duration Above Max" },
];


export const mapDrugDataPdf = (data) => {
  return data.result.map(item => ({
    absoluteTemperatureMax: item.absoluteTemperatureMax,
    absoluteTemperatureMin: item.absoluteTemperatureMin,
    allowedDurationAboveMax: item.allowedDurationAboveMax,
    allowedDurationBelowMin: item.allowedDurationBelowMin,
    blindingGroup: item.blindingGroup,
    blindingGroupId: item.blindingGroupId,
    code: item.code,
    dispensationDetails: item.dispensationDetails,
    id: item.id,
    isBulk: item.isBulk,
    lastUpdate: item.lastUpdate,
    maxReturnablePillCount: item.maxReturnablePillCount,
    name: item.name,
    notes: item.notes,
    package: item.package,
    packageId: item.packageId,
    resupplyGroup: item.resupplyGroup,
    shippingGroup: item.shippingGroup,
    triggerTemperatureMax: item.triggerTemperatureMax,
    triggerTemperatureMin: item.triggerTemperatureMin
  }));
}

export const mapDoseDataPdf = (data) => {
  return data.result.map(item => ({
    dispensationDetails: item.dispensationDetails,
    id: item.id,
    isEnabled: item.isEnabled,
    isRecommended: item.isRecommended,
    mgDose: item.mgDose,
    name: item.name
  }))
}

export const mapTreatmentDataPdf = (data) => {
  return data.result.map(item => ({
    description: item.description,
    dispensations: item.dispensations,
    id: item.id,
    lastUpdate: item.lastUpdate,
    notes: item.notes
  }))
}

//subject attribute 

export const mapSubjectAttributeData = (data) => {
  const { addCountries = [], datA_PAC = [] } = data.value || {};

  // Map countries
  const mappedCountries = addCountries.map((country) => ({
      id: country.id,
      name: country.name,
      shortName: country.shortName,
      lastUpdate: country.lastUpdate,
      patientDobFormat: country.patientDobformat,
      recordPatientDob: country.recordPatientDob,
      recordPatientGender: country.recordPatientGender,
      recordPatientInitials: country.recordPatientInitials,
      region: country.region,
      useSubjectCode: country.useSubjectCode,
      useTwentyFourHourTime: country.useTwentyFourHourTime,
      sites: country.sites,
      notes: country.notes,
  }));

  // Map PAC
  const mappedPAC = datA_PAC.map((pac) => ({
      id: pac.id,
      displayName: pac.displayName,
      countryId: pac.countryId,
      siteId: pac.siteId,
      country: pac.country,
      site: pac.site,
  }));
  const patientAttributeConfigurationId = data.value?.patientAttributeConfigurationId || null;

  const res = {
      status: data?.status,
      message: data?.message,
      patientAttributeConfigurationId,
      countries: mappedCountries,
      pac: mappedPAC,
      totalCount: addCountries.length, 
  };
  return res;
}


export const mapDataTypeDetails = (response) => {
  if (!response || response.status !== "Success" || !Array.isArray(response.result)) {
      console.error("Invalid response structure:", response);
      throw new Error("Invalid response structure");
  }

  // Map the response data to the desired format
  return response.result.map((item) => ({
      id: item.id,
      name: item.name,
      isDateTime: item.isDateTime,
      isNumeric: item.isNumeric,
      isMultipleChoice: item.isMultipleChoice,
      patientAttributeConfigurationDetails: item.patientAttributeConfigurationDetails || []
  }));
};

export const mapBusinessRuleDetails = (response) => {
  if (!response || response.status !== "Success" || !Array.isArray(response.result)) {
      console.error("Invalid response structure:", response);
      throw new Error("Invalid response structure");
  }

  // Map the response data to the desired format
  return response.result.map((item) => ({
      id: item.id,
      name: item.name,
      description: item.description,
      inclusive: item.inclusive,
      patientAttributeConfigurationDetails: item.patientAttributeConfigurationDetails || [],
      visits: item.visits || [],
  }));
};

