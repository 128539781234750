import { dataServer } from "./axios.config";

// export const getSystemRegion = (pageNumberRegions, pageSizeRegions, sortingField, sortingType, debouncedSearchStringRegions) => {
//   return new Promise((resolve, reject) => {
//     dataServer
//       .get(pageNumberRegions && pageSizeRegions ? `/app/SystemRegion?pageNumber=${pageNumberRegions}&pageSize=${pageSizeRegions}&sorting=%5B%7B"Field"%3A"${sortingField}"%2C"Order"%3A"${sortingType}"%7D%5D%20&filter=%7B"items"%3A%5B%7B"Id"%3A1%2C"columnField"%3A"name"%2C"operatorValue"%3A"contains"%2C"value"%3A"${debouncedSearchStringRegions}"%7D%5D%2C"linkOperator"%3A"And"%2C"quickFilterLogicOperator"%3A"And"%7D` : `/app/SystemRegion?pageNumber=1&pageSize=10000000`)
//       .then((res) => resolve(res))
//       .catch((err) => reject(err));
//   });
// };
export const getSystemRegion = (pageNumber, pageSize, sortingType, sortingField, debouncedSearchString, regionFilters) => {
  return new Promise((resolve, reject) => {
    const filterItems = (Array.isArray(regionFilters) ? regionFilters : []).map((filter, index) => ({
      Id: index + 1,
      columnField: filter.columnField,
      operatorValue: filter.operatorValue,
      value: filter.value,
    }));
    const filterPayload = encodeURIComponent(JSON.stringify({
      items: filterItems,
      linkOperator: "And",
      quickFilterLogicOperator: "And",
    }));

    const sortingPayload = encodeURIComponent(JSON.stringify([{
      Field: sortingField, 
      sort: sortingType  
    }]));
    
    const url = `/app/SystemRegion?pageNumber=${pageNumber}&pageSize=${pageSize}&sorting=${sortingPayload}&filter=${filterPayload}`;
    dataServer
      .get(url)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.error("API Error:", err);
        reject(err);
      });
  });
};

export const createSystemRegion = (data) => {
  return new Promise((resolve, reject) => {
    dataServer
      .post("app/SystemRegion", data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const editSystemRegion = (id, data) => {
  return new Promise((resolve, reject) => {
    dataServer
      .put(`app/SystemRegion/${id}`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getSystemRegionById = (id) => {
  return new Promise((resolve, reject) => {
    dataServer
      .get(`app/SystemRegion/${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
