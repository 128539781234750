import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { getStudyTypes } from "../../../services/sponsors";
import {
  editStudy,
  getStudyById,
  getAllPms,
  editStudyStatus,
} from "../../../services/studies";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  Tabs,
  Tab,
  Typography,
  Container,
  Paper,
  InputBase,
  List,
  ListItem,
  Dialog,
  DialogContent,
  DialogContentText,
  Checkbox,
  DialogActions,
  Button,
  Autocomplete,
  TextField,
} from "@mui/material";
import DialogBox from "../../../components/DialogBox";
import { useCallbackPrompt } from "../../../hooks/useCallbackPrompt";
import PropTypes from "prop-types";
import UserContext from "../../../context/user/UserContext";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { SearchSharp, SyncAlt } from "@mui/icons-material";
import "./Study.css";
import axios from "axios";
import { getSystemRoleById } from "../../../services/system_roles";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const tabStyles = {
  fontSize: "12px",
  fontWeight: "700",
};

const EditStudy = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  var id = params.id;
  const sponsorId = location?.state?.sponsorId;

  const { value, setValue } = useContext(UserContext);

  const [load, setLoad] = React.useState(false);
  const [compLoad, setCompLoad] = React.useState(false);
  const [sponsorList, setSponsorList] = React.useState([]);
  const [studyTypeList, setStudyTypeList] = React.useState([]);
  const [editStudyID, setEditStudyID] = React.useState(id);
  const [sponsorID, setSponsorID] = React.useState(sponsorId);
  const [selectedValue, setSelectedValue] = useState(null);
  const [StudyDbName, setStudyDbName] = useState("");
  const [StudyDbJobId, setStudyDbJobId] = useState("");
  const [studyName, setStudyName] = React.useState("");
  const [studyType, setStudyType] = React.useState("");
  const [studyTypeID, setStudyTypeID] = React.useState("");

  const [portUrl, setPortUrl] = React.useState("");
  const [apiUrl, setApiUrl] = React.useState("");
  const [questionaireUrl, setQuestionaireUrl] = React.useState("");
  const [projectMgrs, setProjectMgrs] = React.useState([]);
  const [alreadySelectedMgr, setAlreadySelectedMgr] = React.useState([]);
  const [managerSelected, setManagerSelected] = React.useState([]);
  const [isActive, setIsActive] = React.useState(false);

  const [showDialog, setShowDialog] = React.useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const [studyRoles, setStudyRoles] = React.useState([]);
  const [systemRoles, setSystemRoles] = React.useState([]);

  const [studyLanguages, setStudyLanguages] = React.useState([]);
  const [systemLanguages, setSystemLanguages] = React.useState([]);

  const [studyCountries, setStudyCountries] = React.useState([]);
  const [systemCountries, setSystemCountries] = React.useState([]);

  const [studyRegions, setStudyRegions] = React.useState([]);
  const [systemRegions, setSystemRegions] = React.useState([]);

  const [searchStringSystemRoles, setSearchStringSystemRoles] = useState("");
  const [
    debouncedSearchStringSystemRoles,
    setDebouncedSearchStringSystemRoles,
  ] = useState("");
  const [searchStringStudyRoles, setSearchStringStudyRoles] = useState("");
  const [debouncedSearchStringStudyRoles, setDebouncedSearchStringStudyRoles] =
    useState("");

  const [searchStringSystemLanguages, setSearchStringSystemLanguages] =
    useState("");
  const [
    debouncedSearchStringSystemLanguages,
    setDebouncedSearchStringSystemLanguages,
  ] = useState("");
  const [searchStringStudyLanguages, setSearchStringStudyLanguages] =
    useState("");
  const [
    debouncedSearchStringStudyLanguages,
    setDebouncedSearchStringStudyLanguages,
  ] = useState("");

  const [searchStringSystemCountries, setSearchStringSystemCountries] =
    useState("");
  const [
    debouncedSearchStringSystemCountries,
    setDebouncedSearchStringSystemCountries,
  ] = useState("");
  const [searchStringStudyCountries, setSearchStringStudyCountries] =
    useState("");
  const [
    debouncedSearchStringStudyCountries,
    setDebouncedSearchStringStudyCountries,
  ] = useState("");

  const [searchStringSystemRegions, setSearchStringSystemRegions] =
    useState("");
  const [
    debouncedSearchStringSystemRegions,
    setDebouncedSearchStringSystemRegions,
  ] = useState("");
  const [searchStringStudyRegions, setSearchStringStudyRegions] = useState("");
  const [
    debouncedSearchStringStudyRegions,
    setDebouncedSearchStringStudyRegions,
  ] = useState("");

  const [open, setOpen] = useState(false);

  const [studyIdRole, setStudyIdRole] = useState("");
  const [shortNameRole, setShortNameRole] = useState("");
  const [descriptionRole, setDescriptionRole] = useState("");
  const [isBlindedRole, setIsBlindedRole] = useState(false);
  const [autoAssignNewSitesRole, setAutoAssignNewSitesRole] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchRoleById = async (id) => {
    try {
      const res = await getSystemRoleById(id);
      if (res.status) {
        const { shortName, description, isBlinded, autoAssignNewSites } =
          res.data;
        setShortNameRole(shortName);
        setDescriptionRole(description);
        setIsBlindedRole(isBlinded);
        setAutoAssignNewSitesRole(autoAssignNewSites);
      }
    } catch (err) {
      setLoad(false);
      console.log("Error: ", err.message);
    }
  };

  const onSubmit2 = async () => {
    try {
      const formdata = new FormData();
      formdata?.append("ShortName", shortNameRole),
        formdata?.append("Description", descriptionRole),
        formdata?.append("IsBlinded", isBlindedRole),
        formdata?.append("AutoAssignNewSites", autoAssignNewSitesRole);
      formdata?.append("Id", studyIdRole);
      formdata?.append("StudyId", id);
      formdata?.append("IsSeedDATA", false);
      formdata?.append("isActive", true);

      const res = await axios.put(
        `https://genesisapi.augursapps.com/app/StudyRoles/UpdateStudyRole?id=${studyIdRole}`,
        formdata
      );
      if (res.status) {
        // console.log("rrrr", res);
        if (res?.status == 200 && res?.data == "Updated successfully") {
          toast.success("Study Role updated successfully");
          handleClose();
          getAllStudyRoles();
        }
      }
    } catch (err) {
      console.log("Error: ", err.message);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchStringStudyRoles(searchStringStudyRoles);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchStringStudyRoles]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchStringSystemRoles(searchStringSystemRoles);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchStringSystemRoles]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchStringStudyCountries(searchStringStudyCountries);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchStringStudyCountries]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchStringSystemCountries(searchStringSystemCountries);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchStringSystemCountries]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchStringStudyLanguages(searchStringStudyLanguages);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchStringStudyLanguages]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchStringSystemLanguages(searchStringSystemLanguages);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchStringSystemLanguages]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchStringStudyRegions(searchStringStudyRegions);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchStringStudyRegions]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchStringSystemRegions(searchStringSystemRegions);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchStringSystemRegions]);

  const fetchEditUser = async (id) => {
    try {
      const res = await getStudyById(id);
      // console.log(res, "API Response");

      if (res.status === 200) {
        const study = res?.data; // Assuming you want to fetch the first study details from the result array

        setAlreadySelectedMgr(study.projectManagers || []);
        setManagerSelected((study.projectManagers || []).map((mgr) => mgr.id));
        setStudyDbJobId(study?.studyDbJobId);
        setStudyDbName(study?.studyDbName);
        setSponsorID(study?.sponsorId);
        setSelectedValue(study?.sponsorName || null);
        setStudyTypeID(study?.studyType?.id);
        setStudyName(study?.studyName);
        setApiUrl(study?.questionnaireBuilderUrl);
        setPortUrl(study?.portalUrl);
        setQuestionaireUrl(study?.questionnaireBuilderUrl);
        setIsActive(study?.isActive);
      } else {
        console.error("No study data found or API call failed");
      }
    } catch (error) {
      console.error("An error occurred while fetching the study data", error);
    }
  };

  const fetchSponsors = async () => {
    try {
      const res = await axios.get(
        `https://genesisapi.augursapps.com/app/Sponsor/SponsorList`
      );
      if (res?.data) {
        setSponsorList(res?.data);
      } else {
        console.error("Failed to fetch sponsors");
      }
    } catch (error) {
      console.error("An error occurred while fetching the sponsors", error);
    }
  };

  const fetchStudyTypes = async () => {
    const res = await getStudyTypes();
    setStudyTypeList(res.data);

    res.data.map((item) => {
      if (item.name === studyType) {
        setStudyTypeID(item.id);
      }
    });
  };

  const pageNumber = 1;
  const pageSize = 10000;

  const getPMS = async () => {
    try {
      const res = await getAllPms(pageNumber, pageSize);

      setProjectMgrs(
        res.data?.result?.filter((data) => data?.studies?.length == 0)
      );
    } catch (error) {
      console.error("Error fetching project managers: ", error.message);
    }
  };

  const AddStudyRoleOnSystemRole = async (role) => {
    try {
      const resp = await axios.post(
        `https://genesisapi.augursapps.com/app/StudyRoles/AddStudyRoleOnSystemRole?systemRoleId=${role?.id}&studyId=${id}`
      );

      if (resp?.data == "Record was already there . Activated successfully") {
        toast.success("Added successfully");
      } else {
        toast.error("Something went wrong");
      }

      fetchSystemRoles();
      getAllStudyRoles();
    } catch (error) {
      console.log({ error });
    }
  };

  const AddSystemRoleOnStudyRole = async (ids) => {
    try {
      const resp = await axios.put(
        `https://genesisapi.augursapps.com/app/StudyRoles/UpdateActiveStatus?id=${ids}&isActive=false&StudyId=${id}`
      );

      if (resp?.data == "Updated successfully") {
        toast.success("Updated successfully");
      } else {
        toast.error("Something went wrong");
      }

      fetchSystemRoles();
      getAllStudyRoles();
    } catch (error) {
      console.log({ error });
    }
  };

  const fetchSystemRoles = async () => {
    try {
      const res = await axios.get(
        `https://genesisapi.augursapps.com/app/SystemRoles/GetSystemRoles?pageNumber=1&pageSize=100000000&filter=%7B"items"%3A%5B%7B"Id"%3A1%2C"columnField"%3A"shortName"%2C"operatorValue"%3A"contains"%2C"value"%3A"${debouncedSearchStringSystemRoles}"%7D%5D%2C"linkOperator"%3A"And"%2C"quickFilterLogicOperator"%3A"And"%7D`
      );
      if (res.status) {
        setSystemRoles(res.data?.result);
      }
    } catch (err) {
      console.log("Error: ", err.message);
    }
  };

  // const getAllStudyRoles = async () => {
  //   try {
  //     // const resp = await axios.get(`https://genesisapi.augursapps.com/app/StudyRoles/GetStudyRolesPaging?pageNumber=1&pageSize=100000000&filter=%7B"items"%3A%5B%7B"Id"%3A1%2C"columnField"%3A"name"%2C"operatorValue"%3A"contains"%2C"value"%3A"${debouncedSearchStringStudyRoles}"%7D%5D%2C"linkOperator"%3A"And"%2C"quickFilterLogicOperator"%3A"And"%7D`);
  //     const resp = await axios.get(`https://genesisapi.augursapps.com/app/StudyRoles/GetStudyRolesPaging?pageNumber=1&pageSize=100000000`);
  //     if(resp?.data){
  //       setStudyRoles(resp?.data?.result?.filter((item) => item?.isActive === true && item?.studyId === id))
  //     }
  //   } catch (error) {
  //     console.log("Error: ", error.message);
  //   }
  // }
  //   const getAllStudyRoles = async () => {
  //    try{
  //     const filter = {
  //       items: [
  //         {
  //           Id: 1,
  //           columnField: "shortName",
  //           operatorValue: "contains",
  //           value: "WednesDay"
  //         },
  //         {
  //           Id: 2,
  //           columnField: "description",
  //           operatorValue: "equals",
  //           value: "wwww"
  //         }
  //       ],
  //       linkOperator: "and",
  //       quickFilterLogicOperator: "and"
  //     };

  //     const sorting = [
  //       {
  //         Field: "shortName",
  //         sort: "asc"
  //       }
  //     ];

  //     // Encode the filter and sorting objects as JSON strings
  //     const filterString = encodeURIComponent(JSON.stringify(filter));
  //     const sortingString = encodeURIComponent(JSON.stringify(sorting));

  //     const url = `https://genesisapi.augursapps.com/app/StudyRoles/GetStudyRolesPaging?pageNumber=1&pageSize=10&filter=${filterString}&sorting=${sortingString}`;

  //     try {
  //       const resp = await axios.get(url);

  //       console.log("Response data:", resp.data);

  //       // Check and process the response data
  //       if (resp?.data && Array.isArray(resp.data)) {
  //         setStudyCountries(resp.data.result.filter((item) => item?.isActive === true && item?.studyId === id));
  //       } else {
  //         console.error("Unexpected response format:", resp.data);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching study roles:", error.message);
  //     }

  //   } catch(error) {
  //     console.log("Error: ", error.message);
  //   }
  // }

  const getAllStudyRoles = async () => {
    try {
      // Define filter criteria
      const filter = {
        items: [
          {
            Id: 1,
            columnField: "shortName",
            operatorValue: "contains",
            value: debouncedSearchStringStudyRoles || "", // Dynamically include search string
          },
          // Add more filter items here if necessary
        ],
        linkOperator: "and",
        quickFilterLogicOperator: "and",
      };

      // Define sorting criteria
      const sorting = [
        {
          Field: "shortName",
          sort: "asc",
        },
      ];

      // Encode filter and sorting objects as JSON strings
      const filterString = encodeURIComponent(JSON.stringify(filter));
      const sortingString = encodeURIComponent(JSON.stringify(sorting));

      // Construct the URL with filters and sorting
      const url = `https://genesisapi.augursapps.com/app/StudyRoles/GetStudyRolesPaging?pageNumber=1&pageSize=100&filter=${filterString}&sorting=${sortingString}`;

      // Fetch data from the API
      const resp = await axios.get(url);
      if (resp?.data?.result && Array.isArray(resp.data.result)) {
        setStudyRoles(
          resp.data.result.filter(
            (item) => item?.isActive === true && item?.studyId === id
          )
        );
      } else {
        console.error("Unexpected response format:", resp.data);
      }
    } catch (error) {
      console.error("Error fetching study roles:", error.message);
    }
  };

  useEffect(() => {
    fetchSystemRoles();
  }, [debouncedSearchStringSystemRoles]);

  useEffect(() => {
    getAllStudyRoles();
  }, [debouncedSearchStringStudyRoles]);

  const AddStudyCountriesOnSystemCountries = async (Countries1) => {
    const { countryId, dateCreatedAt } = Countries1;

    try {
      const payload = {
        countryId,
        isActive: true,
        dateCreatedAt,
        createdBy: 1,
        studyId: id,
      };
      const resp = await axios.post(
        `https://genesisapi.augursapps.com/app/StudyCountry/AddStudyCountry`,
        payload
      );

      if (
        resp?.data?.message == "Added Successfully" ||
        resp?.data?.message ==
          "Updated Successfully  to Active..There is already a record with same studyId and  countryId "
      ) {
        toast.success("Added successfully");
      } else {
        toast.error("Something went wrong");
      }

      fetchSystemCountries();
      getAllStudyCountries();
    } catch (error) {
      console.log({ error });
    }
  };

  const AddSystemCountriesOnStudyCountries = async (Countries2) => {
    const { countryId, dateCreatedAt } = Countries2;

    try {
      const payload = {
        countryId,
        isActive: false,
        dateCreatedAt,
        createdBy: 1,
        studyId: id,
      };

      const resp = await axios.put(
        `https://genesisapi.augursapps.com/app/StudyCountry/UpdateStudyCountry?Id=${Countries2?.id}`,
        payload
      );

      if (resp?.data?.message == "Updated successfully") {
        toast.success("Updated successfully");
      } else {
        toast.error("Something went wrong");
      }

      fetchSystemCountries();
      getAllStudyCountries();
    } catch (error) {
      console.log({ error });
    }
  };

  const fetchSystemCountries = async () => {
    try {
      const res = await axios.get(
        `https://genesisapi.augursapps.com/app/SystemCountry/GetSystemCountries?pageNumber=1&pageSize=100000000&filter=%7B"items"%3A%5B%7B"Id"%3A1%2C"columnField"%3A"name"%2C"operatorValue"%3A"contains"%2C"value"%3A"${debouncedSearchStringSystemCountries}"%7D%5D%2C"linkOperator"%3A"And"%2C"quickFilterLogicOperator"%3A"And"%7D`
      );
      // const res = await axios.get(`https://genesisapi.augursapps.com/app/SystemCountry/GetSystemCountries?pageNumber=1&pageSize=100000000`);

      if (res.status) {
        setSystemCountries(res.data?.result);
      }
    } catch (err) {
      console.log("Error: ", err.message);
    }
  };

  // const getAllStudyCountries = async () => {
  //   try {
  //     const resp = await axios.get(`https://genesisapi.augursapps.com/app/StudyCountry/GetStudyCountryPaging?pageNumber=1&pageSize=100000000&filter=%7B"items"%3A%5B%7B"Id"%3A1%2C"columnField"%3A"countryname"%2C"operatorValue"%3A"contains"%2C"value"%3A"${debouncedSearchStringStudyCountries}"%7D%5D%2C"linkOperator"%3A"And"%2C"quickFilterLogicOperator"%3A"And"%7D`);
  //     // const resp = await axios.get(`https://genesisapi.augursapps.com/app/StudyCountry/GetStudyCountryPaging?pageNumber=1&pageSize=100000000`);

  //     // if(resp?.data){
  //     //   setStudyCountries(resp?.result?.data.filter((item) => item?.isActive === true && item?.studyId === id))
  //     // }
  //     if(resp?.data){
  //       setStudyCountries(resp?.data?.result?.filter((item) => item?.isActive === true && item?.studyId === id))
  //     }
  //   } catch (error) {
  //     console.log("Error: ", error.message);
  //   }
  // }

  // const getAllStudyCountries = async () => {
  //   try {
  //     const resp = await axios.get(`https://genesisapi.augursapps.com/app/StudyCountry/GetStudyCountryPaging?pageNumber=1&pageSize=100000000`);

  //     console.log("Response data:", resp.data);

  //     if (resp?.data?.result?.data && Array.isArray(resp.data.result.data)) {
  //       setStudyCountries(resp.data.result.data.filter((item) => item?.isActive === true && item?.studyId === id));
  //     } else {
  //       console.error("Unexpected response format:", resp.data);
  //     }
  //   } catch (error) {
  //     console.log("Error: ", error.message);
  //   }
  // }
  const getAllStudyCountries = async (countryName = "", studyName = "") => {
    try {
      // Define filter criteria dynamically based on input parameters
      let filter = {};
      if (countryName) filter.CountryName = countryName;
      if (studyName) filter.StudyName = studyName;
  
      // Define sorting criteria
      const sorting = ["CountryName Asc"];
  
      // Encode filter and sorting objects as JSON strings
      const filterString = encodeURIComponent(JSON.stringify(filter));
      const sortingString = encodeURIComponent(JSON.stringify(sorting));
  
      // Construct the URL with filters and sorting
      const url = `https://genesisapi.augursapps.com/app/StudyCountry/GetStudyCountryPaging?pageNumber=1&pageSize=100&filter=${filterString}&sorting=${sortingString}`;
  
      // Log the constructed URL for debugging
      console.log("Constructed URL:", url);
  
      // Fetch data from the API
      const resp = await axios.get(url);
  
      // Log response data for debugging
      console.log("Response data:", resp.data);
  
      // Check and process the response data
      if (resp?.data?.result?.data && Array.isArray(resp.data.result.data)) {
        setStudyCountries(
          resp.data.result.data.filter(
            (item) => item?.isActive === true && item?.studyId === id
          )
        );
      } else {
        console.error("Unexpected response format:", resp.data);
      }
    } catch (error) {
      console.error("Error fetching study countries:", error.message);
    }
  }

  useEffect(() => {
    fetchSystemCountries();
  }, [debouncedSearchStringSystemCountries]);

  useEffect(() => {
    getAllStudyCountries();
  }, [debouncedSearchStringStudyCountries]);

  const AddStudyLanguagesOnSystemLanguages = async (Languages1) => {
    const { languageId } = Languages1;

    try {
      const payload = {
        languageId,
        isActive: true,
        dateCreatedAt: new Date(),
        createdBy: 1,
        studyId: id,
      };
      const resp = await axios.post(
        `https://genesisapi.augursapps.com/app/StudyLanguage/AddStudyLanguage`,
        payload
      );

      if (
        resp?.data?.message == "Added" ||
        resp?.data?.message ==
          "Updated Successfully to Active..There is already a record with same studyId and  LanguageId"
      ) {
        toast.success("Added successfully");
      } else {
        toast.error("Something went wrong");
      }

      fetchSystemLanguages();
      getAllStudyLanguages();
    } catch (error) {
      console.log({ error });
    }
  };

  const AddSystemLanguagesOnStudyLanguages = async (Languages2) => {
    const { languageId } = Languages2;

    try {
      const payload = {
        languageId,
        isActive: false,
        dateCreatedAt: new Date(),
        createdBy: 1,
        studyId: id,
      };

      const resp = await axios.put(
        `https://genesisapi.augursapps.com/app/StudyLanguage/UpdateStudyLanguage?Id=${Languages2?.id}`,
        payload
      );

      if (resp?.data?.message == "Updated Successfully") {
        toast.success("Updated successfully");
      } else {
        toast.error("Something went wrong");
      }

      fetchSystemLanguages();
      getAllStudyLanguages();
    } catch (error) {
      console.log({ error });
    }
  };

  const fetchSystemLanguages = async () => {
    try {
      const res = await axios.get(
        `https://genesisapi.augursapps.com/app/SystemLanguage?pageNumber=1&pageSize=100000000&filter=%7B"items"%3A%5B%7B"Id"%3A1%2C"columnField"%3A"name"%2C"operatorValue"%3A"contains"%2C"value"%3A"${debouncedSearchStringSystemLanguages}"%7D%5D%2C"linkOperator"%3A"And"%2C"quickFilterLogicOperator"%3A"And"%7D`
      );
      if (res.status) {
        setSystemLanguages(res.data?.result);
      }
    } catch (err) {
      console.log("Error: ", err.message);
    }
  };

  // const getAllStudyLanguages = async () => {
  //   try {
  //     const resp = await axios.get(`https://genesisapi.augursapps.com/app/StudyLanguage/GetStudyLanguagePaging?pageNumber=1&pageSize=100000000&filter=%7B"items"%3A%5B%7B"Id"%3A1%2C"columnField"%3A"name"%2C"operatorValue"%3A"contains"%2C"value"%3A"${debouncedSearchStringStudyLanguages}"%7D%5D%2C"linkOperator"%3A"And"%2C"quickFilterLogicOperator"%3A"And"%7D`);

  //     if(resp?.data){
  //       setStudyLanguages(resp?.data?.result?.filter((item) => item?.isActive === true && item?.studyId === id))
  //     }
  //   } catch (error) {
  //     console.log("Error: ", error.message);
  //   }
  // }
  // const getAllStudyLanguages = async (languageName) => {
  //   console.log(languageName,'fdfdf')
  //   try {
  //     const resp = await axios.get(
  //       `https://genesisapi.augursapps.com/app/StudyLanguage/GetStudyLanguagePaging?pageNumber=1&pageSize=100000000`
  //     );

  //     console.log("Response data:", resp.data);

  //     if (resp?.data?.result?.data && Array.isArray(resp.data.result.data)) {
  //       setStudyLanguages(
  //         resp.data.result.data.filter(
  //           (item) => item?.isActive === true && item?.studyId === id
  //         )
  //       );
  //     } else {
  //       console.error("Unexpected response format:", resp.data);
  //     }
  //   } catch (error) {
  //     console.log("Error: ", error.message);
  //   }
  // };
  const getAllStudyLanguages = async (languageName = "") => {
    try {
      // Log input parameters
      console.log("Input parameters:", { languageName });
  
      // Define filter criteria dynamically based on input parameters
      let filter = {};
      if (languageName) filter.LanguageName = languageName;
      console.log("Filter object before encoding:", filter);
  
      // Define sorting criteria
      const sorting = ["LanguageName Asc"];
  
      // Encode filter and sorting objects as JSON strings
      const filterString = encodeURIComponent(JSON.stringify(filter));
      const sortingString = encodeURIComponent(JSON.stringify(sorting));
  
      // Construct the URL with filters and sorting
      const url = `https://genesisapi.augursapps.com/app/StudyLanguage/GetStudyLanguagePaging?pageNumber=1&pageSize=100&filter=${filterString}&sorting=${sortingString}`;
  
      // Log the constructed URL for debugging
      console.log("Constructed URL:", url);
  
      // Fetch data from the API
      const resp = await axios.get(url);
  
      // Log response data for debugging
      console.log("Response data:", resp.data);
  
      // Check and process the response data
      if (resp?.data?.result?.data && Array.isArray(resp.data.result.data)) {
        setStudyLanguages(
          resp.data.result.data.filter(
            (item) => item?.isActive === true && item?.studyId === id
          )
        );
      } else {
        console.error("Unexpected response format:", resp.data);
      }
    } catch (error) {
      console.error("Error fetching study countries:", error.message);
    }
  };
  
  

  useEffect(() => {
    fetchSystemLanguages();
  }, [debouncedSearchStringSystemLanguages]);

  useEffect(() => {
    getAllStudyLanguages(searchStringStudyLanguages);
  }, [debouncedSearchStringStudyLanguages]);

  const AddStudyRegionsOnSystemRegions = async (Regions1) => {
    const { regionId, dateCreatedAt } = Regions1;

    try {
      const payload = {
        regionId,
        isActive: true,
        dateCreatedAt,
        createdBy: 1,
        studyId: id,
      };
      const resp = await axios.post(
        `https://genesisapi.augursapps.com/app/StudyRegion/AddStudyRegion`,
        payload
      );

      if (
        resp?.data?.message == "Added" ||
        resp?.data?.message ==
          "Updated successfully  to Active..there is already a record with same studyId and  RegionId "
      ) {
        toast.success("Added successfully");
      } else {
        toast.error("Something went wrong");
      }

      fetchSystemRegions();
      getAllStudyRegions();
    } catch (error) {
      console.log({ error });
    }
  };

  const AddSystemRegionsOnStudyRegions = async (Regions2) => {
    const { regionId, dateCreatedAt } = Regions2;

    try {
      const payload = {
        regionId,
        isActive: false,
        dateCreatedAt,
        createdBy: 1,
        studyId: id,
      };

      const resp = await axios.put(
        `https://genesisapi.augursapps.com/app/StudyRegion/UpdateStudyRegion?Id=${Regions2?.id}`,
        payload
      );

      if (resp?.data?.message == "Updated successfully") {
        toast.success("Updated successfully");
      } else {
        toast.error("Something went wrong");
      }

      fetchSystemRegions();
      getAllStudyRegions();
    } catch (error) {
      console.log({ error });
    }
  };

  const fetchSystemRegions = async () => {
    try {
      const res = await axios.get(
        `https://genesisapi.augursapps.com/app/SystemRegion?pageNumber=1&pageSize=100000000&filter=%7B"items"%3A%5B%7B"Id"%3A1%2C"columnField"%3A"name"%2C"operatorValue"%3A"contains"%2C"value"%3A"${debouncedSearchStringSystemRegions}"%7D%5D%2C"linkOperator"%3A"And"%2C"quickFilterLogicOperator"%3A"And"%7D`
      );
      if (res.status) {
        setSystemRegions(res.data?.result);
      }
    } catch (err) {
      console.log("Error: ", err.message);
    }
  };

  // const getAllStudyRegions = async () => {
  //   try {
  //     const resp = await axios.get(`https://genesisapi.augursapps.com/app/StudyRegion/GetStudyRegionPaging?pageNumber=1&pageSize=100000000&filter=%7B"items"%3A%5B%7B"Id"%3A1%2C"columnField"%3A"name"%2C"operatorValue"%3A"contains"%2C"value"%3A"${debouncedSearchStringStudyRegions}"%7D%5D%2C"linkOperator"%3A"And"%2C"quickFilterLogicOperator"%3A"And"%7D`);

  //     if(resp?.data){
  //       setStudyRegions(resp?.data?.result?.filter((item) => item?.isActive === true && item?.studyId === id))
  //     }
  //   } catch (error) {
  //     console.log("Error: ", error.message);
  //   }
  // }
  const getAllStudyRegions = async () => {
    try {
      const resp = await axios.get(
        `https://genesisapi.augursapps.com/app/StudyRegion/GetStudyRegionPaging?pageNumber=1&pageSize=100000000`
      );

      console.log("Response data:", resp.data);

      if (resp?.data?.result?.data && Array.isArray(resp.data.result.data)) {
        setStudyRegions(
          resp.data.result.data.filter(
            (item) => item?.isActive === true && item?.studyId === id
          )
        );
      } else {
        console.error("Unexpected response format:", resp.data);
      }
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  

  useEffect(() => {
    fetchSystemRegions();
  }, [debouncedSearchStringSystemRegions]);

  useEffect(() => {
    getAllStudyRegions();
  }, [debouncedSearchStringStudyRegions]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const ItemTypes = {
    ROLE: "role",
    COUNTRY: "country",
    LANGUAGE: "language",
    REGION: "region",
  };

  const RoleItem = ({ role, id, type, desc, onClick }) => {
    const [, drag] = useDrag(() => ({
      type: ItemTypes.ROLE,
      item: { role, type, id, desc },
    }));

    return (
      <ListItem
        ref={drag}
        onClick={onClick}
        sx={{
          width: "100%",
          marginBottom: "8px",
          marginRight: "6px",
          padding: "16px",
          backgroundColor: "#f0f0f0",
          cursor: "pointer",
          borderRadius: "10px",
          fontSize: "15px",
        }}
      >
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <Typography variant="h6" fontSize={18}>
            {role}
          </Typography>
          <span>-</span>
          <Typography variant="body1">{desc}</Typography>
        </Stack>
      </ListItem>
      // </LightTooltip>
    );
  };

  const RoleContainerSystem = ({ roles, type, onDropRole }) => {
    const [, drop] = useDrop({
      accept: ItemTypes.ROLE,
      drop: (item) => {
        if (item.type === "study" && type === "system") {
          onDropRole(item.id);
        }
      },
    });

    return (
      <Paper
        ref={drop}
        sx={{
          paddingX: "16px",
          height: "400px",
          overflow: "auto",
          marginTop: "10px",
        }}
      >
        <List sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          {roles.map((role, index) => (
            // <Tooltip title={role?.description}>
            <RoleItem
              key={index}
              desc={role?.description}
              id={role?.id}
              role={role?.shortName}
              type={type}
            />
            // </Tooltip>
          ))}
        </List>
      </Paper>
    );
  };

  const RoleContainerStudy = ({ roles, type, onDropRole }) => {
    const [, drop] = useDrop({
      accept: ItemTypes.ROLE,
      drop: (item) => {
        if (item.type === "system" && type === "study") {
          onDropRole(item);
        }
      },
    });

    return (
      <Paper
        ref={drop}
        sx={{
          paddingX: "16px",
          height: "400px",
          overflow: "auto",
          marginTop: "10px",
        }}
      >
        <List sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          {roles?.map((role, index) => (
            <RoleItem
              onClick={() => {
                setStudyIdRole(role?.id);
                fetchRoleById(role?.id);
                setTimeout(() => {
                  handleClickOpen(true);
                }, 500);
              }}
              key={index}
              desc={role?.description}
              id={role?.id}
              role={role?.shortName}
              type={type}
            />
          ))}
        </List>
      </Paper>
    );
  };

  const handleDropRoleStudy = (role) => {
    // console.log("role", role);
    AddStudyRoleOnSystemRole(role);
  };

  const handleDropRoleSystem = (role) => {
    AddSystemRoleOnStudyRole(role);
  };

  const CountriesItem = ({ countryId, dateCreatedAt, type, id, onClick }) => {
    const [, drag] = useDrag(() => ({
      type: ItemTypes.COUNTRY,
      item: { countryId, type, dateCreatedAt, id },
    }));

    const countryName = systemCountries?.filter(
      (data) => data?.id == countryId
    );

    return (
      <ListItem
        ref={drag}
        onClick={onClick}
        sx={{
          width: "100%",
          marginBottom: "8px",
          marginRight: "6px",
          padding: "16px",
          backgroundColor: "#f0f0f0",
          cursor: "pointer",
          borderRadius: "10px",
          fontSize: "15px",
        }}
      >
        {/* <Stack direction={"row"} alignItems={"center"} spacing={1}> */}
        <Typography variant="h6" fontSize={18}>
          {countryName[0]?.name}
        </Typography>
        {/* <span>-</span>
            <Typography variant="body1">...</Typography> */}
        {/* </Stack> */}
      </ListItem>
      // </LightTooltip>
    );
  };

  const CountriesContainerSystem = ({ Countries, type, onDropCountries }) => {
    const [, drop] = useDrop({
      accept: ItemTypes.COUNTRY,
      drop: (item) => {
        if (item.type === "study" && type === "system") {
          onDropCountries(item);
        }
      },
    });

    return (
      <Paper
        ref={drop}
        sx={{
          paddingX: "16px",
          height: "400px",
          overflow: "auto",
          marginTop: "10px",
        }}
      >
        <List sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          {Countries?.map((Country, index) => (
            // <Tooltip title={Countries?.description}>
            <CountriesItem
              key={index}
              countryId={Country?.id}
              id={Country?.id}
              dateCreatedAt={Country?.dateCreatedAt}
              type={type}
            />
            // </Tooltip>
          ))}
        </List>
      </Paper>
    );
  };

  const CountriesContainerStudy = ({ Countries, type, onDropCountries }) => {
    const [, drop] = useDrop({
      accept: ItemTypes.COUNTRY,
      drop: (item) => {
        // console.log({item});
        if (item.type === "system" && type === "study") {
          onDropCountries(item);
        }
      },
    });

    return (
      <Paper
        ref={drop}
        sx={{
          paddingX: "16px",
          height: "400px",
          overflow: "auto",
          marginTop: "10px",
        }}
      >
        <List sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          {Countries?.map((Country, index) => (
            // <Tooltip title={Countries?.description}>
            <CountriesItem
              key={index}
              countryId={Country?.countryId}
              dateCreatedAt={Country?.dateCreatedAt}
              id={Country?.id}
              type={type}
            />
            // </Tooltip>
          ))}
        </List>
      </Paper>
    );
  };

  const handleDropCountriesStudy = (Countries) => {
    // console.log("Countries", Countries);
    AddStudyCountriesOnSystemCountries(Countries);
  };

  const handleDropCountriesSystem = (Countries) => {
    // console.log("Country", Countries);
    AddSystemCountriesOnStudyCountries(Countries);
  };

  const LanguagesItem = ({ languageId, dateCreatedAt, type, id, onClick }) => {
    const [, drag] = useDrag(() => ({
      type: ItemTypes.LANGUAGE,
      item: { languageId, type, dateCreatedAt, id },
    }));

    const languageName = systemLanguages?.filter(
      (data) => data?.id == languageId
    );

    return (
      <ListItem
        ref={drag}
        onClick={onClick}
        sx={{
          width: "100%",
          marginBottom: "8px",
          marginRight: "6px",
          padding: "16px",
          backgroundColor: "#f0f0f0",
          cursor: "pointer",
          borderRadius: "10px",
          fontSize: "15px",
        }}
      >
        {/* <Stack direction={"row"} alignItems={"center"} spacing={1}> */}
        <Typography variant="h6" fontSize={18}>
          {languageName[0]?.name}
        </Typography>
        {/* <span>-</span>
            <Typography variant="body1">...</Typography> */}
        {/* </Stack> */}
      </ListItem>
      // </LightTooltip>
    );
  };

  const LanguagesContainerSystem = ({ Languages, type, onDropLanguages }) => {
    const [, drop] = useDrop({
      accept: ItemTypes.LANGUAGE,
      drop: (item) => {
        // console.log({item});
        if (item.type === "study" && type === "system") {
          onDropLanguages(item);
        }
      },
    });

    return (
      <Paper
        ref={drop}
        sx={{
          paddingX: "16px",
          height: "400px",
          overflow: "auto",
          marginTop: "10px",
        }}
      >
        <List sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          {Languages?.map((Language, index) => (
            // <Tooltip title={Languages?.description}>
            <LanguagesItem
              key={index}
              languageId={Language?.id}
              id={Language?.id}
              dateCreatedAt={Language?.dateCreatedAt}
              type={type}
            />
            // </Tooltip>
          ))}
        </List>
      </Paper>
    );
  };

  const LanguagesContainerStudy = ({ Languages, type, onDropLanguages }) => {
    const [, drop] = useDrop({
      accept: ItemTypes.LANGUAGE,
      drop: (item) => {
        // console.log({item});
        if (item.type === "system" && type === "study") {
          onDropLanguages(item);
        }
      },
    });

    return (
      <Paper
        ref={drop}
        sx={{
          paddingX: "16px",
          height: "400px",
          overflow: "auto",
          marginTop: "10px",
        }}
      >
        <List sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          {Languages?.map((Language, index) => (
            // <Tooltip title={Languages?.description}>
            <LanguagesItem
              key={index}
              languageId={Language?.languageId}
              dateCreatedAt={Language?.dateCreatedAt}
              id={Language?.id}
              type={type}
            />
            // </Tooltip>
          ))}
        </List>
      </Paper>
    );
  };

  const handleDropLanguagesStudy = (Languages) => {
    // console.log("Languages", Languages);
    AddStudyLanguagesOnSystemLanguages(Languages);
  };

  const handleDropLanguagesSystem = (Languages) => {
    // console.log("Languages", Languages);
    AddSystemLanguagesOnStudyLanguages(Languages);
  };

  const RegionsItem = ({ regionId, dateCreatedAt, type, id, onClick }) => {
    const [, drag] = useDrag(() => ({
      type: ItemTypes.REGION,
      item: { regionId, type, dateCreatedAt, id },
    }));

    const regionName = systemRegions?.filter((data) => data?.id == regionId);

    return (
      <ListItem
        ref={drag}
        onClick={onClick}
        sx={{
          width: "100%",
          marginBottom: "8px",
          marginRight: "6px",
          padding: "16px",
          backgroundColor: "#f0f0f0",
          cursor: "pointer",
          borderRadius: "10px",
          fontSize: "15px",
        }}
      >
        {/* <Stack direction={"row"} alignItems={"center"} spacing={1}> */}
        <Typography variant="h6" fontSize={18}>
          {regionName[0]?.name}
        </Typography>
        {/* <span>-</span>
            <Typography variant="body1">...</Typography> */}
        {/* </Stack> */}
      </ListItem>
      // </LightTooltip>
    );
  };

  const RegionsContainerSystem = ({ Regions, type, onDropRegions }) => {
    const [, drop] = useDrop({
      accept: ItemTypes.REGION,
      drop: (item) => {
        if (item.type === "study" && type === "system") {
          onDropRegions(item);
        }
      },
    });

    return (
      <Paper
        ref={drop}
        sx={{
          paddingX: "16px",
          height: "400px",
          overflow: "auto",
          marginTop: "10px",
        }}
      >
        <List sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          {Regions?.map((Region, index) => (
            // <Tooltip title={Regions?.description}>
            <RegionsItem
              key={index}
              regionId={Region?.id}
              id={Region?.id}
              dateCreatedAt={Region?.dateCreatedAt}
              type={type}
            />
            // </Tooltip>
          ))}
        </List>
      </Paper>
    );
  };

  const RegionsContainerStudy = ({ Regions, type, onDropRegions }) => {
    const [, drop] = useDrop({
      accept: ItemTypes.REGION,
      drop: (item) => {
        // console.log({item});
        if (item.type === "system" && type === "study") {
          onDropRegions(item);
        }
      },
    });

    return (
      <Paper
        ref={drop}
        sx={{
          paddingX: "16px",
          height: "400px",
          overflow: "auto",
          marginTop: "10px",
        }}
      >
        <List sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          {Regions?.map((Region, index) => (
            // <Tooltip title={Regions?.description}>
            <RegionsItem
              key={index}
              regionId={Region?.regionId}
              dateCreatedAt={Region?.dateCreatedAt}
              id={Region?.id}
              type={type}
            />
            // </Tooltip>
          ))}
        </List>
      </Paper>
    );
  };

  const handleDropRegionsStudy = (Regions) => {
    // console.log("Regions", Regions);
    AddStudyRegionsOnSystemRegions(Regions);
  };

  const handleDropRegionsSystem = (Regions) => {
    // console.log("Regions", Regions);
    AddSystemRegionsOnStudyRegions(Regions);
  };

  useEffect(() => {
    getPMS();
    fetchSponsors();
    fetchStudyTypes();
  }, []);

  useEffect(() => {
    getPMS();
    fetchSponsors();
    fetchStudyTypes();
  }, [isActive]);

  useEffect(() => {
    fetchEditUser(id);
  }, [id]);

  yup.addMethod(yup.string, "urlWithDomain", function () {
    return this.test("urlWithDomain", "URL must be a valid URL with a domain extension", function (value) {
      const { path, createError } = this;
      if (!value) return true;
      const urlWithScheme = value.match(/^https?:\/\//i) ? value : `http://${value}`;
      try {
        const url = new URL(urlWithScheme);
        const validDomainPattern = /\.[a-z]{2,}$/i;
        if (!validDomainPattern.test(url.hostname)) {
          return createError({ path, message: "URL must include a valid domain extension" });
        }
  
        return true;
      } catch {
        return createError({ path, message: "URL must be a valid URL" });
      }
    });
  });

  const validationSchema = yup.object().shape({
    studyName: yup.string().max(255).required("Study Name is required"),
    portUrl: yup
      .string()
      .max(255)
      .required("Portal URL is required")
      .urlWithDomain(),
    apiUrl: yup
      .string()
      .max(255)
      .required("API URL is required")
      .urlWithDomain(),
    questionaireUrl: yup
      .string()
      .max(255)
      .required("Questionnaire builder URL is required")
      .urlWithDomain(),
  });


  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const notify = () =>
    toast.success("Study Updated Successfully", {
      toastId: "editSuccessToast",
    });

  const activeSuccess = () =>
    toast.success("Status Updated Successfully", {
      toastId: "statusSuccessToast",
    });

  const sponsorRequired = () =>
    toast.warn("Sponsor Is Required", {
      toastId: "sponsorRequiredToast",
    });

  const studyTypeRequired = () =>
    toast.warn("Study Is Required", {
      toastId: "studyRequiredToast",
    });

  const managerRequired = () =>
    toast.warn("Manager Is Required", {
      toastId: "managerRequired",
    });

  const requestFailed = () =>
    toast.error("Something went wrong", {
      toastId: "requestFailed",
    });

  const cancelStudy = (e) => {
    e.preventDefault();
    navigate("/all-studies");
  };

  const onSubmit = async (data, e) => {
    setLoad(true);
    e.preventDefault();
    try {
      if (sponsorID === "" || sponsorID === null) {
        sponsorRequired();
        setLoad(false);
      } else if (studyTypeID === "") {
        studyTypeRequired();
        setLoad(false);
      } else if (managerSelected.length === 0) {
        managerRequired();
        setLoad(false);
      } else {
        setCompLoad(true);
        setShowDialog(false);
        const newData = {
          SponsorId: sponsorID,
          PortalUrl: data.portUrl,
          Name: data.studyName,
          ApiUrl: data.apiUrl,
          StudyTypeId: studyTypeID,
          QuestionnaireBuilderUrl: data.questionaireUrl,
          ProjectManagerId: managerSelected,
          IsActive: isActive,
          StudyDbName,
          StudyDbJobId,
        };
        const res = await editStudy(editStudyID, newData);
        if (res.status) {
          notify();
          navigate("/all-studies");
        } else {
          requestFailed();
          setCompLoad(false);
        }
      }
    } catch (err) {
      console.log("submit error: ", err);
      setShowDialog(true);
      requestFailed();
    } finally {
      setCompLoad(false);
      setLoad(false);
    }
  };

  const handleSponsorChange = (event) => {
    setSponsorID(event.target.value);
    setShowDialog(true);
  };

  const handleStudyChange = (event) => {
    let name = event.target.value;
    if (name.length > 0 && name[0] === " ") {
      name = name.trimStart();
      event.target.value = name;
    }
    if (name) {
      setStudyName(name);
      setShowDialog(true);
      clearErrors("studyName");
    }
  };

  const handleStudyTypeChange = (event) => {
    setStudyTypeID(event.target.value);
    setShowDialog(true);
  };

  const handlePortUrl = (event) => {
    let name = event.target.value;
    if (name.length > 0 && name[0] === " ") {
      name = name.trimStart();
      event.target.value = name;
    }
    setPortUrl(name);
    if (name) {
      setShowDialog(true);
      clearErrors("portUrl");
    }
  };

  const handleApiUrl = (event) => {
    let name = event.target.value;
    if (name.length > 0 && name[0] === " ") {
      name = name.trimStart();
      event.target.value = name;
    }
    setApiUrl(name);
    if (name) {
      setShowDialog(true);
      clearErrors("apiUrl");
    }
  };

  const handleQuestionaireUrl = (event) => {
    let name = event.target.value;
    if (name.length > 0 && name[0] === " ") {
      name = name.trimStart();
      event.target.value = name;
    }
    setQuestionaireUrl(name);
    if (name) {
      setShowDialog(true);
      clearErrors("questionaireUrl");
    }
  };

  const handleActiveChange = async (editStudyID, event) => {
    setShowDialog(true);
    setLoad(true);
    const activeChecked = event.target.checked;
    setIsActive(activeChecked);

    const res = await editStudyStatus(editStudyID, activeChecked);
    if (res.status === 200) {
      setLoad(false);
      activeSuccess();
    } else {
      setLoad(false);
      requestFailed();
    }
  };

  const handleMgrAlreadySelected = (item, event) => {
    setShowDialog(true);
    const isChecked = event.target.checked;
    if (isChecked === true) {
      const managerArray = [];
      managerArray.push(item.id);
      setManagerSelected([...managerSelected, ...managerArray]);
    } else if (isChecked === false) {
      const filterManager = managerSelected.filter((mgr) => mgr !== item.id);
      setManagerSelected(filterManager);
    }
  };

  const handleMgrSelect = (item, event) => {
    setShowDialog(true);
    const isChecked = event.target.checked;
    if (isChecked === true) {
      const managerArray = [];
      managerArray.push(item.id);
      setManagerSelected([...managerSelected, ...managerArray]);
    } else if (isChecked === false) {
      const filterManager = managerSelected.filter((mgr) => mgr !== item.id);
      setManagerSelected(filterManager);
    }
  };

  const filterOptions = (options, state) => {
    return options.filter((option) =>
      option.name.toLowerCase().includes(state.inputValue.toLowerCase())
    );
  };

  return (
    <>
      {systemRoles?.length > 0 && studyName?.length > 0 ? (
        <>
          <div className="content-body">
            <DialogBox
              showDialog={showPrompt}
              confirmNavigation={confirmNavigation}
              cancelNavigation={cancelNavigation}
            />

            <p className="admin-link" style={{ fontWeight: "600" }}>
              <Link to="/homepage">Home</Link>
              {" > "}
              <Link to="/all-studies">Studies</Link>
              {" > "}
              <span
                style={{
                  color: "#4b8ac0",
                  cursor: "pointer",
                }}
              >
                Update Study
              </span>
            </p>

            <Box
              sx={{
                width: "100%",
                borderColor: "divider",
                marginTop: 3,
                overflow: "auto",
              }}
            >
              <Tabs
                className="system-tabs"
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="fullWidth"
              >
                <Tab sx={tabStyles} label="Update Study" {...a11yProps(0)} />
                <Tab
                  sx={tabStyles}
                  label="Roles Management"
                  {...a11yProps(1)}
                />
                <Tab
                  sx={tabStyles}
                  label="Countries Management"
                  {...a11yProps(2)}
                />
                <Tab
                  sx={tabStyles}
                  label="Languages Management"
                  {...a11yProps(3)}
                />
                <Tab
                  sx={tabStyles}
                  label="Regions Management"
                  {...a11yProps(4)}
                />
              </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
              <Box sx={{ marginTop: "2%" }}>
                <>
                  <p className="sponsor-heading">Update Study</p>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="uploadField">
                      <Row style={{ alignItems: "center" }}>
                        <Col
                          xs={12}
                          sm={12}
                          md={6}
                          style={{ paddingInline: "3%", paddingBlock: "1%" }}
                        >
                          <label className="uploadInputLabel">
                            Sponsor
                            <span className="error-highlight">*</span>
                          </label>

                          <Autocomplete
                            disablePortal
                            options={sponsorList}
                            value={selectedValue}
                            onChange={(event, value) => {
                              setSponsorID(value ? value.id : null);
                              setSelectedValue(value);
                            }}
                            getOptionLabel={(option) => option.name}
                            fullWidth
                            // filterOptions={filterOptions}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                hiddenLabel={true}
                                variant="outlined"
                              />
                            )}
                          />

                          {/* <FormControl className="nameField">
                          <Select
                            name="sponsor"
                            value={sponsorID}
                            inputProps={{ "aria-label": "Without label" }}
                            onChange={handleSponsorChange}
                          >
                            <MenuItem value="">
                              <em>Select Sponsor</em>
                            </MenuItem>
                            {(sponsorList || []).map((item, index) => (
                              <MenuItem key={index} value={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl> */}

                          <span style={{ color: "#3661eb", marginTop: "1%" }}>
                            {errors.sponsor?.message}
                          </span>
                        </Col>

                        <Col
                          xs={12}
                          sm={12}
                          md={6}
                          style={{ paddingInline: "3%", paddingBlock: "1%" }}
                        >
                          <label className="uploadInputLabel">
                            Portal Url
                            <span className="error-highlight">*</span>
                          </label>
                          <input
                            className="nameField"
                            defaultValue={portUrl}
                            type="text"
                            name="portUrl"
                            {...register("portUrl", { required: true })}
                            onChange={handlePortUrl}
                          />
                          <span style={{ color: "#3661eb", marginTop: "1%" }}>
                            {errors.portUrl?.message}
                          </span>
                        </Col>

                        <Col
                          xs={12}
                          sm={12}
                          md={6}
                          style={{ paddingInline: "3%", paddingBlock: "1%" }}
                        >
                          <label className="uploadInputLabel">
                            Name
                            <span className="error-highlight">*</span>
                          </label>
                          <input
                            name="studyName"
                            defaultValue={studyName}
                            className="nameField"
                            type="text"
                            {...register("studyName", { required: true })}
                            onChange={handleStudyChange}
                          />
                          <span style={{ color: "#3661eb", marginTop: "1%" }}>
                            {errors.studyName?.message}
                          </span>
                        </Col>

                        <Col
                          xs={12}
                          sm={12}
                          md={6}
                          style={{ paddingInline: "3%", paddingBlock: "1%" }}
                        >
                          <label className="uploadInputLabel">
                            Api Url
                            <span className="error-highlight">*</span>
                          </label>
                          <input
                            className="nameField"
                            defaultValue={apiUrl}
                            type="text"
                            name="apiUrl"
                            {...register("apiUrl", { required: true })}
                            onChange={handleApiUrl}
                          />
                          <span style={{ color: "#3661eb", marginTop: "1%" }}>
                            {errors.apiUrl?.message}
                          </span>
                        </Col>

                        <Col
                          xs={12}
                          sm={12}
                          md={6}
                          style={{ paddingInline: "3%", paddingBlock: "1%" }}
                        >
                          <label className="uploadInputLabel">
                            Study Type
                            <span className="error-highlight">*</span>
                          </label>
                          <FormControl className="nameField">
                            <Select
                              name="studyType"
                              value={studyTypeID}
                              onChange={handleStudyTypeChange}
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem value="">
                                <em>Select Study Type</em>
                              </MenuItem>
                              {studyTypeList.map((item, index) => (
                                <MenuItem key={index} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <span style={{ color: "#3661eb", marginTop: "1%" }}>
                            {errors.studyType?.message}
                          </span>
                        </Col>

                        <Col
                          xs={12}
                          sm={12}
                          md={6}
                          style={{ paddingInline: "3%", paddingBlock: "1%" }}
                        >
                          <label className="uploadInputLabel">
                            Questionaire builder Url{" "}
                            <span className="error-highlight">*</span>
                          </label>
                          <input
                            name="questionaireUrl"
                            defaultValue={questionaireUrl}
                            className="nameField"
                            type="text"
                            {...register("questionaireUrl", { required: true })}
                            onChange={handleQuestionaireUrl}
                          />
                          <span style={{ color: "#3661eb", marginTop: "1%" }}>
                            {errors.questionaireUrl?.message}
                          </span>
                        </Col>

                        <Col
                          xs={12}
                          sm={12}
                          md={6}
                          style={{ paddingInline: "3%", paddingBlock: "1%" }}
                        >
                          <fieldset className="my-fieldset">
                            <legend className="manager-legend">
                              Project Managers
                              <span className="error-highlight">*</span>:{" "}
                            </legend>
                            <div
                              style={{
                                paddingTop: "18px",
                                paddingLeft: "18px",
                                paddingRight: "18px",
                                paddingBottom: "0px",
                              }}
                            >
                              {alreadySelectedMgr &&
                              alreadySelectedMgr.length === 0 ? (
                                <p>No already selected managers</p>
                              ) : (
                                alreadySelectedMgr.map((manager, index) => (
                                  <div
                                    className="toggleContainerNew"
                                    key={index}
                                  >
                                    <p className="generalHead">
                                      {manager?.emailAddress}
                                    </p>
                                    <Stack
                                      direction="row"
                                      spacing={1}
                                      alignItems="center"
                                    >
                                      <AntSwitch
                                        defaultChecked={alreadySelectedMgr.filter(
                                          (item) => item.id === manager.id
                                        )}
                                        onChange={(event) =>
                                          handleMgrAlreadySelected(
                                            manager,
                                            event
                                          )
                                        }
                                        inputProps={{
                                          "aria-label": "ant design",
                                        }}
                                      />
                                    </Stack>
                                  </div>
                                ))
                              )}
                            </div>
                            <div
                              style={{
                                paddingTop: "0px",
                                paddingLeft: "18px",
                                paddingRight: "18px",
                                paddingBottom: "18px",
                              }}
                            >
                              {projectMgrs && projectMgrs.length === 0 ? (
                                <p>No new project managers available yet.</p>
                              ) : (
                                projectMgrs.map((item, index) => (
                                  <div
                                    className="toggleContainerNew"
                                    key={index}
                                  >
                                    <p className="generalHead">
                                      {item.emailAddress}
                                    </p>
                                    <Stack
                                      direction="row"
                                      spacing={1}
                                      alignItems="center"
                                    >
                                      <AntSwitch
                                        onChange={(event) =>
                                          handleMgrSelect(item, event)
                                        }
                                        inputProps={{
                                          "aria-label": "ant design",
                                        }}
                                      />
                                    </Stack>
                                  </div>
                                ))
                              )}
                            </div>
                          </fieldset>
                        </Col>

                        <Col
                          xs={12}
                          sm={12}
                          md={6}
                          style={{ paddingInline: "3%", paddingBlock: "1%" }}
                        >
                          {load ? (
                            <>
                              <div
                                style={{
                                  alignItems: "center",
                                  height: "70vh",
                                }}
                              >
                                <BeatLoader color="#3661eb" />
                              </div>
                            </>
                          ) : (
                            <fieldset className="my-fieldset">
                              <legend className="status-legend">Status:</legend>
                              <div className="toggleContainer">
                                <p className="generalHead">Active</p>
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <AntSwitch
                                    checked={isActive}
                                    onChange={(event) =>
                                      handleActiveChange(editStudyID, event)
                                    }
                                    inputProps={{ "aria-label": "ant design" }}
                                  />
                                </Stack>
                              </div>
                            </fieldset>
                          )}
                        </Col>

                        <Col
                          xs={12}
                          sm={12}
                          md={6}
                          style={{ paddingInline: "3%", paddingBlock: "4%" }}
                        >
                          <div className="createSponsor-buttons">
                            <button
                              className="sponsorCancelButton"
                              onClick={cancelStudy}
                            >
                              Cancel
                            </button>
                            <button
                              className="sponsorCreateButton"
                              type="submit"
                            >
                              Update
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </form>
                </>
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <DndProvider backend={HTML5Backend}>
                <Container
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "32px",
                  }}
                >
                  <Stack style={{ width: "45%" }}>
                    <Row>
                      <Col sm={12} md={4}>
                        <Typography variant="h4" gutterBottom>
                          {"System Roles"}
                        </Typography>
                      </Col>
                      <Col sm={12} md={8}>
                        <Paper
                          component="form"
                          sx={{
                            p: "4px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <SearchSharp style={{ width: 20, height: 20 }} />
                          <InputBase
                            sx={{ ml: 1, flex: 1, fontSize: 16 }}
                            placeholder="Search System Roles"
                            inputProps={{ "aria-label": "search system roles" }}
                            value={searchStringSystemRoles}
                            onChange={(e) =>
                              setSearchStringSystemRoles(e.target.value)
                            }
                          />
                        </Paper>
                      </Col>
                    </Row>
                    <RoleContainerSystem
                      roles={systemRoles}
                      type="system"
                      onDropRole={handleDropRoleSystem}
                    />
                  </Stack>

                  <div
                    style={{
                      padding: 10,
                      backgroundColor: "#3E4CF4",
                      borderRadius: "5px",
                      width: "40px",
                      height: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <SyncAlt style={{ color: "#fff", fontSize: 30 }} />
                  </div>

                  <Stack style={{ width: "45%" }}>
                    <Row>
                      <Col sm={12} md={4}>
                        <Typography variant="h4" gutterBottom>
                          {"Study Roles"}
                        </Typography>
                      </Col>
                      <Col sm={12} md={8}>
                        <Paper
                          component="form"
                          sx={{
                            p: "4px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <SearchSharp style={{ width: 20, height: 20 }} />
                          <InputBase
                            sx={{ ml: 1, flex: 1, fontSize: 16 }}
                            placeholder="Search Study Roles"
                            inputProps={{ "aria-label": "search study roles" }}
                            value={searchStringStudyRoles}
                            onChange={(e) =>
                              setSearchStringStudyRoles(e.target.value)
                            }
                          />
                        </Paper>
                      </Col>
                    </Row>
                    <RoleContainerStudy
                      roles={studyRoles}
                      type="study"
                      onDropRole={handleDropRoleStudy}
                    />
                  </Stack>
                </Container>
              </DndProvider>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <DndProvider backend={HTML5Backend}>
                <Container
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "32px",
                  }}
                >
                  <Stack style={{ width: "45%" }}>
                    <Row>
                      <Col sm={12} md={4}>
                        <Typography variant="h4" gutterBottom>
                          {"System Countries"}
                        </Typography>
                      </Col>
                      <Col sm={12} md={8}>
                        <Paper
                          component="form"
                          sx={{
                            p: "4px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <SearchSharp style={{ width: 20, height: 20 }} />
                          <InputBase
                            sx={{ ml: 1, flex: 1, fontSize: 16 }}
                            placeholder="Search System Countries"
                            inputProps={{
                              "aria-label": "search system countries",
                            }}
                            value={searchStringSystemCountries}
                            onChange={(e) =>
                              setSearchStringSystemCountries(e.target.value)
                            }
                          />
                        </Paper>
                      </Col>
                    </Row>
                    <CountriesContainerSystem
                      Countries={systemCountries}
                      type="system"
                      onDropCountries={handleDropCountriesSystem}
                    />
                  </Stack>

                  <div
                    style={{
                      padding: 10,
                      backgroundColor: "#3E4CF4",
                      borderRadius: "5px",
                      width: "40px",
                      height: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <SyncAlt style={{ color: "#fff", fontSize: 30 }} />
                  </div>

                  <Stack style={{ width: "45%" }}>
                    <Row>
                      <Col sm={12} md={4}>
                        <Typography variant="h4" gutterBottom>
                          {"Study Countries"}
                        </Typography>
                      </Col>
                      <Col sm={12} md={8}>
                        <Paper
                          component="form"
                          sx={{
                            p: "4px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <SearchSharp style={{ width: 20, height: 20 }} />
                          <InputBase
                            sx={{ ml: 1, flex: 1, fontSize: 16 }}
                            placeholder="Search Study Countries"
                            inputProps={{
                              "aria-label": "search study countries",
                            }}
                            value={searchStringStudyCountries}
                            onChange={(e) =>
                              setSearchStringStudyCountries(e.target.value)
                            }
                          />
                        </Paper>
                      </Col>
                    </Row>
                    <CountriesContainerStudy
                      Countries={studyCountries}
                      type="study"
                      onDropCountries={handleDropCountriesStudy}
                    />
                  </Stack>
                </Container>
              </DndProvider>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <DndProvider backend={HTML5Backend}>
                <Container
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "32px",
                  }}
                >
                  <Stack style={{ width: "45%" }}>
                    <Row>
                      <Col sm={12} md={4}>
                        <Typography variant="h4" gutterBottom>
                          {"System Languages"}
                        </Typography>
                      </Col>
                      <Col sm={12} md={8}>
                        <Paper
                          component="form"
                          sx={{
                            p: "4px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <SearchSharp style={{ width: 20, height: 20 }} />
                          <InputBase
                            sx={{ ml: 1, flex: 1, fontSize: 16 }}
                            placeholder="Search System Languages"
                            inputProps={{
                              "aria-label": "search system languages",
                            }}
                            value={searchStringSystemLanguages}
                            onChange={(e) =>
                              setSearchStringSystemLanguages(e.target.value)
                            }
                          />
                        </Paper>
                      </Col>
                    </Row>
                    <LanguagesContainerSystem
                      Languages={systemLanguages}
                      type="system"
                      onDropLanguages={handleDropLanguagesSystem}
                    />
                  </Stack>

                  <div
                    style={{
                      padding: 10,
                      backgroundColor: "#3E4CF4",
                      borderRadius: "5px",
                      width: "40px",
                      height: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <SyncAlt style={{ color: "#fff", fontSize: 30 }} />
                  </div>

                  <Stack style={{ width: "45%" }}>
                    <Row>
                      <Col sm={12} md={4}>
                        <Typography variant="h4" gutterBottom>
                          {"Study Languages"}
                        </Typography>
                      </Col>
                      <Col sm={12} md={8}>
                        <Paper
                          component="form"
                          sx={{
                            p: "4px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <SearchSharp style={{ width: 20, height: 20 }} />
                          <InputBase
                            sx={{ ml: 1, flex: 1, fontSize: 16 }}
                            placeholder="Search Study Languages"
                            inputProps={{
                              "aria-label": "search study languages",
                            }}
                            value={searchStringStudyLanguages}
                            onChange={(e) =>
                              setSearchStringStudyLanguages(e.target.value)
                            }
                          />
                        </Paper>
                      </Col>
                    </Row>
                    <LanguagesContainerStudy
                      Languages={studyLanguages}
                      type="study"
                      onDropLanguages={handleDropLanguagesStudy}
                    />
                  </Stack>
                </Container>
              </DndProvider>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <DndProvider backend={HTML5Backend}>
                <Container
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "32px",
                  }}
                >
                  <Stack style={{ width: "45%" }}>
                    <Row>
                      <Col sm={12} md={4}>
                        <Typography variant="h4" gutterBottom>
                          {"System Regions"}
                        </Typography>
                      </Col>
                      <Col sm={12} md={8}>
                        <Paper
                          component="form"
                          sx={{
                            p: "4px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <SearchSharp style={{ width: 20, height: 20 }} />
                          <InputBase
                            sx={{ ml: 1, flex: 1, fontSize: 16 }}
                            placeholder="Search System Regions"
                            inputProps={{
                              "aria-label": "search system regions",
                            }}
                            value={searchStringSystemRegions}
                            onChange={(e) =>
                              setSearchStringSystemRegions(e.target.value)
                            }
                          />
                        </Paper>
                      </Col>
                    </Row>
                    <RegionsContainerSystem
                      Regions={systemRegions}
                      type="system"
                      onDropRegions={handleDropRegionsSystem}
                    />
                  </Stack>

                  <div
                    style={{
                      padding: 10,
                      backgroundColor: "#3E4CF4",
                      borderRadius: "5px",
                      width: "40px",
                      height: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <SyncAlt style={{ color: "#fff", fontSize: 30 }} />
                  </div>

                  <Stack style={{ width: "45%" }}>
                    <Row>
                      <Col sm={12} md={4}>
                        <Typography variant="h4" gutterBottom>
                          {"Study Regions"}
                        </Typography>
                      </Col>
                      <Col sm={12} md={8}>
                        <Paper
                          component="form"
                          sx={{
                            p: "4px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <SearchSharp style={{ width: 20, height: 20 }} />
                          <InputBase
                            sx={{ ml: 1, flex: 1, fontSize: 16 }}
                            placeholder="Search Study Regions"
                            inputProps={{
                              "aria-label": "search study regions",
                            }}
                            value={searchStringStudyRegions}
                            onChange={(e) =>
                              setSearchStringStudyRegions(e.target.value)
                            }
                          />
                        </Paper>
                      </Col>
                    </Row>
                    <RegionsContainerStudy
                      Regions={studyRegions}
                      type="study"
                      onDropRegions={handleDropRegionsStudy}
                    />
                  </Stack>
                </Container>
              </DndProvider>
            </TabPanel>
            <Dialog open={open} onClose={handleClose}>
              <DialogContent>
                <DialogContentText>
                  <Box sx={{ marginTop: "2%" }}>
                    <p className="user-heading">Update Study Role</p>
                    <Row style={{ alignItems: "center" }}>
                      <Col
                        xs={12}
                        sm={12}
                        md={6}
                        style={{ paddingInline: "3%", paddingBlock: "1%" }}
                      >
                        <label className="uploadInputLabel">
                          Name {/* {errors.roleName?.message ? ( */}
                          <span className="error-highlight">*</span>
                          {/* ) : (
                          <></>
                        )} */}
                        </label>
                        <input
                          className="nameField"
                          type="text"
                          name="shortName"
                          value={shortNameRole}
                          onChange={(e) => setShortNameRole(e.target.value)}
                        />
                      </Col>

                      <Col
                        xs={12}
                        sm={12}
                        md={6}
                        style={{ paddingInline: "3%", paddingBlock: "1%" }}
                      >
                        <label className="uploadInputLabel">
                          Description {/* {errors.description?.message ? ( */}
                          <span className="error-highlight">*</span>
                          {/* ) : (
                          <></>
                        )} */}
                        </label>

                        <textarea
                          className="nameFieldTextArea"
                          type="text"
                          name="description"
                          value={descriptionRole}
                          onChange={(e) => setDescriptionRole(e.target.value)}
                        ></textarea>
                      </Col>

                      <Col
                        xs={12}
                        sm={12}
                        md={6}
                        style={{ paddingInline: "3%", paddingBlock: "1%" }}
                      >
                        <label className="uploadInputLabel">Blinded</label>
                        <Checkbox
                          defaultChecked={isBlindedRole}
                          className="blueCheckbox"
                          style={{ fontSize: "15px" }}
                          value={isBlindedRole}
                          onChange={(e) => setIsBlindedRole(e.target.checked)}
                        />
                      </Col>

                      <Col
                        xs={12}
                        sm={12}
                        md={6}
                        style={{ paddingInline: "3%", paddingBlock: "1%" }}
                      >
                        <label className="uploadInputLabel">
                          Auto Assign Sites
                        </label>

                        <Checkbox
                          defaultChecked={autoAssignNewSitesRole}
                          className="blueCheckbox"
                          style={{ fontSize: "15px" }}
                          inputProps={{ "aria-label": "controlled" }}
                          value={autoAssignNewSitesRole}
                          onChange={(e) =>
                            setAutoAssignNewSitesRole(e.target.checked)
                          }
                        />
                      </Col>
                    </Row>
                  </Box>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  style={{ width: "120px", height: "35px", fontSize: "15px" }}
                  variant="outlined"
                  onClick={handleClose}
                >
                  No
                </Button>
                <Button
                  style={{ width: "120px", height: "35px", fontSize: "15px" }}
                  variant="contained"
                  onClick={onSubmit2}
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <BeatLoader color="#3661eb" />
          </div>
        </>
      )}
    </>
  );
};

export default EditStudy;
