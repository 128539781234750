import { QueryClient, useMutation, useQuery, useQueryClient } from "react-query";
import { useSubjectAttributeManagement } from "../../context/studyManagement/SubjectAttribute/SubjectAttributemanagementContext";
import {
    addEditChoices,
    editPatientAttributeConfig,
    getAllBusinessDetail,
    getAllDataTypeDetail,
    getAllSingleAttributeConfig,
    getAllSubjectAttributes,
    getAllSubjectPac,
    postSubjectDetailConfig,
    updateSingleAttributeConfig
} from "../../services/subject_attributes";
import {
    mapBusinessRuleDetails,
    mapDataTypeDetails,
    mapSubjectAttributeData
} from "../../utils/dataMapping";
import { toast } from "react-toastify";

const useSubjectAttributesDetails = () => {
    const { state, dispatch } = useSubjectAttributeManagement();
    const queryParams = new URLSearchParams(location.search);
    const pacId = queryParams.get("pacId");
    const queryClient = useQueryClient();

    // Fetch subject attribute data
    const fetchSubjectAttributeData = async () => {
        const res = await getAllSubjectAttributes();
        if (res?.data?.message === "DATA Extracted" && res?.data?.result) {
            return mapSubjectAttributeData(res.data.result);
        }
        throw new Error("Invalid response structure");
    };

    // Fetch business details
    const fetchBusinessDetails = async () => {
        const res = await getAllBusinessDetail();
        if (res?.data?.status === "Success" && res?.data?.result) {
            return mapBusinessRuleDetails(res.data);
        }
        throw new Error("Invalid response structure");
    };

    // Fetch data type details
    const fetchDataTypeDetails = async () => {
        const res = await getAllDataTypeDetail();
        if (res?.data?.status === "Success" && res?.data?.result) {
            return mapDataTypeDetails(res.data);
        }
        throw new Error("Invalid response structure");
    };

    // Fetch attribute details by guid
    const fetchAttributeDetailsByGuid = async (guid) => {
        const res = await editPatientAttributeConfig(guid);
        if (res?.data?.status === "Success" && res?.data?.result.value) {
            return res.data.result.value;
        }
        throw new Error("Invalid response structure");
    };

    const fetchSingleAttributeConfiguration = async ({ queryKey }) => {
        const groupName = queryKey[1]; // Extract groupName from queryKey array
        try {
            const res = await getAllSingleAttributeConfig(groupName);
            if (res?.status === "Success" && Array.isArray(res?.result?.SubjectVariable)) {
                // Map the data to a more usable format
                const mappedData = res.result.SubjectVariable.map(item => ({
                    key: item.key,
                    value: item.value,
                    notes: item.notes,
                    lastUpdate: item.lastUpdate,
                    group: item.group
                }));
                return mappedData;
            }
            throw new Error('Invalid response structure');
        } catch (error) {
            console.error("Error in fetchSingleAttributeConfiguration:", error.message);
            throw error; // Propagate error to be handled by useQuery
        }
    };



    // Queries
    const subjectAttributeQuery = useQuery(
        "subjectAttribute",
        fetchSubjectAttributeData,
        {
            onSuccess: (data) => dispatch({ type: "SET_SUBJECTATTRIBUTE_DATA", payload: data }),
            onError: (error) => {
                console.error("Error fetching subject attribute data: ", error.message);
                dispatch({ type: "DISABLE_LOADING" });
            },
            refetchOnWindowFocus: false,
        }
    );

    const businessDetailsQuery = useQuery(
        "businessDetails",
        fetchBusinessDetails,
        {
            onSuccess: (data) => dispatch({ type: "SET_BUSINESS_DETAIL_DATA", payload: data }),
            onError: (error) => {
                console.error("Error fetching business details: ", error.message);
                dispatch({ type: "DISABLE_LOADING" });
            },
            refetchOnWindowFocus: false,
        }
    );

    const dataTypeDetailsQuery = useQuery(
        "dataTypeDetails",
        fetchDataTypeDetails,
        {
            onSuccess: (data) => dispatch({ type: "SET_DATATYPE_DETAIL_DATA", payload: data }),
            onError: (error) => {
                console.error("Error fetching data type details: ", error.message);
                dispatch({ type: "DISABLE_LOADING" });
            },
            refetchOnWindowFocus: false,
        }
    );

    const createSubjectAttributetMutation = useMutation(
        ({ Id, AddCountryId }) => getAllSubjectPac(Id, AddCountryId),
        {
            onSuccess: (data) => dispatch({ type: "CREATE_SUBJECT_ATTRIBUTE_DATA", payload: data.data }),
            onError: (error) => console.error("Error creating subject attribute:", error.message),
        }
    );

    const createSubjectAttributetConfigurationDetailMutation = useMutation(
        (data) => postSubjectDetailConfig(data),
        {
            onSuccess: (data) => dispatch({ type: "CREATE_SUBJECT_ATTRIBUTE_DETAIL_DATA", payload: data.data }),
            onError: (error) => console.error("Error creating subject attribute:", error.message),
        }
    );

    // Query for fetching attribute details by guid
    const attributeDetailsQuery = (guid) => useQuery(
        ["attributeDetails", guid],
        () => fetchAttributeDetailsByGuid(guid),
        {
            onSuccess: (data) => dispatch({ type: "SET_ATTRIBUTE_DETAILS_DATA", payload: data }),
            onError: (error) => console.error("Error fetching attribute details:", error.message),
            enabled: !!guid,
            refetchOnWindowFocus: false,
        }
    );

    const singleAttributeConfig = useQuery(
        ["subjectConfiguration", "Subject Variable"],
        fetchSingleAttributeConfiguration,
        {
            onSuccess: (data) => {
                dispatch({ type: "SET_SUBJECT_CONFIGURATION_DATA", payload: data })
            },
            onError: (error) => {
                console.error("Error fetching subject configuration:", error.message);
                dispatch({ type: "DISABLE_LOADING" });
            },
            refetchOnWindowFocus: false,
        }
    );

    const updateAttributeConfig = async (key, value) => {
        dispatch({ type: 'UPDATE_REQUEST' });
        try {
            const data = await updateSingleAttributeConfig(key, value);
            dispatch({ type: 'UPDATE_SUCCESS', payload: data });
        } catch (error) {
            dispatch({ type: 'UPDATE_FAILURE', payload: error.message });
        }
    };

    // const addOrEditChoiceMutation = useMutation(
    //     ({ patientAttributeConfigurationDetailId, choiceText }) =>
    //         addEditChoices(patientAttributeConfigurationDetailId, choiceText),
    //     {
    //         onSuccess: (response, { setChoices, reset }) => {
    //             if (response && response?.data?.result) {
    //                 const newChoice = {
    //                     text: response?.data?.result
    //                         ?.patientAttributeConfigurationDetailChoices[
    //                         response?.data?.result?.patientAttributeConfigurationDetailChoices.length - 1
    //                     ].text,
    //                     id: response?.data?.result
    //                         ?.patientAttributeConfigurationDetailChoices[
    //                         response?.data?.result?.patientAttributeConfigurationDetailChoices.length - 1
    //                     ].id, 
    //                 };
    //                 setChoices(prevChoices => [...prevChoices, newChoice]);
    //                 reset();
    //             }
    //         },
    //         onError: (error) => {
    //             console.error("Error adding choice:", error);
    //         }
    //     }
    // );
    const addOrEditChoiceMutation = useMutation(
        ({ patientAttributeConfigurationDetailId, choiceText }) =>
            addEditChoices(patientAttributeConfigurationDetailId, choiceText),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["attributeDetails", pacId]);
            },
            onError: (error) => {
                console.error("Error adding choice:", error);
                toast.error("Failed to add choice.");
            },
        }
    );


    return {
        subjectAttributeQuery,
        businessDetailsQuery,
        dataTypeDetailsQuery,
        createSubjectAttributetMutation,
        createSubjectAttributetConfigurationDetailMutation,
        attributeDetailsQuery,
        fetchAttributeDetailsByGuid,
        singleAttributeConfig,
        updateAttributeConfig,
        addOrEditChoiceMutation,
        state
    };
};

export default useSubjectAttributesDetails;

